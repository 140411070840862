<template>
  <b-jumbotron lead="Ψάξε ένα ζωάκι για υιοθεσία..." class="mt-5 search jumbotronSearchLead">
        <b-container fluid class="bv-example-row display-desktop">
            <b-row>
                <b-col lg="8">
                    <b-form-group label="Είδος" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                            id="checkbox-group-pets"
                            v-model="adoptionFilters.petsSelected"
                            :options="listOfPets"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    <b-row class="mt-2">
                        <b-col>
                            <label class="mb-0">
                                Ηλικία από (ετών):
                            </label>
                            <KInput
                                title=""
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="age_from"
                                parent="adoptionFilters"
                                class="label_sign_in"
                                type="number"
                            />
                            <label class="mb-0">
                                Ηλικία μέχρι (ετών): 
                            </label>
                            <KInput
                                title=""
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="age_to"
                                parent="adoptionFilters"
                                class="label_sign_in"
                                type="number"
                            />
                        </b-col>
                        <b-col>
                            <label class="mb-0">
                                Ράτσα: 
                            </label>
                            <multiselect 
                                v-model="adoptionFilters.breeds"
                                placeholder="Ράτσα"
                                :options="filteredBreeds"
                                class="label_sign_in"
                                :multiple="true"
                                label="name" 
                                track-by="id"
                            >
                            </multiselect>
                            <label class="mb-0 mt-2">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="adoptionFilters.cityId">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-button 
                        @click="getAdoptionResults()" 
                        variant="primary"
                        :disabled="!continueStep"
                        class="button button-orange font-weight-bold btn-primary rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
                <b-col lg="4" class="text-right" align-self="end">
                    <b-img :src="require('@/assets/images/pets.png')" fluid class="pb-3"></b-img>
                    <div class="mt-2" v-if="authenticated">
                        <b-button v-b-modal.modal-2 pill variant="primary" class="button button-orange font-weight-bold">Έχω κατοικίδιο προς υιοθεσία</b-button>
                    </div>
                    <b-modal 
                        v-if="authenticated"
                        id="modal-2" 
                        title="Κατοικίδιο προς υιοθεσία"
                        @ok="createPetForAdoption()"
                        @show="clearNewPetForAdoption"
                        @hidden="clearNewPetForAdoption"
                        :ok-disabled="!enableAddModalButton"
                    >
                        <AdoptionDetails />
                        <div>
                            <b-button 
                                v-if="adoptionMediaFiles.length < maxMedia" 
                                @click="addMedia({slug: 'adoptionMediaFiles'})" 
                                class="button button-orange font-weight-bold"
                                pill
                                >Προθήκη φωτογραφίας +  
                            </b-button>
                            <PetMedia :index="index" :media="media" v-for="(media,index) in adoptionMediaFiles" :key="index" slug="adoptionMediaFiles"/>
                        </div>
                    </b-modal>
                </b-col>
            </b-row>
        </b-container>

        <!-- mobile search form -->
        <b-container id="lostFoundSearchFormMobile" class="bv-example-row display-mobile text-center p-0">
            <b-row>
                <b-col lg="8">

                    <b-form-group label="" v-slot="{ ariaDescribedby }" v-bind:class="!nextStepLostFoundSearch ? '' : 'displaynone' ">
                        <b-form-checkbox-group
                            id="checkbox-group-pets-mbl"
                            v-model="adoptionFilters.petsSelected"
                            :options="listOfPets"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1-mbl"
                            @input="checkAnimalType"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    
                    
                    <b-button class="arrowBackSearch" v-if="nextStepLostFoundSearch" @click="go_back()"><span>←</span> πίσω</b-button>
                    <b-row v-if="nextStepLostFoundSearch">
                        <b-col>
                            <label class="mb-0 mobileSearchFormLabel text-left">
                                Ηλικία από (ετών): 
                            </label>
                            <KInput
                                title=""
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="age_from"
                                parent="adoptionFilters"
                                class="label_sign_in w-100 text-left"
                                type="number"
                            />
                        </b-col>
                        <b-col>
                            <label class="mb-0 mobileSearchFormLabel text-left">
                                Ηλικία μέχρι (ετών): 
                            </label>
                            <KInput
                                title=""
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="age_to"
                                parent="adoptionFilters"
                                class="label_sign_in w-100 text-left"
                                type="number"
                            />
                        </b-col>
                    </b-row>
                    <b-row v-if="nextStepLostFoundSearch" class="mt-2">
                        <b-col>
                            <label class="mb-0 mobileSearchFormLabel">
                                Ράτσα: 
                            </label>
                            <b-form-select v-model="adoptionFilters.breeds" class="form-control">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Ράτσα</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="breed in filteredBreeds" v-bind:value="breed" :key="breed.id">
                                    {{ breed.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col>
                            <label class="mb-0 mobileSearchFormLabel">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="adoptionFilters.cityId" class="form-control d-block">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>

                    <b-button 
                        v-if="nextStepLostFoundSearch"
                        @click="getAdoptionResults()"
                        :disabled="!continueStep"
                        variant="primary"
                        class="mt-3 w-100 button-orange button rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
            </b-row>
        </b-container>

  </b-jumbotron>


</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import PetMedia from '../Pets/PetMedia.vue'
import AdoptionDetails from '../../components/Adoption/AdoptionDetails.vue'
import Multiselect from 'vue-multiselect'
import KInput from '@/components/Form/KInput.vue'
import {merge} from 'lodash'
import {integer, minValue } from 'vuelidate/lib/validators'

    export default {
        data(){
            return {
                nextStepLostFoundSearch: false,
                listOfPets: [
                    { text: 'Σκύλος', value: 1 },
                    { text: 'Γάτα', value: 2 },
                    { text: 'Άλογο', value: 3 },
                    { text: 'Άλλο', value: 0 }
                ],
                clientValidation: {
                    adoptionFilters: {
                        age_from: {
                            integer,
                            minValue: minValue(0),
                            maxValue(value) {
                                return value < this.adoptionFilters.age_to
                            }
                        },
                        age_to: {
                            integer,
                            minValue(value) {
                                return value > this.adoptionFilters.age_from
                            }
                        }
                    }
                },

                serverValidation: {},

                errorMessages: {
                    adoptionFilters: {
                        age_from:{
                            integer: 'Must be integer',
                            minValue: 'Δεν μπορεί να έχει αρνητική τιμή',
                            maxValue: 'Δεν μπορεί να είναι μεγαλύτερη της "Ηλικίας μέχρι"'
                        },
                        age_to:{
                            integer: 'Must be integer',
                            minValue: 'Δεν μπορεί να είναι μικρότερη της "Ηλικίας από"'
                        },
                    }
                }
            }
        },

        computed: {
            ...mapState('search', ['adoptionFilters', 'breeds', '$vald']),
            ...mapState('areas', ['cities']),
            ...mapState('pet', ['adoptionMediaFiles', 'maxMedia', 'newPetForAdoption']),
            ...mapGetters('auth', ['authenticated']),
            filteredBreeds() {
                if (this.breeds.length) {
                    let otherTypes = this.adoptionFilters.petsSelected.includes(0) ? [4, 5] : []
                    return this.breeds.filter((item) => {
                        return this.adoptionFilters.petsSelected.includes(item.pet_type_id) ||
                            otherTypes.includes(item.pet_type_id)
                    })
                }
                return []
            },
            continueStep(){
                let flag = false

                if(this.$vald.adoptionFilters.$dirty){
                    if(this.$vald.adoptionFilters.$anyError){
                        //this.disableButtonsAction()
                    }else{
                        //this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.adoptionFilters.$invalid){
                        //this.disableButtonsAction()
                    }else{
                        //this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
            enableAddModalButton() {
                return this.newPetForAdoption.area && this.newPetForAdoption.type && this.newPetForAdoption.breed_list.length && this.newPetForAdoption.age
            }
        },

        methods: {
            ...mapActions('search', ['getAdoptionResults', 'getFilterData', 'setValidator']),
            ...mapActions('areas', ['getCities']),
            ...mapActions('pet', ['addMedia', 'createPetForAdoption', 'clearNewPetForAdoption']),
            checkAnimalType() {
                this.nextStepLostFoundSearch = true;

                var child0 = document.getElementById('checkbox-group-pets-mbl_BV_option_0');
                
                if (child0.checked) {
                    child0.parentElement.className += ' selectedBackground';
                    child0.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child0.parentElement.classList.remove('selectedBackground');
                    child0.nextSibling.classList.remove('selectedWhiteFont');
                }

                var child1 = document.getElementById('checkbox-group-pets-mbl_BV_option_1');
                
                if (child1.checked) {
                    child1.parentElement.className += ' selectedBackground';
                    child1.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child1.parentElement.classList.remove('selectedBackground');
                    child1.nextSibling.classList.remove('selectedWhiteFont');
                }

                var child2 = document.getElementById('checkbox-group-pets-mbl_BV_option_2');
                
                if (child2.checked) {
                    child2.parentElement.className += ' selectedBackground';
                    child2.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child2.parentElement.classList.remove('selectedBackground');
                    child2.nextSibling.classList.remove('selectedWhiteFont');
                }

                var child3 = document.getElementById('checkbox-group-pets-mbl_BV_option_3');
                
                if (child3.checked) {
                    child3.parentElement.className += ' selectedBackground';
                    child3.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child3.parentElement.classList.remove('selectedBackground');
                    child3.nextSibling.classList.remove('selectedWhiteFont');
                }
                
            },
            go_back() {
                this.nextStepLostFoundSearch = false;
            }
        },

        created(){
            this.getCities()
            this.getFilterData()
            this.setValidator(this.$v)
            this.getAdoptionResults()
        },

        components: {
            AdoptionDetails, PetMedia, Multiselect, KInput
        },
        validations() {
            return this.rules
        }
    }
</script>

<style lang="scss" scoped>

</style>