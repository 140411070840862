<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
                <RegisterTemplate title="Δώσε μας μερικά χαρακτηριστικά">
                    <KDate 
                        title="Ημερομηνία υιοθεσίας:"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="adoption_date"
                        parent="pet"
                        class="label_sign_in"
                    />
                    <KInput
                        title="Αριθμός διαβατηρίου:"
                        :validation="$vald"
                        :minNumber=0
                        :continue-step="continueStep"
                        :errorMessages="errorMessages"
                        objectName="passport_no"
                        parent="pet"
                        class="label_sign_in"
                        type="number"
                       
                    />
                    <KInput
                        title="Αριθμός τατουάζ:"
                        :validation="$vald"
                        :continue-step="continueStep"
                        :errorMessages="errorMessages"
                        objectName="tatoo_no"
                        parent="pet"
                        type="number"
                        class="label_sign_in"
                    />
                    <KDate 
                        title="Ημερομηνία τατουαζ:"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="tatoo_date"
                        parent="pet"
                        class="label_sign_in"
                    />
                    <KInput
                        title="Θέση τατουάζ:"
                        :validation="$vald"
                        :continue-step="continueStep"
                        :errorMessages="errorMessages"
                        objectName="tatoo_location"
                        parent="pet"
                        class="label_sign_in"
                    />
                    <KFile 
                        :validation="$vald"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="certificate"
                        parent="pet"
                        title="Πιστοποιητικό Ηλεκτρονικής Ταυτοποίησης"
                    />
                </RegisterTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import {integer, minValue } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import KInput from '@/components/Form/KInput.vue'
import KDate from '@/components/Form/KDate.vue'
import KFile from '@/components/Form/KFile.vue'
import RegisterTemplate from '@/components/Pets/Registration/RegisterTemplate.vue'


    export default {
        computed: {
            ...mapState('pet_registration', ['pet', '$vald']),
            continueStep(){
                let flag = false

                if(this.$vald.pet.$dirty){
                    if(this.$vald.pet.$anyError){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.pet.$invalid){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
            showPhoto() {
                if (typeof this.pet.certificate === 'object' && !Array.isArray(this.pet.certificate) && this.pet.certificate !== null) {
                    return URL.createObjectURL(this.pet.certificate);
                } else if (this.pet.certificate !== null && this.pet.certificate.length) {
                    return this.baseUrl + this.pet.certificate
                }

                return null;
            }
        },
        data(){
            return {
                baseUrl: process.env.VUE_APP_WEB_URI,
                clientValidation: {
                    pet: {
                        adoption_date: {
                            
                        },
                        passport_no:{
                            integer,
                            minValue: minValue(0)
                        },
                        tatoo_no: {
                            integer,
                            minValue: minValue(0)
                            },
                        tatoo_date: {
                              maxValue: value => {
                                if ( ! value )
                                    return true
                                let currentDate = moment(new Date()).startOf('day')
                                return value <= currentDate.format('YYYY-MM-DD')
                            }
                        },
                        tatoo_location: {},
                        certificate: {
                            isValidFileSize: (value) => {
                                if (!value || !value.length || typeof value === 'string') {
                                    return true
                                }
                                const fileSizeinKb = value.size / 1024
                                const size = Math.round(fileSizeinKb * 100) / 100 // convert up to 2 decimal place
                                return size <= 15360
                            }
                        }
                    }
                },

                serverValidation: {},

                errorMessages: {
                    pet: {
                        passport_no:{
                            integer: 'Must be integer',
                            minValue: 'Δεν μπορεί να έχει αρνητική τιμή'
                        },
                        tatoo_no: { 
                            integer: 'Must be integer',
                            minValue: 'Δεν μπορεί να έχει αρνητική τιμή'},
                        tatoo_date: {
                             minValue: 'The maximum Date of Birth is Today!'
                        },
                        tatoo_location: {},
                        adoption_date: {},
                        certificate: {
                            isValidFileSize: 'The file is too big.'
                        }
                    }
                }
            }
        },
        
        validations() {
            return this.rules
        },
        
        methods:{
            ...mapActions('pet_registration', ['disableButtonsAction', 'enableButtonsAction', 'setValidator'])
        },

        created(){
            this.setValidator(this.$v)
        },
        components: {
            RegisterTemplate, KInput, KDate, KFile
        }
    }
</script>

<style lang="scss" scoped>

</style>