<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
                <RegisterTemplate title="Πώς πάμε από υγεία;">
                    <KSelect 
                        title="Είναι στειρωμένο;"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="sterilization"
                        :options="sterilizationOptions"
                        identifier="value"
                        val="text"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                    />
                    <b-form-group id="input-group-2" label="Γνωστές ασθένειες" label-for="diseases">
                        <b-form-tags input-id="diseases" v-model="pet.diseases"></b-form-tags>
                    </b-form-group>
                    
                    <label>Εμβόλια και θεραπείες</label>
                    <b-form-select v-if="filteredVaccines.length" @change="addNewVaccine" v-model="selectedVacc">
                        <template #first>
                            <b-form-select-option :value="null" disabled>Επέλεξε εμβόλιο</b-form-select-option>
                        </template>
                        <b-form-select-option v-for="vaccine in filteredVaccines" :key="vaccine.id" :value="vaccine">
                            {{ vaccine.name }}
                        </b-form-select-option>
                    </b-form-select>

                    <div v-else>
                        No other vaccines...
                    </div>

                    <div v-if="pet.listVaccines" class="mt-4">
                        <b-row v-for="newVaccine in pet.listVaccines" :key="newVaccine.id" class="mb-2">
                            <b-col align-self="center" class="vaccineName">
                                <button @click="deleteSelectedVaccine(newVaccine.vaccine)" class="border-0 mr-1 bg-transparent deleteVaccine">
                                    <b-icon icon="x-circle" scale="1.5" variant="danger"></b-icon>
                                </button>
                                <span>{{ newVaccine.vaccine.name }}</span>
                            </b-col>
                            <b-col>
                                <b-form-datepicker
                                    class="vaccinesDatepicker"
                                    v-model="newVaccine.date"
                                    locale="el"
                                    :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                                ></b-form-datepicker>
                            </b-col>
                        </b-row>
                    </div>

                </RegisterTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import KSelect from '@/components/Form/KSelect.vue'
import RegisterTemplate from '@/components/Pets/Registration/RegisterTemplate.vue';

    export default {
        data() {
            return {
                sterilizationOptions: [
                    {value: 1, text: 'Ναι'}, 
                    {value: 0, text: 'Όχι'}
                ],
                clientValidation: {
                    pet: {
                        sterilization:{
                            required
                        },
                    }
                },

                serverValidation: {},

                errorMessages: {
                    pet: {
                        sterilization: {
                            required: 'The Sterilization field is required!',
                        },
                    }
                }
            }
        },
        computed: {
            ...mapState('pet_registration', ['pet', '$vald', 'vaccines', 'selectedVaccine', 'currentDate']),

            selectedVacc: {
                get(){
                    return this.selectedVaccine
                },

                set(value){
                    this.setSelectedVaccine(value)
                }
            },

            filteredVaccines() {
                if (this.vaccines) {
                    return this.vaccines.filter((item) => item.pet_type_id == this.pet.pet_type_id)
                }
                return []
            },

            continueStep(){
                let flag = false

                if(this.$vald.pet.$dirty){
                    if(this.$vald.pet.$anyError){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.pet.$invalid){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            }
        },
        validations() {
            return this.rules
        },
        
        methods:{
            ...mapActions('pet_registration', ['disableButtonsAction', 'enableButtonsAction', 'setValidator', 'addNewVaccine', 'deleteSelectedVaccine', 'setSelectedVaccine']),
        },

        created(){
            this.setValidator(this.$v)
        },
        components: {
            RegisterTemplate, KSelect
        }
    }
</script>

<style lang="scss" scoped>

</style>