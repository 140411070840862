import { render, staticRenderFns } from "./KMultiSelect.vue?vue&type=template&id=218d92bb&scoped=true&"
import script from "./KMultiSelect.vue?vue&type=script&lang=js&"
export * from "./KMultiSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218d92bb",
  null
  
)

export default component.exports