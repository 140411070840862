<template>
    <b-form-group>

        <label v-if="title !== null" class="w-100">{{title}}</label>

        <b-form-select
            v-if="!isArray" 
            class="w-100" 
            :id="objectName + '-select'"
            :name="objectName + '-select'"
            v-model="validation[parent][objectName].$model"
            :state="validateState()"
            :aria-describedby="objectName + '-live-feedback'" 
            :placeholder="placeholder"
            :multiple="multiple"
            @change="inputEvent($event)"
        >
            <b-form-select-option disabled :value="null">{{ placeholder }}</b-form-select-option>

            <b-form-select-option
                v-for="(option, index) in options"
                :key="identifier === '' ? index : option[identifier]"
                :value="identifier === '' ? index : option[identifier]"
            >
                {{ val === '' ? option : option[val] }}
            </b-form-select-option>
        </b-form-select>

        <b-form-select
            v-else
            class="w-100" 
            :id="objectName + '-select'"
            :name="objectName + '-select'"
            v-model="validation[parent].$each.$iter[indexOfArray][objectName].$model"
            :state="validateArrayState()"
            :aria-describedby="objectName + indexOfArray + '-live-feedback'" 
            :placeholder="placeholder"
            :multiple="multiple"
            @change="inputEvent($event)"

        >
            <b-form-select-option disabled :value="null">{{ placeholder }}</b-form-select-option>

            <b-form-select-option
                v-for="(option, index) in options"
                :key="identifier === '' ? index : option[identifier]"
                :value="identifier === '' ? index : option[identifier]"
            >
                {{ val === '' ? option : option[val] }}
            </b-form-select-option>
        </b-form-select>

        <ErrorMessage 
            v-for="(message, index) in errorMessages[parent][objectName]"
            :key="index"
            :message="message"
            :show="showErrorMessage(index)"
            :id="getId(index)" 
        />

    </b-form-group>
</template>

<script>
import ErrorMessage from '../Errors/FormInvalidFeedback.vue'

    export default {
        props:{
            isArray: {
                type: Boolean,
                default: false
            },

            indexOfArray: {
                type: [String, Number],
                default: "0"
            },
                        
            parent: {
                type: String,
                required: true
            },

            objectName: {
                type: String,
                required: true
            },

            title: {
                type: String,
                required: false,
                default: null
            },

            validation: {
                type: Object,
                required: true
            },

            continueStep: {
                type: Boolean,
                required: true
            },

            errorMessages:{
                type: Object,
                required: true
            },

            options:{
                type: Array,
                required: true
            },

            identifier: {
                type: String,
                default: ''
            },

            val: {
                type: String,
                default: ''
            },

            placeholder: {
                type: String, 
                required: false
            },
            multiple: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        components: {
            ErrorMessage
        },

        methods:{
            validateState(){
                let { $dirty, $error } = this.validation[this.parent][this.objectName]
                if($dirty){
                    if($error){
                        return false
                    }
                }
                return null            
            },

            validateArrayState(){
                let { $dirty, $error } = this.validation[this.parent].$each.$iter[this.indexOfArray][this.objectName]
                if($dirty){
                    if($error){
                        return false
                    }
                }
                return null 
            },

            showErrorMessage(index){
                if(!this.isArray){
                    return !this.validation[this.parent][this.objectName][index]
                }

                return !this.validation[this.parent].$each[this.indexOfArray][this.objectName][index]
            },

            getId(index){
                if(this.isArray){
                    return this.objectName + index
                }

                return this.objectName
            },

            inputEvent(e) {
                this.$emit('change', e);
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>