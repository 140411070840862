<template>
    <div id="lostPetCard" v-if="pet.losts">
        <img 
            :src="require('@/assets/images/lost_and_found.png')" 
            alt="Lost and Found Logo" 
            class="display-mobile m-auto pt-4 pb-4" 
            style="padding: 0;border-radius: 0;width: auto !important; height: 100% !important;" />

        <ShowTemplate :image="pet.photo ? pet.photo : ''" class="petImg">
            <div class="text-center">
                <h3 class="font-weight-bold display-desktop">Χάθηκε - {{ pet.name }}</h3>
                <h5 class="display-desktop">
                    <b-icon-geo-alt class="ml-2 orangeColor"></b-icon-geo-alt>
                    {{ pet.losts[0].area.city.name }}, {{ pet.losts[0].area.city.country.sort }}
                </h5>
            </div>

            <div>
                <div class="row pb-4 pt-3">
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Ημερομηνία: </div>
                        <div class="lostPetInputs">{{ pet.losts[0].date_lost }}</div>
                    </div>
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Χρώμα: </div>
                        <div class="lostPetInputs">{{ colouring }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Ράτσα: </div>
                        <div class="lostPetInputs">{{ breeds }}</div>
                    </div>
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Περιοχή: </div>
                        <div class="lostPetInputs">{{ pet.losts[0].area.name }}</div>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-12">
                        <div class="font-weight-bold lostPetLabels">Πληροφορίες: </div>
                        <div class="lostPetTextarea">{{ pet.losts[0].description }}</div>
                    </div>
                </div>
            </div>
        </ShowTemplate>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import { BIconGeoAlt } from 'bootstrap-vue'
import ShowTemplate from '../../components/LostAndFound/Show/ShowTemplate.vue'

export default {
    data(){
        return {
            baseUrl: process.env.VUE_APP_WEB_URI
        }
    },

    computed: {
        breeds(){
            if(Array.isArray(this.pet.breeds) && this.pet.breeds.length){
                return this.pet.breeds.map(breed => breed.name).join(", ")
            }
            return 'χωρίς ράτσα'
        },

        colouring(){
            if(Array.isArray(this.pet.colours) && this.pet.colours.length){
                return this.pet.colours.map(colour => colour.name).join(", ")
            }
            return 'χωρίς χρώμα'
        },

        ...mapState('pet', ['pet']),
    },

    methods:{
        ...mapActions('pet', ['showLostPet']),
    },

    created(){
        let data = {
            id: this.$route.params.id
        }
        this.showLostPet(data)
    },

    components: {
        BIconGeoAlt, ShowTemplate
    },
}
</script>

<style lang="scss" scoped>

</style>