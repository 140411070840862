<template>
    <b-form-group>
        <label>{{ title }}</label>

        <b-form-datepicker
            :aria-describedby="objectName + '-live-feedback'" 
            :id="objectName + '-datepicker'" 
            v-model="validation[parent][objectName].$model"
            class="mb-2"
            locale="el"
            :state="validateState()"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
        ></b-form-datepicker>


        <ErrorMessage 
            :id="objectName" 
            v-for="(message, index) in errorMessages[parent][objectName]"
            :key="index"
            :show="!validation[parent][objectName][index]"
            :message="message"
        />

    </b-form-group>
</template>

<script>
import ErrorMessage from '../Errors/FormInvalidFeedback.vue'

    export default {
        props:{
            parent: {
                type: String,
                required: true
            },

            objectName: {
                type: String,
                required: true
            },

            title: {
                type: String,
                required: true
            },

            validation: {
                type: Object,
                required: true
            },

            continueStep: {
                type: Boolean,
                required: true
            },

            errorMessages:{
                type: Object,
                required: true
            }
        },

        components: {
            ErrorMessage
        },

        methods:{
            validateState(){
                let { $dirty, $error } = this.validation[this.parent][this.objectName]
                if($dirty){
                    if($error){
                        return false
                    }
                }
                return null
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>