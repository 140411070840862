<template>
    <b-jumbotron lead="Lost & Found" class="mt-lg-5 search lostAndFoundSearch">
        <b-container fluid class="bv-example-row display-desktop">
            <b-row>
                <b-col lg="8">
                    <b-form-group v-slot="{ariaDescribedby}">
                        <b-form-radio 
                            v-model="searchFilters.typeOfSearch" 
                            :aria-describedby="ariaDescribedby" 
                            name="some-radios"
                            :inline="true"
                            class="align-middle"
                            value="found">Βρέθηκε
                        </b-form-radio>
                        <b-form-radio 
                            v-model="searchFilters.typeOfSearch" 
                            :aria-describedby="ariaDescribedby" 
                            name="some-radios" 
                            :inline="true"
                            class="align-middle"
                            value="lost">Χάθηκε
                        </b-form-radio>
                    </b-form-group>

                    <b-form-group label="Είδος" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                            id="checkbox-group-pets"
                            v-model="searchFilters.petsSelected"
                            :options="listOfPets"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                        ></b-form-checkbox-group>
                    </b-form-group>

                    <b-row>
                        <b-col>
                            <label class="mb-0">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="searchFilters.cityId">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col align-self="end">
                            <label class="mb-0">
                                Ημερομηνία: 
                            </label>
                            <b-form-datepicker
                                v-model="searchFilters.date"
                                locale="el"
                                autocomplete="off"
                                placeholder="Ημερομηνία"
                                :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                            ></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col>
                            <label class="mb-0">
                                Ηλικία: 
                            </label>
                            <KInput
                                title=""
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="age"
                                parent="searchFilters"
                                class="label_sign_in"
                                type="number"
                            />
                        </b-col>
                        <b-col>
                            <label class="mb-0">
                                Ράτσα: 
                            </label>
                            <multiselect 
                                v-model="searchFilters.breeds"
                                placeholder="Ράτσα"
                                :options="filteredBreeds"
                                class="label_sign_in"
                                :multiple="true"
                                label="name" 
                                track-by="id"
                            >
                            </multiselect>
                        </b-col>
                    </b-row>
                    <b-button 
                        @click="getSearchResults(searchFilters)" 
                        :disabled="!continueStep" 
                        variant="primary"
                        class="button button-orange font-weight-bold btn-primary rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
                <b-col lg="4" class="text-right" align-self="end">
                    <b-img :src="require('@/assets/images/pets.png')" fluid class="pb-3"></b-img>
                    <div class="mt-2" v-if="authenticated">
                        <b-button v-b-modal.modal-2 pill variant="primary" class="button button-orange font-weight-bold">Βρήκα ένα κατοικίδιο</b-button>
                    </div>
                    <b-modal 
                        id="modal-2" 
                        title="Βρέθηκε ένα ζωάκι"
                        @ok="createFoundPet()"
                        @show="clearNewPetFound"
                        @hidden="clearNewPetFound"
                        :ok-disabled="!enableAddModalButton"
                        v-if="authenticated"
                    >
                        <FoundDetails />
                        <div>
                            <b-button 
                                v-if="foundMediaFiles.length < maxMedia" 
                                @click="addMedia({slug: 'foundMediaFiles'})" 
                                class="button button-orange font-weight-bold"
                                pill
                                >Προθήκη φωτογραφίας +  
                            </b-button>
                            <PetMedia :index="index" :media="media" v-for="(media,index) in foundMediaFiles" :key="index" slug="foundMediaFiles"/>
                        </div>
                        <p class="mb-0 pt-4">*υποχρεωτικά πεδία</p>
                    </b-modal>
                </b-col>
            </b-row>
        </b-container>


        <!-- mobile search form -->
        <b-container id="lostFoundSearchFormMobile" class="bv-example-row display-mobile text-center p-0">
            <b-row>
                <b-col lg="8" class="p-0">
                    <b-form-group v-slot="{ariaDescribedby}" v-bind:class="!nextStepLostFoundSearch ? '' : 'displaynone'">
                        <b-form-radio 
                            id="found_checkbox"
                            @change="checkRadioFound"
                            v-model="searchFilters.typeOfSearch"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios-mbl"
                            :inline="true"
                            class="align-middle separatorLine"
                            value="found">Βρέθηκε
                        </b-form-radio>
                        <b-form-radio 
                            id="lost_checkbox"
                            @change="checkRadioLost"
                            v-model="searchFilters.typeOfSearch" 
                            :aria-describedby="ariaDescribedby" 
                            name="some-radios-mbl" 
                            :inline="true"
                            class="align-middle"
                            value="lost">Χάθηκε
                        </b-form-radio>
                    </b-form-group>

                    <b-form-group label="" v-slot="{ ariaDescribedby }" v-bind:class="!nextStepLostFoundSearch ? '' : 'displaynone'">
                        <b-form-checkbox-group
                            id="checkbox-group-pets-mbl"
                            v-model="searchFilters.petsSelected"
                            :options="listOfPets"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1-mbl"
                            @input="checkAnimalType"
                            :disabled="!selectedLostOrFound"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    
                    
                    <b-button class="arrowBackSearch" v-if="nextStepLostFoundSearch" @click="go_back()"><span>←</span> πίσω</b-button>
                    <b-row v-if="nextStepLostFoundSearch">
                        <b-col cols="12">
                            <label class="mb-0 mobileSearchFormLabel">
                                Περιοχή
                            </label>
                            <b-form-select v-model="searchFilters.cityId" class="form-control">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                        <b-col cols="12" align-self="end" class="pt-2">
                            <label class="mb-0 mobileSearchFormLabel">
                                Ημερο/νία
                            </label>
                            <b-form-datepicker
                                id="mbl-date-form"
                                v-model="searchFilters.date"
                                locale="el"
                                autocomplete="off"
                                :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                            ></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2" v-if="nextStepLostFoundSearch">
                        <b-col>
                            <label class="mb-0 mobileSearchFormLabel">
                                Ηλικία 
                            </label>
                            <KInput
                                title=""
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="age"
                                parent="searchFilters"
                                class="label_sign_in w-100 text-left"
                                type="number"
                            />
                        </b-col>
                        <b-col cols="8">
                            <label class="mb-0 mobileSearchFormLabel">
                                Ράτσα 
                            </label>
                            <!--multiselect 
                                v-model="searchFilters.breeds"
                                placeholder="Ράτσα"
                                :options="filteredBreeds"
                                class="label_sign_in form-control"
                                :multiple="true"
                                label="name" 
                                track-by="id"
                                :maxHeight="120"
                                :optionHeight="30"
                            >
                            </multiselect-->
                            <b-form-select v-model="searchFilters.breeds" class="form-control">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Ράτσα</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="breed in filteredBreeds" v-bind:value="breed" :key="breed.id">
                                    {{ breed.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>


                    <b-button 
                        v-if="nextStepLostFoundSearch"
                        @click="getSearchResults(searchFilters)" 
                        :disabled="!continueStep" 
                        variant="primary"
                        class="mt-3 w-100 button-orange button rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
            </b-row>
        </b-container>

  </b-jumbotron>

</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import PetMedia from '../Pets/PetMedia.vue'
import {integer, minValue } from 'vuelidate/lib/validators'
import FoundDetails from '../../components/LostAndFound/All/FoundDetails.vue'
import Multiselect from 'vue-multiselect'
import KInput from '@/components/Form/KInput.vue'
import {merge} from 'lodash'

    export default {
        data(){
            return {
                nextStepLostFoundSearch: false,
                selectedLostOrFound: false,
                listOfPets: [
                    { text: 'Σκύλος', value: 1 },
                    { text: 'Γάτα', value: 2 },
                    { text: 'Άλογο', value: 3 },
                    { text: 'Άλλο', value: 0 }
                ],
                clientValidation: {
                    searchFilters: {
                        age: {
                            integer,
                            minValue: minValue(0)
                        }
                    }
                },

                serverValidation: {},

                errorMessages: {
                    searchFilters: {
                        age:{
                            integer: 'Must be integer',
                            minValue: 'Δεν μπορεί να έχει αρνητική τιμή'
                        },
                    }
                }
            }
        },

        computed: {
            ...mapGetters('search', ['canSearch']),
            ...mapState('search', ['searchFilters', 'breeds', '$vald']),
            ...mapState('areas', ['cities']),
            ...mapState('pet', ['newPetFound', 'maxMedia', 'foundMediaFiles']),
            ...mapGetters('auth', ['authenticated']),
            filteredBreeds() {
                if (this.breeds.length) {
                    let otherTypes = this.searchFilters.petsSelected.includes(0) ? [4, 5] : []
                    return this.breeds.filter((item) => {
                        return this.searchFilters.petsSelected.includes(item.pet_type_id) ||
                            otherTypes.includes(item.pet_type_id)
                    })
                }
                return []
            },
            continueStep(){
                let flag = false

                if(this.$vald.searchFilters.$dirty){
                    if(this.$vald.searchFilters.$anyError){
                        //this.disableButtonsAction()
                    }else{
                        //this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.searchFilters.$invalid){
                        //this.disableButtonsAction()
                    }else{
                        //this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag && this.canSearch
            },
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
            enableAddModalButton() {
                return this.newPetFound.foundDate && this.newPetFound.area && this.newPetFound.type && this.newPetFound.breed_list.length
            }
        },

        methods: {
            ...mapActions('search', ['getSearchResults', 'getFilterData', 'setValidator']),
            ...mapActions('areas', ['getCities']),
            ...mapActions('pet', ['addMedia', 'createFoundPet', 'clearNewPetFound']),

            checkRadioFound() {
                this.selectedLostOrFound = true;

                var found = document.getElementById('found_checkbox').nextElementSibling;
                var lost = document.getElementById('lost_checkbox').nextElementSibling;

                found.classList.remove('notSelectedCheckbox');
                found.classList.add('seletedLostFound');
                lost.classList.remove('seletedLostFound');
                lost.classList.add('notSelectedCheckbox');
            },

            checkRadioLost() {
                this.selectedLostOrFound = true;

                var found = document.getElementById('found_checkbox').nextElementSibling;
                var lost = document.getElementById('lost_checkbox').nextElementSibling;

                lost.classList.remove('notSelectedCheckbox');
                lost.classList.add('seletedLostFound');
                found.classList.remove('seletedLostFound');
                found.classList.add('notSelectedCheckbox');
            },

            checkAnimalType() {
                this.nextStepLostFoundSearch = true;

                var child0 = document.getElementById('checkbox-group-pets-mbl_BV_option_0');
                
                if (child0.checked) {
                    child0.parentElement.className += ' selectedBackground';
                    child0.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child0.parentElement.classList.remove('selectedBackground');
                    child0.nextSibling.classList.remove('selectedWhiteFont');
                }
 
                var child1 = document.getElementById('checkbox-group-pets-mbl_BV_option_1');
                
                if (child1.checked) {
                    child1.parentElement.className += ' selectedBackground';
                    child1.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child1.parentElement.classList.remove('selectedBackground');
                    child1.nextSibling.classList.remove('selectedWhiteFont');
                }

                var child2 = document.getElementById('checkbox-group-pets-mbl_BV_option_2');
                
                if (child2.checked) {
                    child2.parentElement.className += ' selectedBackground';
                    child2.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child2.parentElement.classList.remove('selectedBackground');
                    child2.nextSibling.classList.remove('selectedWhiteFont');
                }

                var child3 = document.getElementById('checkbox-group-pets-mbl_BV_option_3');
                
                if (child3.checked) {
                    child3.parentElement.className += ' selectedBackground';
                    child3.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child3.parentElement.classList.remove('selectedBackground');
                    child3.nextSibling.classList.remove('selectedWhiteFont');
                }
            },

            go_back() {
                this.nextStepLostFoundSearch = false;
            }
        },

        components: {
            PetMedia, FoundDetails, Multiselect, KInput
        },

        created(){
            this.getCities()
            this.getFilterData()
            this.setValidator(this.$v)
            this.getSearchResults(this.searchFilters)
        },
        validations() {
            return this.rules
        },
    }
</script>

<style lang="scss" scoped>

</style>