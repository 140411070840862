<template>
    <b-navbar class="bg-white" type="light" toggleable="lg">
        <b-container fluid="xl" class="hearder-container">
            <div class="row no-gutters w-100 align-items-center">
                <div class="col-3 col-lg-2 display-desktop">
                    <router-link class="navbar-brand" :to="{name: 'Home'}">
                        <img :src="require('@/assets/images/logo.svg')" />
                    </router-link>
                </div>
                <div class="col-lg-8">
                    <b-collapse id="navbarSupportedContent" is-nav>
                        <b-navbar-nav>
                            <b-nav-item class="display-mobile" :to="{path: '/'}">Home</b-nav-item>
                            <b-nav-item v-if="authenticated" :to="{name: 'My Pets'}">My Pets</b-nav-item>
                            <b-nav-item :to="{name: 'Adoptions'}">Υιοθέτησε</b-nav-item>
                            <!--li v-if="!authenticated"><router-link class="nav-link" to="#" @click="closeMenu()">Community</router-link></li-->
                            <b-nav-item to="/lost-found">Lost & Found</b-nav-item>
                            <!--li><router-link class="nav-link" :to="{name: 'Subscribe'}" @click="closeMenu()">Subscribe</router-link></li-->
                            <b-nav-item to="/posts">Άρθρα</b-nav-item>
                            <b-nav-item-dropdown text="Κοινότητα">
                                <b-dropdown-item class="dropdown-item" to="/advertisements">Αγγελίες</b-dropdown-item>
                                <b-dropdown-item class="dropdown-item" to="/pet-walker-ads">Pet Walkers</b-dropdown-item>
                                <b-dropdown-item class="dropdown-item" to="/vets">Κτηνίατροι</b-dropdown-item>
                            </b-nav-item-dropdown>
                            <!-- <b-nav-item :to="{name: 'Subscribe'}">Go Premium</b-nav-item> -->
                            <!--li v-if="!authenticated" class="nav-item"><router-link class="nav-link" to="#" onclick="$('#navbarSupportedContent').removeClass('show');">Συζητήσεις</router-link></li-->
                        </b-navbar-nav>
                    </b-collapse>
                </div>
                <div id="togglerWrapper" class="col-lg-2 text-right">
                    <b-navbar-toggle target="navbarSupportedContent" aria-controls="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </b-navbar-toggle>
                    <div v-if="!authenticated" class="nav-item float-right">
                        <router-link class="button border-button" style="border: none" :to="{name: 'Login'}">
                            <img :src="require('@/assets/images/shortened-logo.svg')" style="width: 15px; height: 15px" /> Σύνδεση
                        </router-link>
                    </div>
                    <div>
                        <span v-if="authenticated" class="nav-item d-inline-flex align-middle">
                            <router-link class="nav-link" :to="{name: 'Profile'}" onclick="$('#navbarSupportedContent').removeClass('show');">
                                <h4 class="mb-0"><img :src="require('@/assets/images/shortened-logo.svg')" style="width: 100%; height: 27px" /></h4>
                            </router-link>
                        </span>
                    </div>
                </div>
            </div>
        </b-container>
    </b-navbar>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
    name: 'navbar',

    computed:{
        ...mapGetters('auth', ['authenticated']),
        ...mapState(['loading'])
    },
}
</script>

<style lang="scss" scoped>

</style>