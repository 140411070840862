import AdvertisementService from "../../services/AdvertisementService"
import router from '../../router/index'

export const namespaced = true

export const state = {
    newAdvertisement: {
        country : null,
        city: null,
        area: null,
        description: '',
        type: '',
        petType: null,
        price: '',
        title: ''
    },
    advertisementMediaFiles: [],
    maxMedia: 3,

    advertisement: {
        title: '',
        type: '',
        area: null,
        description: '',
        pet_type: null,
        price: '',
        media: null
    },

    canEdit: false
}

export const mutations = {

}

export const getters = {
    
}

export const actions = {

    async createAdvertisement({state}){   
        let formData = new FormData()

        if ("id" in state.newAdvertisement) {
            formData.append('id', state.newAdvertisement.id)
        }

        formData.append('area_id', state.newAdvertisement.area)
        formData.append('title', state.newAdvertisement.title)
        formData.append('description', state.newAdvertisement.description)
        formData.append('pet_type_id', state.newAdvertisement.petType)
        formData.append('type', state.newAdvertisement.type)
        formData.append('price', state.newAdvertisement.price)
        
        state.advertisementMediaFiles.forEach((file, index) => formData.append(`media[${index}]`, file))

        await AdvertisementService.createAdvertisement(formData)

        location.reload()
    },

    clearNewAdvertisement({state}){
        state.advertisementMediaFiles = []
        state.newAdvertisement = {
            country : null,
            city: null,
            area: null,
            description: '',
            type: '',
            petType: null,
            price: ''
        }
    },

    removeMedia({state}, {index}){
        state.advertisementMediaFiles.splice(index,1)
    },

    setMediaFile({state}, {value, index}){
        state.advertisementMediaFiles[index] = value
    },

    addMedia({state}){
        if(state.advertisementMediaFiles.length < state.maxMedia){
            state.advertisementMediaFiles.push(null)
        }
    },

    showAdvertisement({state}, {id}){
        return AdvertisementService.getAdvertisement(id)
            .then(r => {
                state.advertisement = r.data['advertisement']
                state.canEdit = r.data['canEdit']
            })
            .catch(e => console.log(e.message))
    },

    setAdvertisement({state}, data) {
        state.advertisementMediaFiles = []
        state.newAdvertisement = {...data}
        state.newAdvertisement.area = data.area_id
        state.newAdvertisement.petType = data.pet_type_id
    },

    // eslint-disable-next-line no-unused-vars
    deleteAdvertisement({state}, id) {
        return AdvertisementService.deleteAdvertisement(id)
            // eslint-disable-next-line no-unused-vars
            .then(r => {
                router.push({name: 'Advertisements'})
            })
            .catch(e => console.log(e.message))
    }
}