<template>
  <div class="home text-center section">
    <div class="h-100 d-flex upper-section flex-column">
      <div>
        <img :src="require('@/assets/images/logo.svg')" alt="Sozon Logo" class="display-mobile-inline" />
        <img :src="require('@/assets/images/shortened-logo.svg')" class="display-desktop-inline hmpg-logo" />
      </div>
      <div class="pt-5 display-desktop">
        <h5 class="font-weight-light">Γίνε μέλος στην πιο δυναμική κοινότητα και βρες ό,τι χρειάζεσαι <br>εσύ και το κατοικίδιό σου!</h5>
      </div>
      <div class="pt-5 display-desktop" v-if="!authenticated">
        <b-button pill :to="{name: 'Register'}" class="button button-orange font-weight-bold">Γίνε μέλος</b-button>
      </div>
      <div class="pt-5 display-desktop" v-else>
        <b-button pill :to="{name: 'My Pets'}" class="button button-orange font-weight-bold">Τα κατοικίδιά μου</b-button>
      </div>
      <div class="pt-4 pt-md-2">
        <img class="hmpg_pet_img" :src="require('@/assets/images/hmpg-animals.jpg')" />
      </div>
      <div class="pt-4 display-mobile pl-5 pr-5 pl-md-0 pr-md-0">
        <h5 class="font-weight-light home_description">Γίνε μέλος στην πιο δυναμική κοινότητα και βρες ό,τι χρειάζεσαι <br>εσύ και το κατοικίδιό σου!</h5>
      </div>
      <div class="pt-5 display-mobile" v-if="!authenticated">
        <b-button pill :to="{name: 'Register'}" class="button button-orange font-weight-bold">Γίνε μέλος</b-button>
      </div>
      <div class="pt-5 display-mobile" v-else>
        <b-button pill :to="{name: 'My Pets'}" class="button button-orange font-weight-bold">Τα κατοικίδιά μου</b-button>
      </div>
    </div>
    <div class="display-desktop">
      <LostAndFound :lostPets="lostPets" :foundPets="foundPets" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import LostAndFound from '../components/LostAndFound/Homepage/LostAndFound.vue'

export default {
  name: "Home",
  components: {
    LostAndFound
  },
  methods:{
      ...mapActions('homepage', ['getData']),
  },
  created(){
      this.getData()
  },
  computed: {
      ...mapState('homepage', ['lostPets', 'foundPets']),
      ...mapGetters('auth', ['authenticated']),
  },

  // computed: {
  //       ...mapState('pet', ['pets']),
  //   },
  //   methods:{
  //       ...mapActions('pet', ['getPets']),
  //   },
  //   created(){
  //       this.getPets()
  //   },
  //   components: {
  //       Box, Swiper, SwiperSlide
  //   },
  //   data() {
  //       return {
  //           swiperOptions: {
  //               slidesPerView: 3,
  //               navigation: {
  //                   nextEl: '.swiper-button-next',
  //                   prevEl: '.swiper-button-prev'
  //               }
  //               // ...
  //           },
  //       }
  //   }
};
</script>
