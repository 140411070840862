<template>
    <div>
        <h2 class="p-4 whiteBackgroundColor text-center">Lost</h2>

        <div class="w-75 m-auto">
            <swiper class="swiper" :options="swiperOptions">
                <swiper-slide v-for="(item) in lostPets" :key="item.id">
                    <Box :item="item" />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
        </div>

        <div class="pt-4 pb-4">
            <b-button pill :to="{name: 'All Lost and Found'}" class="button button-orange font-weight-bold">View all Lost</b-button>
        </div>

        <h2 class="p-4 whiteBackgroundColor text-center">Found</h2>

        <div class="w-75 m-auto">
            <swiper class="swiper" :options="swiperOptions">
                <swiper-slide v-for="(item) in foundPets" :key="item.id">
                    <FoundBox :item="item" />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
        </div>

        <div class="pt-4 pb-4">
            <b-button pill :to="{name: 'All Lost and Found'}" class="button button-orange font-weight-bold">View all Found</b-button>
        </div>

    </div>
</template>

<script>
import {mapState} from 'vuex'
import Box from '../All/Box.vue'
import FoundBox from '../All/FoundBox.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
    computed: {
        ...mapState('homepage', ['lostPets', 'foundPets']),
    },

    components: {
        Box, Swiper, SwiperSlide, FoundBox
    },

    data() {
        return {           
            swiperOptions: {
                slidesPerView: 3,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>