import apiClient from './Api.js'

export default { 
    createAdvertisement(data){
        return apiClient.post('/advertisement/save', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getAdvertisement(id){
        return apiClient.get('/advertisement/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    deleteAdvertisement(id){
        return apiClient.delete('/advertisement/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    }
}