<template>
    <div>
        <div class="w-75 m-auto">
            <div class="row">
                <FoundBox v-for="(item) in foundList.slice(0, 3)" :item="item" :key="item.id" class="col-lg-4"/>
            </div>
            <div class="pt-3 pb-3 text-center" v-if="foundList.length > 3">
                <b-button pill class="button button-orange font-weight-bold" v-b-toggle.more-found-pets variant="primary">View all</b-button>
            </div>
            <b-collapse id="more-found-pets" class="mt-2" v-if="foundList.length > 3">
                <div class="row">
                    <FoundBox v-for="(item) in foundList.slice(3)" :item="item" :key="item.id" class="col-lg-4"/>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import FoundBox from '../../components/LostAndFound/All/FoundBox.vue'

    export default {
        computed: {
            ...mapState('search', ['foundList'])
        },
        components: {
            FoundBox
        }
    }
</script>

<style lang="scss" scoped>

</style>