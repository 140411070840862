<template>
    <div class="container-fluid pt-5 pb-3">
        <div class="row">
            <div class="col-6">
                <div v-if="prevStep != ''">
                    <b-button 
                        variant="dark"
                        @click.prevent="goToPreviousStep()"
                    >
                        <span class="display-desktop">ΠΡΟΗΓΟΥΜΕΝΟ</span>
                        <b-icon icon="arrow-left" class="display-mobile"></b-icon>
                    </b-button>
                </div>
            </div>
            <div class="col-6 text-right">
                <b-button 
                    v-if="nextStep === 'Submit'" 
                    @click="save()" 
                    variant="dark"
                    :disabled="disableButtons" 
                >
                    ΥΠΟΒΟΛΗ
                </b-button>
                <b-button v-else 
                    variant="dark"
                    @click.prevent="goToNextStep()"
                >
                    <span class="display-desktop">ΕΠΟΜΕΝΟ</span>
                    <b-icon icon="arrow-right" class="display-mobile"></b-icon>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import { BIcon } from 'bootstrap-vue'

    export default {
        components: {
            BIcon
        },
        computed: {
            ...mapState('pet_registration', ['steps', '$vald', 'disableButtons', 'completedSteps']),

            currentStep(){
                let currentRoute = element => element.name === this.currentRouteName()

                let current_step = this.steps.findIndex(currentRoute)

                if(current_step != -1)
                    return this.steps[current_step]
                
                return ''
            },

            nextStep(){
                /*if ( this.isEdit() )
                    return 'Submit'
                */
                let currentRoute = element => element.name === this.currentRouteName()

                let current_step = this.steps.findIndex(currentRoute)
                current_step++

                if(this.steps.length !== current_step)
                    return this.steps[current_step]
                
                return 'Submit'
            },

            prevStep(){
                /*if ( this.isEdit() )
                    return 'Submit'
                */
                let currentRoute = element => element.name === this.currentRouteName()

                let current_step = this.steps.findIndex(currentRoute)
                current_step--

                if(current_step != -1)
                    return this.steps[current_step]
                
                return ''
            }
        },

        methods:{
            ...mapActions('pet_registration', ['save']),
            ...mapMutations('pet_registration', ['SET_COMPLETED_STEPS']),
            
            currentRouteName(){
                return this.$route.name
            },
            currentStepName(){
                return this.$route.meta.stepName
            },
            goToPreviousStep(){
                this.SET_COMPLETED_STEPS(this.completedSteps - 1);
                this.$router.push({name: this.prevStep.name})
            },
            goToNextStep(){
                this.$vald.$touch();
                if(this.$vald.$anyError){
                    return
                }
                this.SET_COMPLETED_STEPS(this.completedSteps + 1);
                this.$router.push({name: this.nextStep.name})
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>