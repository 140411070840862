<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
                <RegisterTemplate title="Hooray! Πες μας για το κατοικίδιό σου!">
                    <KInput
                        title="Όνομα"
                        :validation="$vald"
                        :continue-step="continueStep"
                        :errorMessages="errorMessages"
                        objectName="name"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                    />
                    <KSelect 
                        title="Είδος"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="pet_type_id"
                        :options="pet_types"
                        identifier="id"
                        val="title"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                        placeholder="Είδος"
                        @change="setPetType"
                    />
                    <KMultiSelect
                        title="Επιλέξτε μία ή περισσότερες ράτσες..."
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="breeds"
                        :options="breeds"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                        placeholder="Ράτσα"                  
                    />
                    <KDate 
                        title="Ημερομηνία γέννησης"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="dob"
                        parent="pet"
                        class="label_sign_in"
                    />
                </RegisterTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import RegisterTemplate from '@/components/Pets/Registration/RegisterTemplate.vue';
import KSelect from '@/components/Form/KSelect.vue'
import KDate from '@/components/Form/KDate.vue'
import KInput from '@/components/Form/KInput.vue'
import KMultiSelect from '@/components/Form/KMultiSelect.vue'
import moment from 'moment'

    export default {
        computed: {
            ...mapState('pet_registration', ['pet', 'pet_types', '$vald']),
            breeds() {
                if ( this.pet.pet_type_id ) {
                    let breeds = this.pet_types.find((item) => item.id == this.pet.pet_type_id).breeds;
                    let otherIndex = breeds.findIndex((item) => item.name == 'Άλλο');
                    let other = breeds[otherIndex];

                    breeds.splice(otherIndex, 1);
                    breeds.splice(0, 0, other);

                    return breeds;
                }
                return []
            },
            continueStep(){
                let flag = false

                if(this.$vald.pet.$dirty){
                    if(this.$vald.pet.$anyError){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.pet.$invalid){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            }
        },
        data(){
            return {
                clientValidation: {
                    pet: {
                        name:{
                            required,
                        },
                        dob:{
                            maxValue: value => {
                                if ( ! value )
                                    return true
                                let currentDate = moment(new Date()).startOf('day')
                                return value <= currentDate.format('YYYY-MM-DD')
                            }
                        },
                        pet_type_id:{
                            required,
                        },
                        breeds: {
                            required
                        },
                                          
                    }
                },

                serverValidation: {},

                errorMessages: {
                    pet: {
                        breeds: {
                            required: 'Η Ράτσα είναι υποχρεωτική!'
                        },
                        pet_type_id: {
                            required: 'Το είδος είναι υποχρεωτικό!',
                        },
                        name: {
                            required: 'The Name is required!',
                        },
                        dob: {
                            minValue: 'The maximum Date of Birth is Today!'
                        },
                    }
                }
            }
        },
        
        validations() {
            return this.rules
        },
        
        methods:{
            ...mapActions('pet_registration', ['disableButtonsAction', 'enableButtonsAction', 'setValidator', 'setBreeds']),
            setPetType() {
                if (this.pet.pet_type_id !== '') {
                    this.pet.pet_type = this.pet_types.find((item) => item.id == this.pet.pet_type_id);
                }
                else {
                    this.pet.pet_type = ''
                }
            }
        },

        created(){
            this.setValidator(this.$v)
            this.setPetType()
        },
        components: {
            RegisterTemplate, KSelect , KDate, KMultiSelect, KInput
        }
    }
</script>

<style lang="scss" scoped>

</style>