import apiClient from './Api.js'

export default { 
    fetchEventTypes(data){
        return apiClient.get('/events/fetchAll/', {params: data}, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    createEvent(data){
        return apiClient.post('/events/create', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    updateEvent(id, data){
        return apiClient.post('/events/update/' + id, data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    deleteEvent(id){
        return apiClient.delete('/events/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    deleteReminder(id){
        return apiClient.delete('/reminders/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    /*createCustomReminder(pet, data){
        return apiClient.post('/events/create-custom-reminder/' + pet, data)
    }*/
}