<template>
    <div v-if="user !== null">
        <div class="row">
            <div class="col-md-6 text-lg-left d-flex"><h3 class="loginTitle mb-0 align-self-center">Προφίλ χρήστη</h3></div>
            <div class="col-md-6 text-lg-right">
                <a href="#" class="button border-button profile-btn" @click.prevent="logoutUser">
                    Αποσύνδεση
                </a>
            </div>
        </div>
        
        <Errors :errors="errors" />

        <div class="formBackgroundColor">
            <div class="maxWidthSign m-auto pt-5">
                <div class="form-group">
                    <KInput
                        :validation="$vald"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="name"
                        parent="user"
                        placeholder="Όνομα"
                        title="Όνομα"
                        type="text"
                        autocomplete="name"
                        autofocus
                        class="label_sign_in requiredLabel"
                    />
                </div>

                <div class="form-group">
                    <KInput
                        :validation="$vald"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="last_name"
                        parent="user"
                        placeholder="Επώνυμο"
                        title="Επώνυμο"
                        type="text"
                        autocomplete="last_name"
                        class="label_sign_in requiredLabel"
                    />
                </div>

                <div class="form-group">
                    <KInput
                        :validation="$vald"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="phone"
                        parent="user"
                        placeholder="Τηλέφωνο"
                        title="Τηλέφωνο"
                        type="text"
                        autocomplete="phone"
                        class="label_sign_in"
                    />
                </div>

                <div class="form-group">
                    <KSelect 
                        v-if="countries"
                        title="Χώρα"
                        placeholder="Χώρα"
                        :validation="$vald"
                        :continueStep="true"
                        :errorMessages="errorMessages"
                        objectName="country"
                        :options="countries"
                        identifier="id"
                        val="name"
                        parent="user"
                        class="label_sign_in requiredLabel"
                        @change="initCity"
                    />

                    <KSelect 
                        v-if="cities.length"
                        title="Πόλη"
                        placeholder="Πόλη"
                        :validation="$vald"
                        :continueStep="true"
                        :errorMessages="errorMessages"
                        objectName="city"
                        :options="cities"
                        identifier="id"
                        val="name"
                        parent="user"
                        class="label_sign_in requiredLabel"
                        @change="initArea"
                    />

                    <KSelect 
                        v-if="user.city !== null"
                        title="Περιοχή"
                        placeholder="Περιοχή"
                        :validation="$vald"
                        :continueStep="true"
                        :errorMessages="errorMessages"
                        objectName="area_id"
                        :options="areas"
                        identifier="id"
                        val="name"
                        parent="user"
                        class="label_sign_in requiredLabel"
                    />
                    <KInput
                        :validation="$vald"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="address"
                        parent="user"
                        placeholder="Διεύθυνση"
                        title="Διεύθυνση"
                        type="text"
                        autocomplete="address"
                        class="label_sign_in"
                    />
                    <KInput 
                        :validation="$vald"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="postal_code"
                        parent="user"
                        placeholder="Τ.Κ."
                        title="Τ.Κ."
                        type="number"
                        class="label_sign_in"
                    />
                </div>
            </div>
        </div>

        <div class="formBackgroundColor">
            <div class="maxWidthSign m-auto pt-1">
                <div class="form-group requiredLabel">
                    <label>Email</label>
                    <input class="form-control" type="text" v-model='user.email' disabled />
                </div>
            </div>
        </div>
        <p class="pt-3">*υποχρεωτικά πεδία</p>

        <div class="form-group row mb-0 mt-5">
            <div class="col-md-12 text-center">
                <button @click.prevent="updateProfile" type="submit" class="btn btn-primary registreBtn">
                    ΕΝΗΜΕΡΩΣΗ ΣΤΟΙΧΕΙΩΝ
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import {merge} from 'lodash'
import {mapActions, mapState, mapMutations} from 'vuex'
import KInput from '../../Form/KInput.vue'
import KSelect from '../../Form/KSelect.vue'
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import Errors from '../../Errors/ServerErrorMessages.vue'

    export default {
        data(){
            return {
                clientValidation: {
                    user: {
                        name: {
                            required,
                            minLength: minLength(3)
                        },
                        last_name: {
                            required,
                            minLength: minLength(5)
                        },
                        phone:{
                            numeric,
                            minLength: minLength(10),
                            maxLength: maxLength(10)
                        },
                        address: {
                            minLength: minLength(3)
                        },
                        country: {
                            required
                        },
                        city: {
                            required
                        },
                        area_id: {
                            required
                        },
                        postal_code: {
                        }
                    }
                },

                serverValidation: {

                },

                errorMessages: {
                    user: {
                        name: {
                            required: 'Το όνομα είναι υποχρεωτικό!',
                            minLength: 'Το όνομα πρέπει να έχει τουλάχιστον τέσσερεις (4) χαρακτήρες!'
                        },
                        last_name: {
                            required: 'Το επώνυμο είναι υποχρεωτικό!',
                            minLength: 'Το επώνυμο πρέπει να έχει τουλάχιστον πέντε (5) χαρακτήρες!'
                        },
                        phone:{
                            required: 'Το τηλέφωνο είναι υποχρεωτικό!',
                            numeric: 'Το τηλέφωνο πρέπει να αποτελείται μόνο από αριθμούς',
                            minLength: 'Το τηλέφωνο πρέπει να έχει δέκα (10) χαρακτήρες!',
                            maxLength: 'Το τηλέφωνο πρέπει να έχει δέκα (10) χαρακτήρες!'
                        },
                        address: {
                            required: 'Η διεύθυνση είναι υποχρεωτική!',
                            minLength: 'Η διεύθυνση πρέπει να έχει τουλάχιστον τρεις (3) χαρακτήρες!'
                        },
                        country: {
                            required: 'Η επιλογή της χώρας είναι υποχρεωτική!'
                        },
                        city: {
                            required: 'Η επιλογή της πόλης είναι υποχρεωτική!'
                        },
                        area_id: {
                            required: 'Η επιλογή της περιοχής είναι υποχρεωτική!'
                        },
                        postal_code: {
                        }
                    }
                },
                loading: false,
                error: null
            }
        },

        components:{
            KInput, Errors, KSelect
        },

        computed:{
            ...mapState('auth', ['$vald',  'errors', 'user']),
            ...mapState('areas', ['countries']),
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
            cities() {
                if ( this.user.country !== null && this.countries) {
                    return this.countries.find((item) => item.id == this.user.country).cities
                }
                return []
            },

            areas() {
                if ( this.user.city !== null && this.countries) {
                    return this.cities.find((item) => item.id == this.user.city).areas
                }
                return []
            }
        },

       methods: {
            ...mapActions('auth', ['changeProfile', 'setValidator', 'logout', 'getUser']),
            ...mapMutations('auth', ['CLEAR_ERRORS', 'SET_ERRORS']),
            ...mapActions(['enableLoader', 'disableLoader']),
            ...mapActions('areas', ['getAreas']),

            async updateProfile(){
                this.error = null
                this.CLEAR_ERRORS();

                this.$v.$touch();
                if(this.$v.$anyError){
                    return
                }

                try{
                    await this.changeProfile(this.user)
                    this.$nextTick(() => {
                        this.$vald.$reset();
                    });
                } catch(e){
                    this.error = e
                    this.SET_ERRORS(e.response.data.errors);
                } finally{
                    this.loading = false
                }
            },
            
            async logoutUser(){
                this.enableLoader()

                try{
                    await this.logout()
                    localStorage.clear()
                    this.$router.push({name: 'Login'})
                } catch (error) {
                    this.error = error
                    console.log(error)
                } finally {
                    this.disableLoader()
                }
            },
            initCity() {
                this.user.city = null;
                this.user.area_id = null;
            },

            initArea() {
                this.user.area_id = null;
            }
        },
        
        validations() {
            return this.rules
        },

        created(){
            this.getAreas()
            this.getUser()
            this.setValidator(this.$v)
        },            
        
    }
</script>

<style lang="scss" scoped>

</style>