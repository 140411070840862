<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-auto">
                <b-avatar size="6rem" :src="showPhoto" class="my_pet_avatar"></b-avatar>
                <p v-if="pet.id" class="mt-4 text-center">ID: {{ pet.id }}</p>
            </div>
        </div>
        <div id="togglerContentsPetLog" class="row">
            <div :class="mode == 'horizontal' ? 'col-lg-6' : 'col-lg-12'">
                <CharacteristicsBox v-for="(item, index) in firstRow" :key="index" :item="item" :showEmpty="showEmpty" />
            </div>
            <div :class="mode == 'horizontal' ? 'col-lg-6' : 'col-lg-12'">
                <CharacteristicsBox v-for="(item, index) in secondRow" :key="index" :item="item" :showEmpty="showEmpty" />
            </div>
        </div>
        <b-modal id="certificate-modal" hide-footer title="Πιστοποιητικό Ηλεκτρονικής Ταυτοποίησης">
            <b-img :src="showCertificate" fluid class="m-auto d-block" />
        </b-modal>
    </div>
</template>

<script>

import CharacteristicsBox from '../Registration/CharacteristicsBox.vue'

export default {
    props: {
        pet:  {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            default: "horizontal"
        },
        showEmpty: {
            type: Boolean,
            default: true
        }
    },

    data(){
        return {
            baseUrl: process.env.VUE_APP_WEB_URI
        }
    },

    computed: {
        breeding() {
            if(Array.isArray(this.pet.breeds) && this.pet.breeds.length){
                return this.pet.breeds.map(breed => breed.name).join(", ")
            }
            return ''
        },

        colouring(){
            if(Array.isArray(this.pet.colours) && this.pet.colours.length){
                return this.pet.colours.map(colour => colour.name).join(", ")
            }
            return ''
        },

        vaccinesList(){
            if(Array.isArray(this.pet.listVaccines) && this.pet.listVaccines.length){
                return this.pet.listVaccines.map(v => {
                    return {
                        label: v.vaccine.name,
                        value: `(${v.date})`
                    }
                })
            }
            return []
        },

        showPhoto() {
            if (typeof this.pet.photo === 'object' && !Array.isArray(this.pet.photo) && this.pet.photo !== null) {
                return URL.createObjectURL(this.pet.photo);
            } else if (this.pet.photo !== null) {
                return this.baseUrl + this.pet.photo
            }

            return null;
        },

        showCertificate() {
            if (typeof this.pet.certificate === 'object' && !Array.isArray(this.pet.certificate) && this.pet.certificate !== null) {
                return URL.createObjectURL(this.pet.certificate);
            } else if (this.pet.certificate !== null) {
                return this.baseUrl + this.pet.certificate
            }

            return null;
        },

        firstRow() {
            return [
                {
                    title: "Κατοικίδιο",
                    characteristics: [
                        {
                            label: "Όνομα",
                            value: this.pet.name
                        },
                        {
                            label: "Ημερομηνία Γέννησης",
                            value: this.pet.dob
                        },
                        {
                            label: "Είδος",
                            value: this.pet.pet_type.title
                        },
                        {
                            label: "Ράτσα",
                            value: this.breeding
                        },
                        {
                            label: "Pedigree ID",
                            value: this.pet.petigree_id
                        },
                    ]
                },
                {
                    title: "Γονείς",
                    characteristics: [
                        {
                            label: "Μητέρα",
                            value: this.pet.mother
                        },
                        {
                            label: "Πατέρας",
                            value: this.pet.father
                        },
                        {
                            label: "Γιαγιά 1",
                            value: this.pet.grandmother1
                        },
                        {
                            label: "Παππούς 1",
                            value: this.pet.grandfather1
                        },
                        {
                            label: "Γιαγιά 2",
                            value: this.pet.grandmother2
                        },
                        {
                            label: "Παππούς 2",
                            value: this.pet.grandfather2
                        },
                    ]
                },
                {
                    title: "Ταυτότητα",
                    characteristics: [
                        {
                            label: "Chip ID",
                            value: this.pet.chip_id
                        },
                        {
                            label: "Passport ID",
                            value: this.pet.passport_no
                        },
                        {
                            label: "Πιστοποιητικό Ηλεκτρονικής Ταυτοποίησης",
                            value: this.pet.certificate,
                            type: 'link'
                        },
                    ]
                },
            ];
        },
        secondRow() {
            return [
                {
                    title: "Χαρακτηριστικά",
                    characteristics: [
                        {
                            label: "Χρώμα",
                            value: this.colouring
                        },
                        {
                            label: "Φύλο",
                            value: this.pet.sex ? 'Θηλυκό' : 'Αρσενικό'
                        },
                        {
                            label: "Βάρος",
                            value: this.pet.weight ? this.pet.weight + this.pet.weightMeasurement : ''
                        },
                    ]
                },
                {
                    title: "Ιστορικό υγείας",
                    characteristics: [
                        {
                            label: "Στείρωση",
                            value: this.pet.sterilization ? 'Ναι' : 'Όχι'
                        },
                        {
                            label: "Γνωστές Ασθένειες",
                            value: this.pet.diseases.join(", ")
                        },
                    ]
                },
                {
                    title: "Εμβόλια",
                    characteristics: this.vaccinesList
                }
            ]
        }
    },
    components: {
        CharacteristicsBox
    }
}
</script>

<style lang="scss" scoped>

</style>