<template>
    <div>
        <h4 v-if="categories.length">Κατηγορίες</h4>
        <div v-for="item in categories" :key="item.id">
            <router-link :to="{'path' : '/posts?category=' + item.slug}" class="grayLink">{{ item.name }}</router-link>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
    computed: {
        ...mapState('post', ['categories']),
    },

    methods: {
        ...mapActions('post', ['getCategories']),
    },

    created() {
        this.getCategories()
    }
}
</script>