<template>
    <div class="myPetsSection">
        <h2 
            class="whiteBackgroundColor text-center innerPageHeader"
        >
            Τα Κατοικίδιά μου 
            
            <!-- <span v-if="activePlan !== null && activePlan !== undefined">
                ({{ activePlan.max - loggedPets }} διαθέσιμα Logs)
            </span> -->
        </h2>
        <div class="w-75 m-auto">
            <b-row>
                <b-col lg="4" v-for="(item) in pets" :key="item.id" class="pb-5">
                    <Box :item="item" />
                </b-col>
            </b-row>
            <h3 class="mb-4 plus-btn-container pt-4 pt-lg-0 text-center">
                <router-link class="orangeLink d-inline" :to="{name: 'Create'}">
                    <b-icon-plus-circle class="plus-btn"></b-icon-plus-circle>
                </router-link>
            </h3>
            <!--li v-if="authenticated" class="nav-item"><router-link class="nav-link" :to="{name: 'Create'}" onclick="$('#navbarSupportedContent').removeClass('show');">Εγγραφή κατοικιδίου</router-link></li-->
        </div>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import Box from '../../components/Pets/All/Box.vue'
import { BIconPlusCircle } from 'bootstrap-vue'

export default {
    computed: {
        ...mapState('pet', ['pets', 'loggedPets']),
        ...mapGetters('auth', ['user', 'activePlan'])
    },
    methods:{
        ...mapActions('pet', ['getMyPets', 'getloggedPets']),
        ...mapActions('auth', ['getUser']),
    },
    created(){
        this.getUser()
        this.getMyPets()
        this.getloggedPets()
    },
    components: {
        Box, BIconPlusCircle
    }
}
</script>

<style lang="scss" scoped>

</style>