<template>
    <div>
        <h2 class="p-4 whiteBackgroundColor text-center display-desktop">Προβολή</h2>

        <div class="maxWidth75 m-lg-auto">
            <PetAlert class="mt-3"/>

            <Message v-if="changedOwnerMessage.length" :message="changedOwnerMessage" :type="type" />

            <div class="text-center text-lg-right">
                <b-dropdown id="pet-actions" right text="Actions" variant="primary" class="rounded-dropdown">
                    <b-dropdown-item v-if="petLosts === null" @click="showLostModal = true">Χάθηκε</b-dropdown-item>
                    <b-dropdown-item @click="putPetForAdoption" v-if="pet.status !== 'adoption'">Προς υιοθεσία</b-dropdown-item>
                    <b-dropdown-item @click="removeFromAdoption" v-else>Ακύρωση υιοθεσίας</b-dropdown-item>
                    <b-dropdown-item v-if="petLosts !== null" @click="petIsFound">Βρέθηκε</b-dropdown-item>
                    <b-dropdown-item v-if="isPremium" v-b-modal.changeOwner>Αλλαγή ιδιοκτησίας</b-dropdown-item>
                    <b-dropdown-item @click="$refs.pdfForm.generateReport()">Export PDF</b-dropdown-item>
                    <b-dropdown-item v-b-modal.linkModal>Κοινοποίηση προφίλ</b-dropdown-item>
                    <b-dropdown-item v-b-modal.qrModal>QR Code</b-dropdown-item>
                </b-dropdown>
            </div>
            <b-modal 
                id="linkModal" 
                @ok="$bvModal.hide('linkModal');"
                :ok-only="true"
                :hide-header="true"
                size="lg"
            >
                <PetLinkForm />
            </b-modal>

            <b-modal 
                id="qrModal" 
                :hide-footer="true"
                size="md"
            >
                <div>
                    <h4 class="mb-0">Όνομα: {{pet.name}}</h4>
                    <h5 class="mb-0" v-if="pet.breeds && pet.breeds.length">Ράτσα: {{pet.breeds.map(item => item.name).join(", ")}}</h5>
                    <h5 class="mb-0" v-if="pet.colours && pet.colours.length">Χρώμα: {{pet.colours.map(item => item.name).join(", ")}}</h5>
                </div>
                <div class="text-center pt-3 pb-3">
                    <qrcode-vue :value="getLink()" :size="280" level="H" class="qr-code"/>
                </div>
            </b-modal>

            <b-modal 
                id="changeOwner" 
                :hide-header="true"
                @ok="changeUserOnPet"
                :ok-disabled="!enableChangeOwnerButton"
            >
                <ChangeOwner />
            </b-modal>

            <CreateLostForPet v-if="petLosts === null" class="mt-3" :showModal="showLostModal" @closed="showLostModal = false"/>
            <div class="row pt-4 pb-4 customNoGutters">
                <div id="pet_sidebar_id" class="col-lg-3 pet-sidebar w-75 align-self-start">
                    <div class="hiddenSidebarPetLog" id="pet-show">
                        <Show :pet="pet" mode="vertical" :showEmpty="false"/>
                        <b-button :to="{name: 'EditCreate'}" pill class="button button-orange font-weight-bold mt-3">Επεξεργασία</b-button>
                    </div>
                    <div id="togglerContentPetLog" class="display-mobile" v-on:click="toggleContent">...</div>
                </div>

                <div class="col-lg-9 pl-0 pr-0">
                    <div class="text-center pb-4 pt-5 pt-lg-0 boxShadowBottom" v-if="!canShowLog">
                        <b-img :src="require('@/assets/images/pet_log.png')" fluid />
                    </div>

                    <div class="text-center pl-4 pr-4">
                        <b-button
                            v-if="showEnableButton && !loadingButton"
                            pill
                            class="button button-orange font-weight-bold mt-3"
                            @click="checkIfWantsLog"
                        >
                            Ενεργοποίησε το PetLog
                        </b-button>
                        <b-button
                            disabled
                            pill
                            class="button button-orange font-weight-bold mt-3"
                            v-if="loadingButton"
                        >
                            Παρακαλώ περιμένετε...
                        </b-button>
                        <div v-if="resultSuccessMessage">
                            <b-alert variant="success" show>
                                {{ messageLog }}
                            </b-alert>
                        </div>
                    </div>

                    <div v-if="resultFailMessage" class="pl-4 pr-4">
                        <b-alert variant="warning" show>
                            {{ messageLog }}
                            <router-link :to="{name: 'Subscribe'}">
                                Aναβαθμίστε ή αποκτήστε κάποιο Premium πακέτο
                            </router-link>
                        </b-alert>
                    </div>

                    <PetLog v-if="canShowLog" />
                </div>
            </div>
        </div>
        <PdfForm ref="pdfForm" class="d-none" />
    </div>
</template>

<script>
import Show from '../../components/Pets/Show/Show.vue'
import PetLog from '../../components/Pets/Show/Petlog/PetLog.vue'
import PetAlert from '../../components/Pets/Show/PetAlert.vue'
import PdfForm from '../../components/Pets/Show/PdfForm.vue'
import CreateLostForPet from '../../components/Pets/Show/CreateLostForPet.vue'
import PetLinkForm from '../../components/Pets/Show/PetLinkForm.vue'
import ChangeOwner from '../../components/Pets/Show/ChangeOwner.vue'
import {mapState, mapGetters, mapActions} from 'vuex'
import Message from '../../components/Notifications/Message.vue'
import QrcodeVue from 'qrcode.vue'

export default {
    components: {
        Show, PetAlert, CreateLostForPet, PetLog, PetLinkForm, ChangeOwner, Message, 
        PdfForm, QrcodeVue
    },

    computed: {
        ...mapState('pet', ['pet', 'enableChangeOwnerButton', 'user', 'showEnableButton']),
        ...mapGetters('pet', ['petLosts']),
        ...mapGetters('auth', ['isPremium']),

        resultSuccessMessage(){
            return !this.showEnableButton && !this.loadingButton && this.pet.isLogged === 1 && this.messageLog.length
        },

        resultFailMessage(){
            return !this.showEnableButton && !this.loadingButton && this.pet.isLogged === 0
        },

        canShowLog(){
            return this.isPremium && this.pet.isLogged === 1
        }
    },

    methods:{
        ...mapActions('pet', ['showPet', 'petIsFound', 'putPetForAdoption', 'removeFromAdoption', 'changePetOwnership', 'checkLogForEnable' ,'hideLogButton', 'showLogButton', 'setLogOfPet']),
        ...mapActions('auth', ['getUser']),

        async checkIfWantsLog(){
            this.loadingButton = true

            try{
                let r = await this.checkLogForEnable()

                if(r.status === 200){
                    this.setLogOfPet(r.data['isLogged'])
                    this.messageLog = r.data['message']
                    this.loadingButton = false
                }
            } catch(e){
                console.log(e)
                this.loadingButton = false
            }
        },

        toggleContent: function() {
            var elem = document.getElementById('pet-show');
            
            elem.classList.toggle('hiddenSidebarPetLog');
        },

        getLink() {
            let link = this.$router.resolve({ 
                name: 'QR Code Pet Info',
                params: { uuid: this.pet.qr_code_url },
            });
            return window.location.origin + link.href;
        },

        async changeUserOnPet(){

                this.loading = true

                try{
                    let r = await this.changePetOwnership()

                    if(r.status === 200){
                        this.requestSent = true
                        this.loading = false
                        this.type = 'success'
                        this.changedOwnerMessage = r.data.message
                    }
                } catch(e){
                    this.type = 'danger'
                    if(e.response.data.message.includes('Duplicate entry')){
                        this.changedOwnerMessage = 'Το αίτημα για αλλαγή ιδιοκτήτη υπάρχει...'
                    }else{
                        this.changedOwnerMessage = e.response.data.message
                    }
                    this.loading = false
                }
        }
    },

    created(){
        this.getUser()

        let data = {
            id: this.$route.params.id
        }
        this.showPet(data)
    },

    data() {
        return {
            showLostModal: false,
            loading: false,
            requestSent: false,
            errors: {},
            changedOwnerMessage: '',
            type: '',
            loadingButton: false,
            messageLog: ''
        }
    }
}
</script>

<style lang="scss" scoped>

</style>