<template>
  <div class="maxWidth75 section m-auto">
    <h1>404</h1>
    <h3>
      The page you' re looking for is not here.
    </h3>
    <p>
      <template v-if="resource">
        {{ resource }}
      </template>
    </p>
    <p>
      <router-link :to="{name: 'Home'}">
        Back to the Home Page!
      </router-link>
    </p>

    <pre>
      {{ higherRole.name }}
    </pre>
  </div>
</template>


<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    resource: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters('auth', ['authenticated', 'higherRole'])
  }
}
</script>
