import apiClient from './Api.js'

export default { 
    getResults(data){
        return apiClient.post('search/getSearchResults', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    postAdoptionsList(data){
        return apiClient.post('search/get-all-adoptions', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    postAdvertisementList(data){
        return apiClient.post('search/get-advertisements', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    postPetWalkerAdList(data){
        return apiClient.post('search/get-pet-walker-ads', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    postVetList(data){
        return apiClient.post('search/vets', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },


    getFilterData() {
        return apiClient.get('search/filter-data', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    }
}