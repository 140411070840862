<template>
    <div>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Ημερομηνία</label>
            </b-col>
            <b-col>
                <b-form-datepicker
                    v-model="newPetFound.foundDate"
                    locale="el"
                    :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                ></b-form-datepicker>
            </b-col>
        </b-row>
        
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Χώρα</label>
            </b-col>
            <b-col>
                <b-form-select @change="initCity" v-model="newPetFound.country">
                    <b-form-select-option v-for="c in countries" :key="c.id" :value="c">
                        {{ c.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="newPetFound.country.cities.length">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Πόλη</label>
            </b-col>
            <b-col>
                <b-form-select @change="initArea" v-model="newPetFound.city">
                    <b-form-select-option v-for="ct in newPetFound.country.cities" :key="ct.id" :value="ct">
                        {{ ct.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="newPetFound.city !== null">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Περιοχή</label>
            </b-col>
            <b-col>
                <b-form-select @change="initNeighborhood" v-model="newPetFound.area">
                    <b-form-select-option v-for="a in newPetFound.city.areas" :key="a.id" :value="a">
                        {{ a.name }}
                    </b-form-select-option>
                </b-form-select >
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="newPetFound.area !== null">
            <b-col align-self="center" sm="4">
                <label class="mb-0">Γειτονιά</label>
            </b-col>
            <b-col>
                <b-form-input v-model="newPetFound.neighborhood" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4">
                <label class="mb-0">
                    Περιγραφή
                </label>
            </b-col>
            <b-col>
                <b-form-input v-model="newPetFound.description" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Είδος</label>
            </b-col>
            <b-col>
                <b-form-select v-model="newPetFound.type">
                    <b-form-select-option v-for="petType in petTypes" :key="petType.id" :value="petType">
                        {{ petType.title }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="newPetFound.type !== null">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Ράτσα</label>
            </b-col>
            <b-col>
                <multiselect 
                    v-model="newPetFound.breed_list" 
                    :options="breeds"
                    class="label_sign_in"
                    placeholder="Ράτσα"
                    :multiple="true"
                    :close-on-select="true" 
                    :clear-on-select="false" 
                    :preserve-search="true" 
                    label="name" 
                    track-by="name"
                >
                    <template 
                        slot="selection"></template>
                </multiselect>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import Multiselect from 'vue-multiselect'

    export default {
        computed: {
            ...mapState('pet', ['newPetFound', 'petTypes']),
            ...mapState('areas', ['countries']),

            breeds() {
                if ( this.newPetFound.type ) {
                    return this.petTypes.find((item) => item.id == this.newPetFound.type.id).breeds;
                }
                return []
            }
        },

        created(){
            this.getPetTypes()
            if ( ! this.countries )
                this.getAreas()
        },

        methods:{
            ...mapActions('pet', ['initCity', 'initArea', 'initNeighborhood', 'getPetTypes']),
            ...mapActions('areas', ['getAreas']),
        },
        
        components: {
            Multiselect
        }
    }
</script>

<style lang="scss" scoped>

</style>