import EventService from "../../services/EventService"

export const namespaced = true

export const state = {
    eventTypes : [],

    new: {
        eventId : null,
        name    : '', 
        date    : null,
        description: '',
        reminder_id: null,
        eventSubtypeId: null
    },

    petType: null,

    eventPhotos: [],
    maxPhotos: 3,

    deletedPhotos: [],

    customReminders: [],

    selectedCustomReminders: [],

    selectedReminders: [],

    showCustomReminderForm: false,

    customReminder: {
        name: '',
        notification_date: '',
        description: '',
        setoff: '',
        event_type_id: '',
        event_subtype_id: null
    },
}

export const mutations = {

    SET_EVENT_ID(state,value){
        state.new.eventId = value
    },

    SET_EVENT_SUBTYPE_ID(state,value){
        state.new.eventSubtypeId = value
    },

    CLEAR_EVENT(state){
        state.new.eventId = null
        state.new.eventSubtypeId = null
        state.new.name = ''
        state.new.description = ''
        state.new.date = ''
        state.new.reminder_id = ''
        state.eventPhotos = []
        state.deletedPhotos = []
        state.customReminders = []
        state.selectedReminders = []
        state.showCustomReminderForm = false
        state.customReminder = {
            name: '',
            notification_date: '',
            description: '',
            setoff: '',
            event_type_id: '',
            event_subtype_id: null
        }
        state.selectedCustomReminders = []
    },

    SET_EVENT_DESCRIPTION(state, value){
        state.new.description = value
    },

    SET_SELECTED_REMINDERS(state, value){
        state.selectedReminders = value
    },

    SET_EVENT_DATE(state, value){
        state.new.date = value
    },

    CLEAR_PHOTOS(state){
        state.eventPhotos = []
    },

    SET_EVENT(state, event) {
        state.new.id = event.id
        state.new.eventId = event.event_type_id
        state.new.eventSubtypeId = event.event_subtype_id
        state.new.date = event.date
        state.new.description = event.description
        state.new.reminder_id = event.reminder_id
        state.eventPhotos = event.images
        state.deletedPhotos = []
        state.reminders = event.reminders
    },

    INIT_CUSTOM_REMINDER(state) {

        state.customReminder = {
            name: '',
            notification_date: '',
            description: '',
            setoff: '',
            event_type_id: '',
            event_subtype_id: null
        }

        state.showCustomReminderForm = false
    },

    PUSH_REMINDER(state) {
        state.customReminders.push({...state.customReminder});
    },

    SET_PET_TYPE(state, value) {
        state.petType = value
    },

    SET_REMINDER_FORM(state, value) {
        state.showCustomReminderForm = value
    },

    SET_SELECTED_CUSTOM_REMINDERS(state, value) {
        state.selectedCustomReminders = value
    },
}

export const getters = {
    new_is_filled: state => state.new.date != null && state.new.date != "" && state.new.eventId !== null,

    enableAddModalButton: (state, getters) => getters.new_is_filled,

    enableAdd: state => state.reminder
}

export const actions = {
    fetchTypes({state}, data){
        return EventService.fetchEventTypes(data)
            .then(r => {
                state.eventTypes = r.data
                state.petType = data.pet_type_id
            })
            .catch(e => console.log(e))
    },

    setEventId({commit},id){
        commit('SET_EVENT_ID',id)
    },

    setEventSubtypeId({commit},id){
        commit('SET_EVENT_SUBTYPE_ID',id)
    },

    setEventDescription({commit}, description){
        commit('SET_EVENT_DESCRIPTION', description)
    },

    setEventDate({commit}, date){
        commit('SET_EVENT_DATE', date)
    },

    setSelectedReminders({commit}, value){
        commit('SET_SELECTED_REMINDERS', value)
    },

    setNewEvent({commit}, event) {
        commit('SET_EVENT', event)
    },

    clearEvent({commit}){
        commit('CLEAR_EVENT')
        commit('CLEAR_PHOTOS')
    },

    removeReminder({commit}, index) {
        commit('REMOVE_REMINDER', index);
    },

    setReminderForm({commit}, value) {
        commit('SET_REMINDER_FORM', value)
    },

    setSelectedCustomReminders({commit}, value) {
        commit('SET_SELECTED_CUSTOM_REMINDERS', value)
    },

    async createPetLifeEvent({state}, pet){   
        let formData = new FormData()

        formData.append('event_type_id', state.new.eventId)
        formData.append('event_subtype_id', state.new.eventSubtypeId ? state.new.eventSubtypeId : '')
        formData.append('description', state.new.description)
        formData.append('date', state.new.date)
        formData.append('pet_id', pet.id)
        formData.append('pet_type_id', pet.pet_type_id)
        formData.append('reminders', JSON.stringify(state.selectedCustomReminders))
        formData.append('selected_reminders', JSON.stringify(state.selectedReminders))

        if (state.new.reminder_id) {
            formData.append('reminder_id', state.new.reminder_id)
        }

        for(const [index, file] of state.eventPhotos.entries()){
            formData.append(`photos[${index}]`, file)
        }
        formData.append('folder', pet.folder)
        formData.append('pet_name', pet.name)

        await EventService.createEvent(formData)

        location.reload()           // TODO must change this not load USE VueX
    },

    async updateEvent({state}, pet){   
        let formData = new FormData()

        formData.append('event_type_id', state.new.eventId)
        formData.append('description', state.new.description)
        formData.append('event_subtype_id', state.new.eventSubtypeId ? state.new.eventSubtypeId : '')
        formData.append('date', state.new.date)
        formData.append('pet_id', pet.id)
        formData.append('pet_type_id', pet.pet_type_id)
        formData.append('deleted_photos', JSON.stringify(state.deletedPhotos))

        for(const [index, file] of state.eventPhotos.entries()){
            formData.append(`photos[${index}]`, file)
        }
        formData.append('folder', pet.folder)
        formData.append('pet_name', pet.name)

        await EventService.updateEvent(state.new.id, formData)

        location.reload()           // TODO must change this not load USE VueX
    },

    async deleteEvent({state}, event){   

        await EventService.deleteEvent(event.id)

        //the state needs to be used because otherwise we get an error
        console.log(state.maxPhotos)

        location.reload()           // TODO must change this not load USE VueX
    },
    
    async deleteReminder({state}, event){   

        await EventService.deleteReminder(event.id)

        //the state needs to be used because otherwise we get an error
        console.log(state.maxPhotos)

        location.reload()           // TODO must change this not load USE VueX
    },

    saveCustomReminder({commit}){
        commit('PUSH_REMINDER')
        commit('INIT_CUSTOM_REMINDER')
    },

    addPhoto({state}){
        if(state.eventPhotos.length < state.maxPhotos){
            state.eventPhotos.push(null)
        }
    },

    removePhoto({state}, index){

        var image = state.eventPhotos[index];

        if (image != null && "id" in image) {
            state.deletedPhotos.push(image.id)
        }

        state.eventPhotos.splice(index,1)
    },

    setEventPhotoFile({state}, {value, index}){
        console.log(` Value : ${value} --- INDEX: ${index}`)
        state.eventPhotos[index] = value
    },
}