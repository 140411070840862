import User from '../../services/UserService.js'
import Auth from '../../services/AuthService.js'
import Cashier from '../../services/Cashier.js'
import router from '../../router'

export const namespaced = true

export const state = {
    user: null,
    $vald: {},
    $vald2: {},
    $vald3: {},

    reminders: [],

    token: localStorage.getItem('api_token'),
    verified: localStorage.getItem('verified'),

    errors: {},

    dataPassword: {
        current_password: '',
        password: '',
        password_confirmation :''
    },
    coupon: {
        code: ''
    }
};

export const getters = {
    user: state => state.user,
    authenticated: state => state.token !== null,
    verified: state => {
        if(state.verified !== null){
            return state.verified == "true"
        }
        return true
    },
    higherRole: state => {
        if(state.user !== null){
            let gravities = state.user.roles.map(e => e.gravity)
            let maxGravity = Math.max(...gravities)
            return state.user.roles.find(r => r.gravity === maxGravity)
        }
        return null
    },

    isPremium: (state) => {
        if(state.user && state.user.roles !== null){
            return state.user.roles.find(r => r.name === 'premium')
        }
        return null
    },
    isAdmin: (state, getters)  => {
        if(getters.higherRole !== null){
            return getters.higherRole.name === 'admin'
        }
        return null
    },

    activePlan: (state) => {
        if(state.user !== null){
            if(state.user.plans !== undefined){
                if(state.user.plans.length){
                    return state.user.plans.find(plan => plan.pivot.active)
                }
            }
        }
        return null
    }}

export const mutations = {
    SET_USER(state, user){
        state.user = user
        localStorage.setItem('verified', user.email_verified_at != null)
        state.verified = localStorage.getItem('verified')
    },

    SET_TOKEN(state, token){
        localStorage.setItem('api_token', token)
        state.token = token
    },

    CLEAR_TOKEN(){
        localStorage.clear()
        location.reload()
    },

    CLEAR_USER({state}){
        state.user = null
        state.reminders = []
        console.log('clear user...')
    },

    SET_VALIDATOR(state, value){
        state.$vald = value
    },

    SET_VALIDATOR2(state, value){
        state.$vald2 = value
    },

    SET_VALIDATOR3(state, value){
        state.$vald3 = value
    },

    CLEAR_ERRORS(state){
        state.errors = {}
    },

    SET_ERRORS(state, value) {
        state.errors = value
    },

    INIT_PASSWORDS(state){
        state.dataPassword.current_password = ''
        state.dataPassword.password = ''
        state.dataPassword.password_confirmation = ''
    }
};

export const actions = {
    setToken({commit}, token){
        commit('SET_TOKEN', token)
    },

    setVerified({state}, value) {
        state.verified = value
        localStorage.setItem('verified', value);
        return;
    },

    async forgotUserPassword({commit}, email){
        commit('CLEAR_ERRORS')

        await User.forgotPassword(email)
    },

    async updateUserPassword({commit}, data){
        commit('CLEAR_ERRORS')

        return await User.resetPassword(data)
    },

    async loginUser({commit}, user){
        const {data} = await User.login(user);

        commit('SET_USER', data)
    },

    async registerUser({commit}, user){
        const {data} = await Auth.register(user);

        commit('SET_USER', data)
    },

    setUser({commit}, user){
        commit('SET_USER', user)
    },

    async updateUser({commit}){
        let result = await User.getUser()

        commit('SET_USER', result.data)
    },

    async getUser({dispatch}){
        // alert('get User')
        
        let {data} = await User.getUser()

        dispatch('setUser', data)
    },

    setUserMobile({commit}, user){
        commit('SET_USER', user)
    },

    sendCoupon({state, commit}) {
        return Cashier.claimCoupon(state.coupon)
            .then(() => router.push({name: 'Profile'}))
            .catch((e) => commit('SET_ERRORS', e.response.data.errors));
    },

    async changeProfile({commit},user){
        const {data} = await User.changeProfile(user)

        commit('SET_USER', data)

        commit('CLEAR_ERRORS')
        //UPDATE user in vuex

        location.reload()
    },

    async changePassword({commit},dataPassword){
        await User.changePassword(dataPassword)

        commit('CLEAR_ERRORS')
        commit('INIT_PASSWORDS')

        location.reload()
    },

    async logout({commit}){
        await User.logout()
        commit('CLEAR_TOKEN')
    },

    setValidator({commit}, value){
        commit('CLEAR_ERRORS')
        commit('SET_VALIDATOR', value)
    },

    setValidator2({commit}, value){
        commit('CLEAR_ERRORS')
        commit('SET_VALIDATOR2', value)
    },

    setValidator3({commit}, value){
        commit('CLEAR_ERRORS')
        commit('SET_VALIDATOR3', value)
    },

    getRecentReminders({state}) {
        if (state.token) {
            return User.getReminders().then(r => {
                state.reminders = r.data
            });
        }
    }
}