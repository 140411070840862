<template>
  <b-container class="section">
    <ToS />
  </b-container>
</template>

<script>
import ToS from '../components/ToS.vue'

export default {
  name: "TermsAndConditions",
  components: {
    ToS
  }
}

</script>
