import PetService from "../../services/PetService"

export const namespaced = true

export const state = {
    countries : null,
    cities : null,
    areas : null
}

export const mutations = {

}

export const getters = {
    
}

export const actions = {

    getAreas({state}){
        return PetService.getAreas()
            .then(r => {
                state.countries = r.data
                // state.countries = r.data['countries']
                // state.cities = r.data['cities']
                // state.areas = r.data['areas']
            })
            .catch(e => console.log(e.message))
    },

    getCities({state}){
        return PetService.getAllCities()
            .then(r => {
                state.cities = r.data
            })
            .catch(e => console.log(e.message))
    }
}