import apiClient from './Api.js'

export default { 

    postForCharge(data){
        return apiClient.post('cashier/charge', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    getUserIntent(){
        return apiClient.get('cashier/getUserIntent', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    getPlans(){
        return apiClient.get('cashier/plans', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    claimCoupon(data) {
        return apiClient.post('cashier/claimCoupon', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    }
}