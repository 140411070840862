import PetRegistration from '../views/Pets/Create.vue'
import EditPetRegistration from '../views/Pets/Edit.vue'
import PetFamily from '../views/Pets/Registration/PetFamily.vue'
import Pet from '../views/Pets/Registration/Pet.vue'
import PetPhoto from '../views/Pets/Registration/Photo.vue'
import PetIdentity from '../views/Pets/Registration/Identity.vue'
import PetChip from '../views/Pets/Registration/Chip.vue'
import PetLocation from '../views/Pets/Registration/PetLocation.vue'
import PetCharacteristics from '../views/Pets/Registration/Characteristics.vue'
import PetDiseases from '../views/Pets/Registration/Diseases.vue'
import PetPreview from '../views/Pets/Registration/Preview.vue'
import PetShow from '../views/Pets/Show.vue'
import LostPetShow from '../views/LostAndFound/ShowLost.vue'
import FoundPetShow from '../views/LostAndFound/ShowFound.vue'
import MyPets from '../views/Pets/MyPets.vue'
import Adoptions from '../views/Adoption/Adoptions.vue'
import Home from "../views/Home.vue";
import Login from "../views/Auth/Login.vue";
import ForgotPassword from "../views/Auth/ForgotPassword.vue"
import ResetPassword from "../views/Auth/ResetPassword.vue"
import Profile from "../views/User/Profile.vue"
import RegisterUser from "../views/Auth/Register.vue";
import Search from "../views/LostAndFound/Search.vue"
import ShowPetForAdoption from "../views/Adoption/Show.vue"
import PetInfo from "../views/Pets/PetInfo.vue"
import PageNotFound from "../views/PageNotFound.vue"
import Subscribe from "../views/User/Subscribe.vue"
import ChangeOwner from "../views/Pets/ChangeOwner.vue"
import QrPetInfo from "../views/Pets/QrPetInfo.vue"
import Advertisements from "../views/Advertisement/Advertisements.vue"
import ShowAdvertisement from "../views/Advertisement/Show.vue"
import PetWalkerAds from "../views/PetWalkerAd/PetWalkerAds.vue"
import ShowPetWalkerAd from "../views/PetWalkerAd/Show.vue"
import Vets from "../views/Vet/Vets.vue"
import ShowVet from "../views/Vet/Show.vue"
import ToS from "../views/ToS.vue"
import Posts from "../views/Post/Posts.vue"
import ShowPost from "../views/Post/Show.vue"
import Verify from "../views/Auth/Verify.vue"
import VerifyNotification from "../views/Auth/VerifyNotification.vue"

import middleware from './middleware'

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },

    {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: middleware.guest
    },

    // {
    //     path: "/subscribe",
    //     name: "Subscribe",
    //     component: Subscribe,
    //     beforeEnter: middleware.auth
    // },

    {
        path: "/terms-of-service",
        name: "Terms of Service",
        component: ToS,
    },
    {
        path: "/verify",
        name: "Verify",
        component: Verify,
    },
    {
        path: "/verify-notification",
        name: "VerifyNotification",
        component: VerifyNotification,
        beforeEnter: middleware.auth
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        beforeEnter: middleware.auth
    },

    {
        path: "/register-user",
        name: "Register",
        component: RegisterUser,
        beforeEnter: middleware.guest
    },

    {
        path: "/forgot-password",
        name: "Forgot",
        component: ForgotPassword,
        beforeEnter: middleware.guest
    },

    {
        path: "/posts",
        name: "Posts",
        component: Posts,
    },

    {
        path: "/posts/:slug",
        name: "Show Post",
        component: ShowPost,
    },

    {
        path: "/reset-password/:token",
        name: "ResetPassword",
        props: true,
        component: ResetPassword,
        beforeEnter: middleware.guest
    },

    {
        path: "/changeOwner/:token",
        name: "ChangeOwner",
        props: true,
        component: ChangeOwner,
        beforeEnter: middleware.auth
    },

    {
        path: '/my-pets',
        component: MyPets,
        name: 'My Pets',
        beforeEnter: middleware.auth
    },
    {
        path: '/advertisements',
        component: Advertisements,
        name: 'Advertisements',
    },
    {
        path: '/advertisements/:id',
        component: ShowAdvertisement,
        name: 'Show Advertisement',
    },
    {
        path: '/vets',
        component: Vets,
        name: 'Vets',
    },
    {
        path: '/vets/:id',
        component: ShowVet,
        name: 'Show Vet',
    },
    {
        path: '/pet-walker-ads',
        component: PetWalkerAds,
        name: 'Pet Walker Ads',
    },
    {
        path: '/pet-walker-ads/:id',
        component: ShowPetWalkerAd,
        name: 'Show Pet Walker Ad',
    },
    {
        path: '/for-adoption',
        component: Adoptions,
        name: 'Adoptions',
    },
    {
        path: '/pets-for-adoption/:id',
        component: ShowPetForAdoption,
        name: 'ShowPetForAdoption',
    },
    {
        path: '/lost-found',
        component: Search,
        name: 'All Lost and Found',
    },
    {
        path: '/search',
        component: Search,
        name: 'Search'
    },
    {
        path: '/view/:uuid',
        component: PetInfo,
        name: 'PetInfo',
        beforeEnter: middleware.petInfo
    },
    {
        path: '/qr-code/:uuid',
        component: QrPetInfo,
        name: 'QR Code Pet Info',
        beforeEnter: middleware.qrCodePetInfo
    },
    {
        path: '/pets/:id',
        component: PetShow,
        name: 'Show Pet',
        beforeEnter: middleware.auth
    },
    {
        path: '/lost-pets/:id',
        component: LostPetShow,
        name: 'Show Lost Pet',
    },
    {
        path: '/found-pets/:id',
        component: FoundPetShow,
        name: 'Show Found Pet',
    },
    {
        path: '/register',
        component: PetRegistration,
        children: [
            {
                path: '',
                name: 'Create',
                meta: { 
                    stepName: 'Create'
                },
                component: Pet,
                beforeEnter: middleware.auth
            },
            {
                path: 'family',
                name: 'PetFamily',
                meta: { 
                    stepName: 'PetFamily'
                },
                component: PetFamily,
                beforeEnter: middleware.auth
            },
            {
                path: 'photo',
                name: 'Photo',
                meta: { 
                    stepName: 'Photo'
                },
                component: PetPhoto,
                beforeEnter: middleware.auth
            },
            {
                path: 'identity',
                name: 'Identity',
                meta: { 
                    stepName: 'Identity'
                },
                component: PetIdentity,
                beforeEnter: middleware.auth
            },
            {
                path: 'location',
                name: 'PetLocation',
                meta: { 
                    stepName: 'PetLocation'
                },
                component: PetLocation,
                beforeEnter: middleware.auth
            },            
            {
                path: 'chip',
                name: 'Chip',
                meta: { 
                    stepName: 'Chip'
                },
                component: PetChip,
                beforeEnter: middleware.auth
            },
            {
                path: 'characteristics',
                name: 'Characteristics',
                meta: { 
                    stepName: 'Characteristics'
                },
                component: PetCharacteristics,
                beforeEnter: middleware.auth
            },
            {
                path: 'diseases',
                name: 'Diseases',
                meta: { 
                    stepName: 'Diseases'
                },
                component: PetDiseases,
                beforeEnter: middleware.auth
            },
            {
                path: 'preview',
                name: 'Preview',
                meta: { 
                    stepName: 'Preview'
                },
                component: PetPreview,
                beforeEnter: middleware.auth
            },
        ]
    },
    {
        path: '/edit-pet',
        component: EditPetRegistration,
        children: [
            {
                path: ':id',
                name: 'EditCreate',
                meta: { 
                    stepName: 'Create'
                },
                component: Pet,
                beforeEnter: middleware.auth
            },
            {
                path: 'family/:id',
                name: 'EditPetFamily',
                meta: { 
                    stepName: 'PetFamily'
                },
                component: PetFamily,
                beforeEnter: middleware.auth
            },
            {
                path: 'photo/:id',
                name: 'EditPhoto',
                meta: { 
                    stepName: 'Photo'
                },
                component: PetPhoto,
                beforeEnter: middleware.auth
            },
            {
                path: 'identity/:id',
                name: 'EditIdentity',
                meta: { 
                    stepName: 'Identity'
                },
                component: PetIdentity,
                beforeEnter: middleware.auth
            },
            {
                path: 'characteristics/:id',
                name: 'EditCharacteristics',
                meta: { 
                    stepName: 'Characteristics'
                },
                component: PetCharacteristics,
                beforeEnter: middleware.auth
            },
            {
                path: 'diseases/:id',
                name: 'EditDiseases',
                meta: { 
                    stepName: 'Diseases'
                },
                component: PetDiseases,
                beforeEnter: middleware.auth
            },
            {
                path: 'preview/:id',
                name: 'EditPreview',
                meta: { 
                    stepName: 'Preview'
                },
                component: PetPreview,
                beforeEnter: middleware.auth
            },
            {
                path: 'location/:id',
                name: 'EditPetLocation',
                meta: { 
                    stepName: 'PetLocation'
                },
                component: PetLocation,
                beforeEnter: middleware.auth
            },
            {
                path: 'chip/:id',
                name: 'EditChip',
                meta: { 
                    stepName: 'Chip'
                },
                component: PetChip,
                beforeEnter: middleware.auth
            },
        ]
    },


    {
        path: '/404',
        name: '404',
        component: PageNotFound,
        props: true
    },

    {
        path: '/:pathMatch(.*)*', 
        redirect: {name: '404'}
    },
];

export default routes;