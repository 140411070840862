import store from '../../../store/index.js'
import errorPage from './errorPage.js'

export default (to, from, next) => {
    let role = store.getters['auth/higherRole']

    if(Object.keys(role).length > 0){
        if(role.name === 'admin'){
            next()
        }else{
            errorPage(next, 'You don\'t have access to this page')
        }
    }else{
        errorPage(next, 'You don\'t have access to this page')
    }
}

