<template>
    <div>
        <div class="pet-info m-lg-auto">
            <div class="text-right mb-3 mt-3">
                <b-button 
                    @click="$refs.pdfForm.generateReport()" 
                    variant="primary" 
                    class="button button-orange mt-2" 
                    pill>
                    Export to PDF
                </b-button>
            </div>
            <PdfForm ref="pdfForm" />
        </div>
    </div>
</template>

<script>
import PdfForm from '../../components/Pets/Show/PdfForm.vue'

export default {
    components: {
        PdfForm
    }
}
</script>

<style lang="scss" scoped>

</style>