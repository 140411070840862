import { render, staticRenderFns } from "./Photo.vue?vue&type=template&id=29bcf5e3&scoped=true&"
import script from "./Photo.vue?vue&type=script&lang=js&"
export * from "./Photo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29bcf5e3",
  null
  
)

export default component.exports