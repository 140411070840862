<template>
  <b-jumbotron lead="Pet Walkers" class="mt-5 search jumbotronSearchLead">
        <b-container fluid class="bv-example-row display-desktop">
            <b-row>
                <b-col lg="8" align-self="end">
                    <b-row class="mt-2">
                        <b-col lg="auto">
                            <label class="mb-0 mt-2">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="petWalkerAdFilters.cityId">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-button 
                        @click="getPetWalkerAdResults()" 
                        variant="primary"
                        :disabled="!continueStep"
                        class="mt-3 button button-orange font-weight-bold btn-primary rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
                <b-col lg="4" class="text-right">
                    <b-img :src="require('@/assets/images/pets.png')" fluid class="pb-3"></b-img>
                    <div class="mt-2" v-if="authenticated">
                        <b-button v-b-modal.modal-2 pill variant="primary" class="button button-orange font-weight-bold">Δημιουργία αγγελίας</b-button>
                    </div>
                    <b-modal 
                        v-if="authenticated"
                        id="modal-2" 
                        title="Pet Walker"
                        @ok="createPetWalkerAd()"
                        @show="clearNewPetWalkerAd"
                        @hidden="clearNewPetWalkerAd"
                        :ok-disabled="!enableAddModalButton"
                    >
                        <PetWalkerDetails />
                        <div>
                            <b-button 
                                v-if="petWalkerAdMediaFiles.length < maxMedia" 
                                @click="addMedia({slug: 'petWalkerAdMediaFiles'})" 
                                class="button button-orange font-weight-bold"
                                pill
                                >Προθήκη φωτογραφίας +  
                            </b-button>
                            <PetWalkerMedia :index="index" :media="media" v-for="(media,index) in petWalkerAdMediaFiles" :key="index"/>
                        </div>
                        <p class="mb-0 pt-4">*υποχρεωτικά πεδία</p>
                    </b-modal>
                </b-col>
            </b-row>
        </b-container>

        <!-- mobile search form -->
        <b-container id="lostFoundSearchFormMobile" class="bv-example-row display-mobile text-center p-0">
            <b-row>
                <b-col lg="8">
                    <b-row class="mt-2">
                        <b-col>
                            <label class="mb-0 mobileSearchFormLabel">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="petWalkerAdFilters.cityId" class="form-control d-block">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>

                    <b-button 
                        @click="getPetWalkerAdResults()"
                        :disabled="!continueStep"
                        variant="primary"
                        class="mt-3 w-100 button-orange button rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
            </b-row>
        </b-container>

  </b-jumbotron>


</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import PetWalkerMedia from '../PetWalkerAd/All/PetWalkerMedia.vue'
import PetWalkerDetails from '../PetWalkerAd/PetWalkerDetails.vue'
import {merge} from 'lodash'

    export default {
        data(){
            return {
                clientValidation: {
                },

                serverValidation: {},

                errorMessages: {
                }
            }
        },

        computed: {
            ...mapState('search', ['petWalkerAdFilters', '$vald']),
            ...mapState('areas', ['cities']),
            ...mapState('petWalkerAd', ['petWalkerAdMediaFiles', 'maxMedia', 'newPetWalkerAd']),
            ...mapGetters('auth', ['authenticated']),
            continueStep(){
                return true;
            },
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
            enableAddModalButton() {
                return this.newPetWalkerAd.title && this.newPetWalkerAd.description && this.newPetWalkerAd.email && this.newPetWalkerAd.area
            }
        },

        methods: {
            ...mapActions('search', ['getPetWalkerAdResults', 'setValidator']),
            ...mapActions('areas', ['getCities']),
            ...mapActions('petWalkerAd', ['addMedia', 'createPetWalkerAd', 'clearNewPetWalkerAd']),
        },

        created(){
            this.getCities()
            this.setValidator(this.$v)
            this.getPetWalkerAdResults()
        },

        components: {
            PetWalkerDetails, PetWalkerMedia
        },
        validations() {
            return this.rules
        }
    }
</script>

<style lang="scss" scoped>

</style>