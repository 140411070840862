<template>
  <div id="app">
      <Navbar/>
      <RemindersAlert />
      <div v-if="loading">Loading ...</div>
      <router-view v-else class="body-container"></router-view>
      <Footer/>
  </div>
</template>

<script>
  import './styles/app.css'
  import Navbar from './components/Navbar.vue'
  import Footer from './components/Footer.vue'
  import RemindersAlert from './components/User/Reminders/RemindersAlert.vue'
  import {mapState} from 'vuex'
  import Csrf from './services/Csrf.js'

  export default {
      name: 'app',
      
      async created(){
        await Csrf.getCookie()
      },
      
      computed:{
        ...mapState(['loading']),
      },
      
      components:{
          Navbar, Footer,
          RemindersAlert
      }
  }
</script>