<template>
    <div class="">
        <div class="text-center pb-4 pt-5 pt-lg-0 boxShadowBottom">
            <b-img :src="require('@/assets/images/pet_log.png')" fluid />
        </div>
        <div class="w-75 m-auto text-center">
        
            <h3 class="orangeColor mb-4 plus-btn-container pt-4 pt-lg-0">
                <b-icon-plus-circle @click="$bvModal.show('modal-1'); clearEvent()" class="plus-btn"></b-icon-plus-circle>
            </h3>

            <b-modal 
                id="modal-1" 
                title="Νέο γεγονός" 
                @ok="createPetLifeEvent(pet)"
                @hidden="clearEvent"
                :ok-disabled="!enableAddModalButton"
            >
                <NewLogForm />
            </b-modal>
            <b-modal 
                v-model="editEventModal" 
                title="Επεξεργασία γεγονότος" 
                @ok="updateEvent(pet)"
            >
                <NewLogForm :edit="true" :clear="true"/>
            </b-modal>
            <b-modal 
                v-model="showDeleteModal" 
                title="Διαγραφή γεγονότος" 
                @ok="deleteEvent(event)"
            >
                Σίγουρα θέλετε να διαγράψετε αυτό το γεγονός;
            </b-modal>
            <b-modal 
                v-model="showReminderDeleteModal" 
                title="Διαγραφή υπενθύμισης" 
                @ok="deleteReminder(event)"
            >
                Σίγουρα θέλετε να διαγράψετε αυτή την υπενθύμιση;
            </b-modal>
            <b-modal 
                id="pet-event" 
                v-model="showPetEvent"
                hide-footer
                size="lg"
                scrollable
                centered
                header-class="border-bottom-0 pb-0"
            >
                <PetLifeEvent :event="event" @edit="editEvent(event)" @delete="setDeletedModal" @save-reminder="openSaveReminder()" />
            </b-modal>
            <div class="row petlog">
                <div v-for="(item, index) in eventsAndReminders" 
                    :key="index" 
                    class="col-lg-6 petlog-item"
                    :class="index % 2 == 0 ? 'offset-lg-6 right-box type-' + item.type : 'left-box type-' + item.type"
                    @click="showEvent(item)"
                >
                    <div class="petlog-category">
                        <h5 class="mb-0 petlog-category-bubble" :class="'category-' + item.event_type.event_category.slug">{{ item.event_type.title }}</h5>
                        <p class="pt-2 mb-0 petlog-date position-absolute w-100">{{ item.type == 'event' ? item.date : item.notification_date }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import NewLogForm from './NewLogForm.vue'
import PetLifeEvent from './PetLifeEvent.vue'
import { BIconPlusCircle } from 'bootstrap-vue'

export default {
    data(){
        return {
            baseUrl: process.env.VUE_APP_WEB_URI,
            event: [],
            showPetEvent: false,
            editEventModal: false,
            showDeleteModal: false,
            showReminderDeleteModal: false
        }
    },

    computed: {
        ...mapState('pet', ['pet']),
        ...mapGetters('event', ['enableAddModalButton']),

        eventsAndReminders() {
            var temp = [];

            this.pet.pet_life_events.forEach((item) => {

                item.type = 'event';

                if ( item.reminders.length ) {
                    var reminders = item.reminders.map((x) => {
                        x.type = 'reminder';
                        return x;
                    });

                    temp.push(...reminders);
                }

                temp.push(item);
            })

            return temp;
        }
        /*
            TODO δε φορτώνει το σωστό pet αν δεν πάει πρώτα στην show για να αλλάξει το pet στο vuex 
                 -> χρειάζεται να φορτώνει το pet με τα relations κάθε φορά που επισκεπτεται κάποιος τη σελίδα
        */
    },

    methods:{
        ...mapActions('event', ['clearEvent', 'createPetLifeEvent', 'new', 'setNewEvent', 'updateEvent', 'deleteEvent', 'deleteReminder']),
        showEvent(item) {
            if (item.type != 'event') {
                item.images = []
                item.date = item.notification_date
                item.reminder_id = item.id
                item.reminders = []
            }
            this.event = item;
            this.showPetEvent = true;
        },
        editEvent(event) {
            this.setNewEvent(event)
            this.showPetEvent = false
            this.editEventModal = true;
        },
        openSaveReminder() {
            this.setNewEvent(this.event)
            //this.createPetLifeEvent(this.pet)
            this.$bvModal.show('modal-1')
            this.showPetEvent = false
        },
        setDeletedModal() {
            if (this.event.type == 'event') {
                this.showDeleteModal = true
            } else {
                this.showReminderDeleteModal = true
            }
        }
    },
    
    components: {
        NewLogForm, PetLifeEvent, BIconPlusCircle
    },
}
</script>

<style lang="scss" scoped>

</style>