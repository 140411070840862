<template>
    <div>

        <Errors :errors="errors" />

        <div class="formBackgroundColor">
            <div class="maxWidthSign m-auto pt-5">
                <div class="form-group">
                    <KInput 
                        :validation="$vald2"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="current_password"
                        parent="dataPassword"
                        placeholder="type the current password"
                        title="Current Password"
                        type="password"
                        autocomplete="new-password"
                        class="label_sign_in requiredLabel"
                    />
                </div>
            </div>
        </div>

        <div class="formBackgroundColor">
            <div class="maxWidthSign m-auto pt-1">
                <div class="form-group">
                    <KInput 
                        :validation="$vald2"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="password"
                        parent="dataPassword"
                        placeholder="Password"
                        title="New Password"
                        type="password"
                        autocomplete="new-password"
                        class="label_sign_in requiredLabel"
                    />
                </div>
            </div>
        </div>

        <div class="formBackgroundColor">
            <div class="maxWidthSign m-auto pt-1">
                <div class="form-group">
                    <KInput 
                        :validation="$vald2"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="password_confirmation"
                        parent="dataPassword"
                        placeholder="Confirm Password"
                        title="Confirm Password"
                        type="password"
                        autocomplete="new-password"
                        class="label_sign_in requiredLabel"
                    />        
                </div>
            </div>
        </div>
        <p>*υποχρεωτικά πεδία</p>

        <div class="form-group row mb-0 mt-5">
            <div class="col-md-12 text-center">
                <button @click.prevent="updatePassword" type="submit" class="btn btn-primary registreBtn">
                    ΕΝΗΜΕΡΩΣΗ ΚΩΔΙΚΟΥ
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import {mapActions, mapState, mapMutations} from 'vuex'
import KInput from '../../Form/KInput.vue'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import Errors from '../../Errors/ServerErrorMessages.vue'

    export default {
        data(){
            return {
                clientValidation: {
                    dataPassword: {
                        current_password: {
                            required,
                            minLength: minLength(8)
                        },
                        password: {
                            required,
                            minLength: minLength(8)
                        },
                        password_confirmation: {
                            required,
                            sameAsPassword: sameAs('password')
                        }
                    }
                },

                serverValidation: {

                },

                errorMessages: {
                    dataPassword: {
                        current_password: {
                            required: 'The Current Password is required!',
                            minLength: 'Minimum length of Current Password is eight (8) characters' 
                        },
                        password: {
                            required: 'The New Password is required!',
                            minLength: 'Minimum length of New Password is eight (8) characters'
                        },
                        password_confirmation: {
                            required: 'The Password Confirmation is required!',
                            sameAsPassword: 'The Password Confirmation does not match the Password'
                        }
                    }
                },
                loading: false,
                error: null
            }
        },

        components:{
            KInput, Errors
        },

        computed:{
            ...mapState('auth', ['$vald2',  'errors', 'dataPassword']),

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
        },

       methods: {
            ...mapActions('auth', ['changePassword', 'setValidator2']),
            ...mapMutations('auth', ['CLEAR_ERRORS', 'SET_ERRORS']),

            async updatePassword(){
                this.error = null
                this.CLEAR_ERRORS();

                this.$v.$touch();
                if(this.$v.$anyError){
                    return
                }

                try{
                    await this.changePassword(this.dataPassword)
                    this.$nextTick(() => {
                        this.$vald2.$reset();
                    });
                } catch(e){
                    this.error = e
                    this.SET_ERRORS(e.response.data.errors);
                } finally{
                    this.loading = false
                }
            }
        },
        
        validations() {
            return this.rules
        },
        created(){
            this.setValidator2(this.$v)
        },          
    }
</script>

<style lang="scss" scoped>

</style>