<template>
    <div>
        <KInput
            :validation="$v"
            :continue-step="true"
            :errorMessages="errorMessages"
            objectName="email"
            parent="user"
            placeholder="E-mail owner must belong to our database"
            title="Email"
            type="email"
            autofocus
            class="label_sign_in requiredLabel"
        />
        <p>*υποχρεωτικό πεδίο</p>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import KInput from '../../../components/Form/KInput.vue'
import {merge} from 'lodash'
import { required, email } from 'vuelidate/lib/validators'

    export default {

        computed: {
            ...mapState('pet', ['pet', 'enableChangeOwnerButton', 'user', 'errors']),
            
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            }
        },

        components:{
            KInput
        },

        methods: {
            ...mapMutations('pet', ['DISABLE_CHANGE_OWNER_BUTTON', 'ENABLE_CHANGE_OWNER_BUTTON', 'INIT_USER_EMAIL'])
        },

        validations() {
            return this.rules
        },

        mounted(){
            this.INIT_USER_EMAIL()
            this.DISABLE_CHANGE_OWNER_BUTTON()
        },

        watch: {
            'user.email': function(){                
                if(this.$v.$anyError && this.$v.$dirty){                    
                    this.DISABLE_CHANGE_OWNER_BUTTON()
                }else{
                    this.ENABLE_CHANGE_OWNER_BUTTON()
                }

            }
        },

        data() {
            return {
                clientValidation: {
                    user: {
                        email:{
                            required,
                            email,
                        }
                    }
                },

                errorMessages: {
                    user: {
                        email: {
                            required: 'The Email is required!',
                            email: 'Insert a valid email address',
                        }
                    }
                }
            }
        }
    }
    
</script>

<style lang="scss" scoped>

</style>