<template>
    <b-container>
        <b-row class="section">
            <b-col lg="7">
                <h1>{{ post.title }}</h1>
                <h6>{{ post.created_at }}</h6>
                <router-link  v-if="post.post_category" :to="{'path': '/posts?category=' + post.post_category.slug}" class="grayLink">
                    <h6 class="font-weight-bold">{{ post.post_category.name }}</h6>
                </router-link>
            </b-col>
            <b-col lg="5">
                <b-img :src="getImage()" fluid />
            </b-col>
        </b-row>
        <b-row class="section">
            <b-col lg="9">
                <div v-html="post.body" />
            </b-col>
            <b-col lg="3">
                <Categories />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import Categories from '../../components/Posts/Categories.vue'

export default {
    computed: {
        ...mapState('post', ['post']),
    },

    methods:{
        ...mapActions('post', ['showPost']),
        getImage() {
            if (this.post.image.length) {
                return process.env.VUE_APP_WEB_URI + '/storage/' + this.post.image
            }

            return '';
        }
    },

    created(){
        let slug = this.$route.params.slug
        this.showPost(slug)
    },

    components: {
        Categories
    }
}
</script>

<style lang="scss" scoped>

</style>