<template>
    <div class="pt-5 maxWidthProfile m-auto pb-5">
        
        <!-- <div class="row mb-3" v-if="higherRole !== null">
            <div class="col-md-9 text-lg-left d-flex">
                <h3 class="loginTitle mb-0 align-self-center">Πακέτο συνδρομής: {{ activePlan ? activePlan.label : 'Δωρεάν' }}</h3>
            </div>
            <div class="col-md-3 text-lg-right">
                <router-link v-if="higherRole.gravity <= 10" class="button border-button profile-btn" :to="{name: 'Subscribe'}">
                    Αναβάθμιση
                </router-link>
            </div>
        </div> -->

        <UpdateProfile />
        <ChangePassword />
    </div>
</template>

<script>

import UpdateProfile from '../../components/User/Profile/UpdateProfile.vue'
import ChangePassword from '../../components/User/Profile/ChangePassword.vue'
import {mapGetters} from 'vuex'

    export default {
        components:{
            UpdateProfile, 
            ChangePassword
        },     

        computed:{
            ...mapGetters('auth', ['higherRole', 'activePlan'])
        }
    }
</script>

<style lang="scss" scoped>

</style>