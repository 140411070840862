<template>
    <div class="maxWidth75 m-lg-auto">
        <b-alert variant="success" :show="reminders.length > 0">
            <h5 class="alert-heading mb-0">
                <div v-for="item in reminders" :key="'reminder_' + item.id">
                    <b-link :to="{ path: '/pets/' + item.pet_life_event.pet_id}" class="alert-link font-weight-normal d-inline-block pb-1">
                        {{getArticle(item.pet_life_event.pet.sex)}} {{item.pet_life_event.pet.name}} έχει {{item.name}} στις {{ formatDate(item.notification_date) }}
                    </b-link>
                </div>
            </h5>
        </b-alert>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from 'moment'

    export default {
        computed: {
            ...mapState('auth', ['reminders'])
        },

        methods:{
            ...mapActions('auth', ['getRecentReminders']),
            getArticle(sex) {
                return sex ? 'Η' : 'Ο'
            },
            formatDate(date) {
                return moment(date).format('DD/MM/YYYY')
            }
        },

        created() {
           
            this.getRecentReminders();
        }
    }
</script>

<style lang="scss" scoped>

</style>