import Csrf from './Csrf.js'
import webClient from './Web.js'
import Api from './Api.js'

export default{
    getProfile(){
        return Api.get('get-user-profile', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    async login(user){
        await Csrf.getCookie()

        await webClient.post('login', user)

        return Api.get('user', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    async register(user){
        await Csrf.getCookie()

        await webClient.post('mobile-register', user)

        return Api.get('user', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    async forgotPassword(email){
        let r = await Api.post('forgot-password', {email : email}, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })

        console.log(r)
    },

    async resetPassword(data){
        return await Api.post('reset-password', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    async changeProfile(user){
        await Api.put('user/profile-information', user, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })

        return Api.get('user', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    async getUser(){
        return Api.get('user', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    async changePassword(dataPassword){
        return Api.put('user/password', dataPassword, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    async logout(){
        await Csrf.getCookie()

        return webClient.post('logout')
    },

    getReminders(){
        return Api.get('reminders', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },


    loggedPets(){
        return Api.get('get-logged-pets', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    verify(url){
        return Api.get(url, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    }
}