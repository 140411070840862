<template>
    <div>
        <b-alert variant="success" :show="showAlert">
            <h5 class="alert-heading mb-0">
                <b-link :to="{ path: '/lost-pets/' + petLosts.pet_id }" class="alert-link font-weight-normal" v-if="petLosts !==null ">
                    {{ petLosts.sex ? 'Η' : 'Ο' }} {{ petLosts.name }} χάθηκε στις {{ petLosts.date_lost }} στην περιοχή {{ petLosts.area.name }}.
                </b-link>
            </h5>
            <h5 class="alert-heading mb-0">
                <b-link :to="{ path: '/pets-for-adoption/' + pet.for_adoption.id }" class="alert-link font-weight-normal" v-if="pet.status == 'adoption'">
                    {{ pet.sex ? 'Η' : 'Ο' }} {{ pet.name }} δηλώθηκε για υιοθεσία.
                </b-link>
            </h5>
        </b-alert>
        <!--h2>Pet is Lost</h2>
        <p>
            <ul>
                <li>Ημερομηνία που χάθηκε: {{ petLosts.date_lost }}</li>
                <li>Γειτονιά: {{ petLosts.neighborhood }}</li>
                <li>Περιγραφή: {{ petLosts.description }}</li>
            </ul>
        </p-->
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

    export default {
        computed: {
            ...mapState('pet', ['newPetLost', 'pet']),
            ...mapGetters('pet', ['petLosts']),
            showAlert() {
                return this.petLosts !== null || this.pet.status == 'adoption'
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>