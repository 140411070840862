<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
                <RegisterTemplate title="Ανέβασε μια φωτογραφία!">
                    <KFile 
                        :validation="$vald"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="photo"
                        parent="pet"
                    />
                </RegisterTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterTemplate from '@/components/Pets/Registration/RegisterTemplate.vue';
import KFile from '@/components/Form/KFile.vue';
import {merge} from 'lodash'

    export default {
        computed: {
            ...mapState('pet_registration', ['pet', '$vald']),
            continueStep(){
                let flag = false

                if(this.$vald.pet.$dirty){
                    if(this.$vald.pet.$anyError){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.pet.$invalid){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
        },
        components:{
            RegisterTemplate, KFile
        },
        data() {
            return {
                clientValidation: {
                    pet: {
                        photo: {
                            isValidFileSize: (value) => {
                                if (!value || !value.length || typeof value === 'string') {
                                    return true
                                }
                                const fileSizeinKb = value.size / 1024
                                const size = Math.round(fileSizeinKb * 100) / 100 // convert up to 2 decimal place
                                return size <= 15360
                            }
                        }
                    }
                },
                errorMessages: {
                    pet: {
                        photo: {
                            isValidFileSize: 'The file is too big.'
                        }
                    }
                }
            }
        },
        validations() {
            return this.rules
        },
        created(){
            this.setValidator(this.$v)
        },
        methods: {
            ...mapActions('pet_registration', ['disableButtonsAction', 'enableButtonsAction', 'setValidator']),
        }
    }
</script>

<style lang="scss" scoped>

</style>