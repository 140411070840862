<template>
    <div class="pt-5 maxWidthProfile m-auto pb-5">
      <b-row align-h="center">
        <b-col lg="9">
          <b-modal 
            id="cardErrorModal" 
            :ok-only="true"
            @ok="$bvModal.hide('cardErrorModal');"
          >
            <p>Προέκυψε ένα σφάλμα κατά την πληρωμή. Παρακαλώ δοκιμάστε ξανά.</p>
          </b-modal>
          <h3>Go Premium</h3>

          <p>Αναβάθμισε τώρα το πακέτο σου για να μπορείς να χρησιμοποιήσεις τις επιπλέον παροχές και να έχεις όλο το αρχείο του κατοικιδίου σου συγκεντρωμένο παντού μαζί σου:</p>
          <ul class="pl-2">
            <li><strong>PET LOG</strong> – Το απόλυτο ιστορικό για το κατοικίδιό σου με δυνατότητα καταγραφής σημαντικών γεγονότων όπως γενέθλια, προγραμματισμένα ραντεβού σε κτηνιάτρους, εμβόλια και πολλά άλλα!</li>
            <li><strong>Υπενθυμίσεις</strong> για τα επερχόμενα σημαντικά γεγονότα, τα εμβόλια, τα ραντεβού και για οτιδήποτε άλλο αφορά στο κατοικίδιό σου</li>
            <li>Εκτύπωσε και μοιράσου το πλήρες ιστορικό του κατοικιδίου σου, του πιστοποιητικού εμβολιασμού του καθώς και όλες τις πληροφορίες του εύκολα και γρήγορα</li>
          </ul>
          <h5 class="pt-3"><strong>Πακέτα Premium</strong></h5>
          <p>Η χρέωση είναι εφάπαξ για όλη τη διάρκεια χρήσης της υπηρεσίας.</p>
        </b-col>
        <b-col lg="7" class="pt-2">
          <div v-show="!showCoupon">
            <p>Επίλεξε πακέτο:</p>

            <div v-if="activePlan === null || activePlan === undefined">
              <div v-for="plan in stripePlans" :key="plan.name">
                <input type="radio" :id="plan.name" :value="plan" v-model="stripePlan">
                <label class="plan-label-default" :for="plan.name">
                  {{ plan.label }} 
                  <span v-show="plan.name !== 'plan_5'">
                    -
                    <strong>{{ plan.price_fix }}</strong> &#8364;
                  </span> 
                </label>
                <br>
              </div>
            </div>

            <div v-else>
              <div v-for="plan in stripePlans" :key="plan.name">
                <input :disabled="plan.price_fix <= activePlan.price_fix" type="radio" :id="plan.name" :value="plan" v-model="stripePlan">
                <label 
                  :class="{
                    'plan-label-disabled' : plan.price_fix <= activePlan.price_fix,
                    'plan-label-default' : true,
                    'orangeColor font-weight-bold'   : plan.price_fix == activePlan.price_fix
                  }" 
                  class="plan-label" 
                  :for="plan.name"
                >
                  {{ plan.label }} 
                  <span v-show="plan.name !== 'plan_5'">
                    -
                    <strong>{{ plan.price_fix }}</strong> &#8364;
                  </span> 
                  <span v-if="plan.price_fix > activePlan.price_fix && plan.name !== 'plan_5'" class="you-pay">
                    Εσύ θα πληρώσεις
                    <strong> {{ parseFloat(plan.price_fix - activePlan.price_fix).toFixed(2) }} &#8364;</strong>
                  </span>
                </label>
                <br>
              </div>
            </div>
            <div v-show="stripePlan !== null && stripePlan.name !== 'plan_5'">
              <form id="payment-form">
                  <label for="cardholder-name">Ονοματεπώνυμο κατόχου</label>
                  <div>
                      <input type="text" class="cardholder" id="cardholder-name" v-model="cardholderName"/>
                  </div>
                  <div id="card-element"></div>

                  <div id="card-errors" role="alert"></div>

                  <b-button 
                      v-show="stripePlan !== null"
                      @click="proccessPayment"
                      :disabled="paymentProccessing"
                      :data-secret="intent.client_secret"
                      class="button button-orange font-weight-bold pt-3 pb-3 mt-1"
                  >
                      <div v-if="paymentProccessing">
                          <b-spinner label="Spinning" class="d-inline-block align-middle"></b-spinner>
                          <span class="d-inline-block align-middle ml-2">Παρακαλώ περιμένετε</span>
                      </div>
                      <div v-else>
                          Πληρωμή
                      </div>
                  </b-button>
              </form>       
            </div>
            <b-link class="orangeColor mt-2 d-inline-block" @click="showCoupon = true;" v-if="activePlan === null || activePlan === undefined || activePlan.name == 'plan_1'">
              Έχετε κουπόνι;
            </b-link>

            <div v-show="stripePlan !== null && stripePlan.name === 'plan_5'">
              <b-link href="mailto:info@sozon.com" class="orangeColor">Επικοινωνήστε μαζί μας</b-link> για να διαμορφώσουμε το πακέτο συνδρομής σας
            </div>
          </div>
          <CouponForm v-show="showCoupon" />
        </b-col>
      </b-row>
    </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import Cashier from '../../services/Cashier'
import {mapGetters, mapActions} from 'vuex'
import CouponForm from '../../components/User/Profile/CouponForm.vue'

    export default {
        async mounted(){

            this.getStripePlans()

            this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);        
            const elements = this.stripe.elements()

            var style = {
                base: {
                    color: "#32325d",
                    fontFamily: 'Arial, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                    color: "#32325d"
                    }
                },
                invalid: {
                    fontFamily: 'Arial, sans-serif',
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            };

            this.cardElement = elements.create('card', {
                classes: {
                    style: style
                }
            })

            this.cardElement.mount('#card-element')

        },

        data(){
            return {
                stripe: {},
                stripePlans: [],
                stripePlan: null,
                cardElement: {},
                paymentProccessing: false,

                customer: {

                },

                intent: {},
                cardholderName: '',
                showCoupon: false
            }
        },

        computed: {
          ...mapGetters('auth', ['authenticated', 'higherRole', 'activePlan']),
        },

        components: {
          CouponForm
        },

        methods: {

            ...mapActions('auth', ['updateUser']),

            async proccessPayment(){

              this.paymentProccessing = true

              const {paymentMethod, error} = await this.stripe.createPaymentMethod(
                'card', this.cardElement, {
                  billing_details: {name: this.cardholderName}
                }
              )

              if(error){
                console.log(error)
                this.$bvModal.show('cardErrorModal');
                this.paymentProccessing = false
              }else{
                let response = await Cashier.postForCharge({
                    paymentMethod: paymentMethod.id,
                    plan: this.stripePlan
                }).catch((e) => console.log(e))
                this.paymentProccessing = false

                if(response.status === 200){
                    this.updateUser()
                    
                    this.$router.push({name: 'Profile'})
                }
              }

            },

            async getStripePlans(){
              
              this.updateUser()

              let r = await Cashier.getPlans()

              this.stripePlans = r.data
              this.stripePlan =  this.activePlan == null ? this.stripePlans[1] : null
            },

            async createSetupIntent(){
              let r = await Cashier.getUserIntent()

              this.intent = r.data
    
            }
        }
    }
</script>

<style scoped>

form {
  margin-top: 20px;
  width: 100%;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.cardholder {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}

.plan-label-default{
  margin-left:5px;
  max-width: calc(100% - 18px);
}

.plan-label-disabled{
  text-decoration: black;
  text-decoration-line: line-through;
}

.plan-label-active{
  color: green;
  font-weight: bold;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px 4px 0 0 ;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

input[type="radio"] {
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
}
</style>