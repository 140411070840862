<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
               <RegisterTemplate title="Που ζει το κατοικίδιο σου;">
                    <KSelect 
                        v-if="countries"
                        title="Χώρα"
                        placeholder="Χώρα"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="country"
                        :options="countries"
                        identifier="id"
                        val="name"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                        @change="initCity"
                    />

                    <KSelect 
                        v-if="cities.length"
                        title="Πόλη"
                        placeholder="Πόλη"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="city"
                        :options="cities"
                        identifier="id"
                        val="name"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                        @change="initArea"
                    />

                    <KSelect 
                        v-if="pet.city !== null"
                        title="Περιοχή"
                        placeholder="Περιοχή"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="area_id"
                        :options="areas"
                        identifier="id"
                        val="name"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                    />
                        <!--b-row class="mb-2">
                            <b-col align-self="center" sm="4">
                                <label class="mb-0">
                                    Χώρα
                                </label>
                            </b-col>
                            <b-col>
                                <b-form-select @change="initCity" v-model="pet.country">
                                    <b-form-select-option v-for="c in countries" :key="c.id" :value="c">
                                        {{ c.name }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2" v-if="pet.country.cities.length">
                            <b-col align-self="center" sm="4">
                                <label class="mb-0">
                                    Πόλη
                                </label>
                            </b-col>
                            <b-col>
                                <b-form-select @change="initArea" v-model="pet.city">
                                    <b-form-select-option v-for="ct in pet.country.cities" :key="ct.id" :value="ct">
                                        {{ ct.name }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2" v-if="pet.city !== null">
                            <b-col align-self="center" sm="4">
                                <label class="mb-0">
                                    Περιοχή
                                </label>
                            </b-col>
                            <b-col>
                                <b-form-select v-model="pet.area_id">
                                    <b-form-select-option v-for="a in pet.city.areas" :key="a.id" :value="a.id">
                                        {{ a.name }}
                                    </b-form-select-option>
                                </b-form-select >
                            </b-col>
                        </b-row-->
                </RegisterTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import { mapState, mapActions } from 'vuex'
import RegisterTemplate from '@/components/Pets/Registration/RegisterTemplate.vue'
import KSelect from '@/components/Form/KSelect.vue'
import { required } from 'vuelidate/lib/validators'

    export default {
        computed: {
            ...mapState('pet_registration', ['pet', '$vald']),
            ...mapState('areas', ['countries']),

            continueStep(){
                let flag = false

                if(this.$vald.pet.$dirty){
                    if(this.$vald.pet.$anyError){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.pet.$invalid){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },

            cities() {
                if ( this.pet.country !== '' && this.countries) {
                    return this.countries.find((item) => item.id == this.pet.country).cities
                }
                return []
            },

            areas() {
                if ( this.pet.city !== '' && this.countries) {
                    return this.cities.find((item) => item.id == this.pet.city).areas
                }
                return []
            }
        },
        components: {
            RegisterTemplate, 
            KSelect
        },
        data(){
            return {
                clientValidation: {
                    pet: {
                        country: {
                            required
                        },
                        city: {
                            required
                        },
                        area_id: {
                            required
                        }
                    }
                },

                serverValidation: {},

                errorMessages: {
                    pet: {
                        country: {
                            required: 'Η επιλογή της χώρας είναι υποχρεωτική!'
                        },
                        city: {
                            required: 'Η επιλογή της πόλης είναι υποχρεωτική!'
                        },
                        area_id: {
                            required: 'Η επιλογή της περιοχής είναι υποχρεωτική!'
                        }
                    }
                }
            }
        },
        
        validations() {
            return this.rules
        },
        
        methods:{
            ...mapActions('pet_registration', ['disableButtonsAction', 'enableButtonsAction', 'setValidator', 'initCity', 'initArea']),
            ...mapActions('areas', ['getAreas'])
        },

        created(){
            this.getAreas()
            this.setValidator(this.$v)
        }
    }
</script>

<style lang="scss" scoped>

</style>