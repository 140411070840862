import PetService from "../../services/PetService"
import UserService from "../../services/UserService"

export const namespaced = true

export const state = {    
    pet: {
        pet_type: '',
        breed: '',
        petigree_id: '',
        mother: '',
        father: '',
        grandmother1: '',
        grandfather1: '',
        grandmother2: '',
        grandfather2: '',
        photo: '',
        name: '',
        dob: '',
        chip_id: '',
        passport_no: '',
        colour: '',
        gender: '',
        weight: '', 
        sterilization: '',
        diseases: [],
        listVaccines: [],
        status: '',
        certificate: ''
    },
    pets: [],
    loggedPets: 0,
    showEnableButton: true,

    error: '',

    lostPets: [],
    foundPets: [],

    maxMedia: 3,
    foundMediaFiles: [],
    adoptionMediaFiles: [],

    newPetFound: {
        foundDate : null,
        country : {cities : []},
        city: null,
        area: null,
        description: '',
        neighborhood: '',
        type: null,
        breed_list: []
    },

    newPetForAdoption: {
        country : {cities : []},
        city: null,
        area: null,
        description: '',
        type: null,
        breed_list: [],
        age: ''
    },

    myPetsNotLosted: [],

    newPetLost: {
        pet: null,
        lostDate : null,
        country : {cities : []},
        city: null,
        area: null,
        description: '',
        neighborhood: ''
    },

    petTypes: [],

    owner: {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        address: ''
    },

    user: {
        email: ''
    },
    errors: {},
    enableChangeOwnerButton: false
}

export const mutations = {
    SET_IS_LOGGED_PET(state, value){
        state.pet.isLogged = value
    },

    ENABLE_SHOW_BUTTON(state){
        state.showEnableButton = true
    },

    DISABLE_SHOW_BUTTON(state){
        state.showEnableButton = false
    },

    INIT_USER_EMAIL(state){
        state.user = {email : ''}
    },

    DISABLE_CHANGE_OWNER_BUTTON(state){
        state.enableChangeOwnerButton = false
    },

    ENABLE_CHANGE_OWNER_BUTTON(state){
        state.enableChangeOwnerButton = true
    }, 

    INIT_NEW_PET_LOST(state) {
        state.newPetLost = {
            pet: null,
            lostDate : null,
            country : {cities : []},
            city: null,
            area: null,
            description: '',
            neighborhood: ''
        }
    },

    INIT_CITY(state){
        state.newPetLost.city = null
    },

    INIT_AREA(state){
        state.newPetLost.area = null
    },

    INIT_NEIGHBORHOOD(state){
        state.newPetLost.neighborhood = ''
    },

    SET_PET_FOR_NEW_PET_LOST(state){
        state.newPetLost.pet = state.pet
    },

    CLEAR_ERRORS(state){
        state.errors = {}
    }
}

export const getters = {
    canSubmitLostPet: state => state.newPetLost.pet !== null && state.newPetLost.lostDate !== null && state.newPetLost.area !== null,
    petLosts: () => {
        let losts = state.pet.losts

        if(losts === undefined) return null

        let isLost = state.pet.losts.find(e => e.status === 1)

        if(isLost === undefined){
            return null
        }

        isLost.name = state.pet.name
        isLost.sex = state.pet.sex

        return isLost
    },

    testing: () => 'hello',

    error: () => {
        return state.error;
    }
}

export const actions = {
    setLogOfPet({commit}, value){
        commit('SET_IS_LOGGED_PET', value)
        if(value ===0){
            //...
        }else if(value === 1){

            // commit('ENABLE_SHOW_BUTTON')
        }else{
            alert('Error 666')
        }
        commit('DISABLE_SHOW_BUTTON')

    },

    hideLogButton({commit}){
        commit('DISABLE_SHOW_BUTTON')
    },

    showLogButton({commit}){
        commit('ENABLE_SHOW_BUTTON')
    },

    clearNewPetLost({commit}){
        commit('INIT_NEW_PET_LOST')
    },

    initCity({commit}){
        commit('INIT_CITY')
        commit('INIT_AREA')
    },

    initArea({commit}){
        commit('INIT_AREA')
    },

    initNeighborhood({commit}){
        commit('INIT_NEIGHBORHOOD')
    },

    showPet({state}, {id}){
        return PetService.getPet(id)
            .then(r => {
                state.pet = r.data['pet']
                state.showEnableButton = !! (state.pet.isLogged !== 1)

                //state.pet.breed = state.pet.breed.name;
                //state.pet.pet_type = state.pet.pet_type.title;
                //state.pet.diseases = state.pet.diseases.map((item)=> {return item.name});
                state.pet.listVaccines = state.pet.vaccines.map((item) => {
                    return {
                        date: item.pivot.date_vaccine,
                        vaccine: {
                            name: item.name,
                            id: item.pivot.vaccine_id
                        }
                    }
                })
                state.owner = state.pet.user
            })
            .catch(e => console.log(e.message))
    },

    async showPetFromUuid({state}, {uuid}){
        state.error = '';
        await PetService.showPetFromUuid(uuid)
            .then(r => {
                state.pet = r.data['pet']
                state.owner = state.pet.user
                state.pet.listVaccines = state.pet.vaccines.map((item) => {
                    return {
                        date: item.pivot.date_vaccine,
                        vaccine: {
                            name: item.name,
                            id: item.pivot.vaccine_id
                        }
                    }
                })
            })
            .catch((e) => {
                state.error = e.response.status;
            })
    },

    async qrCodePetInfo({state}, {uuid}){
        state.error = '';
        await PetService.qrCodePetInfo(uuid)
            .then(r => {
                state.pet = r.data['pet']
                state.owner = state.pet.user
            })
            .catch((e) => {
                state.error = e.response.status;
            })
    },

    showLostPet({state}, {id}){
        return PetService.getLostPet(id)
            .then(r => {
                state.pet = r.data['pet']
                // state.pet.breed = state.pet.breed.name;
            })
            .catch(e => console.log(e.message))
    },

    showFoundPet({state}, {id}){
        return PetService.getFoundPet(id)
            .then(r => {
                state.pet = r.data['found']
            })
            .catch(e => console.log(e.message))
    },

    showPetForAdoption({state}, {id}){
        return PetService.getPetForAdoption(id)
            .then(r => {
                state.pet = r.data['pet']
            })
            .catch(e => console.log(e.message))
    },

    getPets({state}){
        return PetService.getPets()
            .then(r => {
                state.pets = r.data['pets']
            })
            .catch(e => console.log(e.message))
    },

    getloggedPets({state}){
        return UserService.loggedPets()
            .then(r => {
                state.loggedPets = r.data
            })
            .catch(e => console.log(e.message))
    },

    getAreas({state}){
        return PetService.getAreas()
            .then(r => {
                state.countries = r.data['countries']
                state.cities = r.data['cities']
                state.areas = r.data['areas']
            })
            .catch(e => console.log(e.message))
    },

    getLostPets({state}){
        return PetService.getLostPetsList()
            .then(r => {
                state.lostPets = r.data['lost']
                state.foundPets = r.data['found']
            })
            .catch(e => console.log(e.message))
    },

    getMyPetsNotLost({state}){
        return PetService.getMyPetsNotLost()
            .then(r => {
                state.myPetsNotLosted = r.data
            })
            .catch(e => console.log(e.message))
    },

    getMyPets({state}){
        return PetService.getMyPets()
            .then(r => {
                state.pets = r.data['pets']
            })
            .catch(e => console.log(e.message))
    },

    async createLostPet({commit, state}){
        let result = await PetService.createLost(state.newPetLost)

        if(result.data === 'all_ok'){
            commit('INIT_NEW_PET_LOST')
            location.reload()
        }else{
            console.log('error ...')
        }
    },
 
    async petIsFound({getters}){

        if(getters.petLosts !== null){
            await PetService.isFound(getters.petLosts)

            location.reload()
        }
    },

    setNewPetLost({commit}){
        commit('SET_PET_FOR_NEW_PET_LOST')
    },

    removeMedia({state}, {slug, index}){
        state[slug].splice(index,1)
    },

    setMediaFile({state}, {slug, value, index}){
        state[slug][index] = value
    },

    addMedia({state}, {slug}){
        if(state[slug].length < state.maxMedia){
            state[slug].push(null)
        }
    },

    clearNewPetFound({state}){
        state.foundMediaFiles = []
        state.newPetFound = {
            foundDate : null,
            country : {cities : []},
            city: null,
            area: null,
            description: '',
            neighborhood: '',
            type: null,
            breedId: null,
            breed_list: [],
        }
    },

    clearNewPetForAdoption({state}){
        state.adoptionMediaFiles = []
        state.newPetForAdoption = {
            country : {cities : []},
            city: null,
            area: null,
            description: '',
            type: null,
            breed_list: [],
            age: ''
        }
    },

    async createFoundPet({state}){   
        let formData = new FormData()

        formData.append('date_found', state.newPetFound.foundDate)
        formData.append('area_id', state.newPetFound.area.id)
        formData.append('neighborhood', state.newPetFound.neighborhood)
        formData.append('description', state.newPetFound.description)
        formData.append('pet_type_id', state.newPetFound.type.id)
        formData.append('breed_id', state.newPetFound.breedId)
        
        state.newPetFound.breed_list.forEach((value, index) => formData.append(`breed_list[${index}]`, JSON.stringify(value)))
        state.foundMediaFiles.forEach((file, index) => formData.append(`media[${index}]`, file))

        await PetService.createFoundPetSave(formData)

        location.reload()
    },

    async createPetForAdoption({state}){   
        let formData = new FormData()

        formData.append('area_id', state.newPetForAdoption.area.id)
        formData.append('description', state.newPetForAdoption.description)
        formData.append('pet_type_id', state.newPetForAdoption.type.id)
        formData.append('age', state.newPetForAdoption.age)
        
        state.newPetForAdoption.breed_list.forEach((value, index) => formData.append(`breed_list[${index}]`, JSON.stringify(value)))
        state.adoptionMediaFiles.forEach((file, index) => formData.append(`media[${index}]`, file))

        await PetService.createPetForAdoption(formData)

        location.reload()
    },

    async getPetTypes({state}){
        let result = await PetService.getTypes()

        state.petTypes = result.data
    },

    async putPetForAdoption({state}) {
        await PetService.putPetForAdoption(state.pet.id)

        location.reload()
    },

    async removeFromAdoption({state}) {
        await PetService.removeFromAdoption(state.pet.id)

        location.reload()
    },

    async setLinkStatus({state}) {
        let formData = new FormData()
        formData.append('active_private_link', state.pet.active_private_link ? 1 : 0)

        await PetService.setLinkStatus(formData, state.pet.id)
    },

    async changePetOwnership({state}){
        return await PetService.changeOwnership({
            email: state.user.email
        }, state.pet.id)
    },

    async checkLogForEnable({state}){
        return await PetService.checkLog(state.pet.id)
    },


    async updatePetOwner({commit}, data){
        commit('CLEAR_ERRORS')

        return await PetService.updateOwnership(data)
    }

}