<template>
    <div>
        <div :class="'event-' + event.event_type.event_category.slug">
            <div class="text-center pb-4 event-logo">
                <b-img :src="require('@/assets/images/' + event.event_type.event_category.slug + '.png')" fluid />
            </div>
            <div class="pt-5 pl-5 pr-5 pb-3">
                <h5 class="event-text-colored">
                    {{event.event_type.title}}
                    <span v-if="event.event_subtype"> | {{event.event_subtype.title}}</span><br>
                    {{event.date}}
                </h5>
                <p>{{getDescription()}}</p>
                <div v-if="event.reminders.length">
                    <div v-for="(reminder, index) in event.reminders" :key="reminder.id">
                        <b-badge class="text-wrap">
                            {{ index + 1 }}. {{ reminder.name }} - Date: {{ reminder.notification_date}}
                        </b-badge>
                    </div>
                </div>
                <div v-if="event.images.length">
                    <div v-for="(image, index) in event.images" :key="index">
                        <b-avatar size="6rem" :src="baseUrl + image.image"></b-avatar>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <b-button v-if="event.type == 'event' " @click="$emit('edit')" pill class="button button-orange font-weight-bold">Επεξεργασία</b-button>
                <b-button v-else @click="$emit('save-reminder')" pill class="button button-orange font-weight-bold">Επιβεβαίωση γεγονότος</b-button>
                <b-button @click="$emit('delete')" pill class="button button-orange font-weight-bold ml-2">Διαγραφή</b-button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['event'],
        data(){
            return {
                baseUrl: process.env.VUE_APP_WEB_URI,
            }
        },
        methods: {
            getDescription() {
                if (this.event.description == 'description') {
                    return this.event.event_subtype ? this.event.event_subtype.description : this.event.description
                }
                return this.event.description
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>