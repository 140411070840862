<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
                <RegisterTemplate title="Γνωρίζεις την οικογένεια;">
                    <KInput
                        title="Pedigree ID:"
                        :minNumber=0
                        :maxNumber=999
                        :validation="$vald"
                        :continue-step="continueStep"
                        :errorMessages="errorMessages"
                        objectName="petigree_id"
                        parent="pet"
                        class="label_sign_in"
                    />
                    <KInput
                        title="Εκτροφέας:"
                        :validation="$vald"
                        :continue-step="continueStep"
                        :errorMessages="errorMessages"
                        objectName="breeder"
                        parent="pet"
                        class="label_sign_in"
                    />
                    <p class="mb-1 mt-2">Μητέρα</p>
                    <b-row>
                        <b-col align-self="end">
                            <KSelect 
                                :validation="$vald"
                                :continueStep="continueStep"
                                :errorMessages="errorMessages"
                                objectName="mother_type"
                                :options="family_types"
                                parent="pet"
                                class="label_sign_in"
                                placeholder="Τύπος"
                            />
                        </b-col>
                        <b-col>
                            <KInput
                                placeholder="Μητέρα"
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="mother"
                                parent="pet"
                                class="label_sign_in"
                                title=''
                            />
                        </b-col>
                    </b-row>

                    <p class="mb-1 mt-2">Πατέρας</p>
                    <b-row>
                        <b-col align-self="end">
                            <KSelect 
                                :validation="$vald"
                                :continueStep="continueStep"
                                :errorMessages="errorMessages"
                                objectName="father_type"
                                :options="family_types"
                                parent="pet"
                                class="label_sign_in"
                                placeholder="Τύπος"
                            />
                        </b-col>
                        <b-col>
                            <KInput
                                placeholder="Πατέρας"
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="father"
                                parent="pet"
                                class="label_sign_in"
                                title=''
                            />
                        </b-col>
                    </b-row>

                    <p class="mb-1 mt-2">Γιαγιά 1</p>
                    <b-row>
                        <b-col align-self="end">
                            <KSelect 
                                :validation="$vald"
                                :continueStep="continueStep"
                                :errorMessages="errorMessages"
                                objectName="grandmother1_type"
                                :options="family_types"
                                parent="pet"
                                class="label_sign_in"
                                placeholder="Τύπος"
                            />
                        </b-col>
                        <b-col>
                            <KInput
                                placeholder="Γιαγιά 1"
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="grandmother1"
                                parent="pet"
                                class="label_sign_in"
                                title=''
                            />
                        </b-col>
                    </b-row>

                    <p class="mb-1 mt-2">Παππούς 1</p>
                    <b-row>
                        <b-col align-self="end">
                            <KSelect 
                                :validation="$vald"
                                :continueStep="continueStep"
                                :errorMessages="errorMessages"
                                objectName="grandfather1_type"
                                :options="family_types"
                                parent="pet"
                                class="label_sign_in"
                                placeholder="Τύπος"
                            />
                        </b-col>
                        <b-col>
                            <KInput
                                placeholder="Παππούς 1"
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="grandfather1"
                                parent="pet"
                                class="label_sign_in"
                                title=''
                            />
                        </b-col>
                    </b-row>

                    <p class="mb-1 mt-2">Γιαγιά 2</p>
                    <b-row>
                        <b-col align-self="end">
                            <KSelect 
                                :validation="$vald"
                                :continueStep="continueStep"
                                :errorMessages="errorMessages"
                                objectName="grandmother2_type"
                                :options="family_types"
                                parent="pet"
                                class="label_sign_in"
                                placeholder="Τύπος"
                            />
                        </b-col>
                        <b-col>
                            <KInput
                                placeholder="Γιαγιά 2"
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="grandmother2"
                                parent="pet"
                                class="label_sign_in"
                                title=''
                            />
                        </b-col>
                    </b-row>

                    <p class="mb-1 mt-2">Παππούς 2</p>
                    <b-row>
                        <b-col align-self="end">
                            <KSelect 
                                :validation="$vald"
                                :continueStep="continueStep"
                                :errorMessages="errorMessages"
                                objectName="grandfather2_type"
                                :options="family_types"
                                parent="pet"
                                class="label_sign_in"
                                placeholder="Τύπος"
                            />
                        </b-col>
                        <b-col>
                            <KInput
                                placeholder="Παππούς 2"
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="grandfather2"
                                parent="pet"
                                class="label_sign_in"
                                title=''
                            />
                        </b-col>
                    </b-row>

                </RegisterTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import { mapState, mapActions } from 'vuex'
import KInput from '@/components/Form/KInput.vue'
import RegisterTemplate from '@/components/Pets/Registration/RegisterTemplate.vue'
import { integer, minValue } from 'vuelidate/lib/validators'
import KSelect from '../../../components/Form/KSelect.vue'


    export default {
        computed: {
            ...mapState('pet_registration', ['pet', '$vald', 'family_types']),
            continueStep(){
                let flag = false

                if(this.$vald.pet.$dirty){
                    if(this.$vald.pet.$anyError){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.pet.$invalid){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            }
        },
        components: {
            RegisterTemplate, KInput, KSelect
        },
        data(){
            return {
                clientValidation: {
                    pet: {
                        petigree_id: {
                            integer,
                            minValue: minValue(0)
                        },

                        breeder:{},

                        mother:{},
                        mother_type: {},

                        father:{},
                        father_type: {},

                        grandmother1: {},
                        grandmother1_type: {},
                        grandmother2: {},
                        grandmother2_type: {},
                        grandfather1: {},
                        grandfather1_type: {},
                        grandfather2: {},
                        grandfather2_type: {},
                    }
                },

                serverValidation: {},

                errorMessages: {
                    pet: {
                        petigree_id: {
                            integer: 'Must be integer',
                            minValue: 'ID cannot be negative'
                        },
                        breeder:{},

                        mother:{},
                        mother_type: {},

                        father:{},
                        father_type: {},

                        grandmother1: {},
                        grandmother1_type: {},
                        grandmother2: {},
                        grandmother2_type: {},
                        grandfather1: {},
                        grandfather1_type: {},
                        grandfather2: {},
                        grandfather2_type: {},
                    }
                }
            }
        },
        
        validations() {
            return this.rules
        },
        
        methods:{
            ...mapActions('pet_registration', ['disableButtonsAction', 'enableButtonsAction', 'setValidator'])
        },

        created(){
            this.setValidator(this.$v)
        },
    }
</script>

<style lang="scss" scoped>

</style>