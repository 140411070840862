<template>
    <div class="login m-auto">

        <div v-if="emailSent">
            <div class="formBackgroundColor">
                <div class="maxWidthSign m-auto pt-5">
                    <div class="form-group">
                        <b-alert variant="success" show>
                            Για αλλαγή του password έχουν σταλεί οδηγίες στο E-mail: {{ user.email }}
                        </b-alert>
                    </div>
                </div>
            </div>

        </div>

        <div v-else>
            <h4 class="whiteBackgroundColor text-center innerPageHeader">Ξέχασες τον κωδικό σου;</h4>

            <Errors :errors="errors" />

            <div class="formBackgroundColor">
                <div class="maxWidthSign m-auto pt-5">
                    <div class="form-group">
                        <KInput
                            :validation="$vald3"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="email"
                            parent="user"
                            placeholder="Email"
                            title="Email"
                            type="email"
                            autofocus
                            class="label_sign_in"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group row mb-0 mt-5">
                <div class="col-md-12 text-center">
                    <button v-if="!loading" :disabled="$vald3.$anyError || !$vald3.$dirty" @click.prevent="forgotPassword" type="submit" class="btn btn-primary registreBtn">
                        Επαναφορά κωδικού
                    </button>

                    <button v-else class="btn btn-primary registreBtn">
                        <b-spinner label="Spinning"></b-spinner> παρακαλώ περιμένετε...
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import {mapActions, mapState, mapMutations} from 'vuex'
import KInput from '../../components/Form/KInput.vue'

import { required, email } from 'vuelidate/lib/validators'
import Errors from '../../components/Errors/ServerErrorMessages.vue'

    export default {
        data(){
            return {
                clientValidation: {
                    user: {
                        email:{
                            required,
                            email,
                        }
                    }
                },

                serverValidation: {

                },

                errorMessages: {
                    user: {
                        email: {
                            required: 'The Email is required!',
                            email: 'Insert a valid email address',
                        }
                    }
                },

                loading: false,
                error: null,

                user: {
                    email: ''
                },

                emailSent: false
            }
        },

        components:{
            KInput, Errors
        },

        computed:{
            ...mapState('auth', ['$vald3',  'errors', 'dataPassword']),

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
        },

       methods: {
            ...mapActions('auth', ['forgotUserPassword', 'setValidator3']),
            ...mapMutations('auth', ['CLEAR_ERRORS', 'SET_ERRORS']),

            async forgotPassword(){
                this.loading = true
                this.error = null
                this.CLEAR_ERRORS();

                try{
                    let email = this.user.email
                    await this.forgotUserPassword(email)
                } catch(e){
                    this.SET_ERRORS(e.response.data.errors)
                } finally{
                    this.emailSent = true
                }
            }
        },
        
        validations() {
            return this.rules
        },
        created(){
            this.setValidator3(this.$v)
        },          
    }
</script>

<style lang="scss" scoped>

</style>