<template>
  <b-jumbotron lead="Αγγελίες" class="mt-5 search jumbotronSearchLead">
        <b-container fluid class="bv-example-row display-desktop">
            <b-row>
                <b-col lg="8" align-self="end">
                    <b-form-group label="Είδος" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group
                            id="checkbox-group-pets"
                            v-model="advertisementFilters.petsSelected"
                            :options="listOfPets"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    <b-row class="mt-2">
                        <b-col lg="auto">
                            <label class="mb-0 mt-2">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="advertisementFilters.cityId">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-button 
                        @click="getAdvertisementResults()" 
                        variant="primary"
                        :disabled="!continueStep"
                        class="mt-3 button button-orange font-weight-bold btn-primary rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
                <b-col lg="4" class="text-right">
                    <b-img :src="require('@/assets/images/pets.png')" fluid class="pb-3"></b-img>
                    <div class="mt-2" v-if="authenticated">
                        <b-button v-b-modal.modal-2 pill variant="primary" class="button button-orange font-weight-bold">Δημιουργία αγγελίας</b-button>
                    </div>
                    <b-modal 
                        v-if="authenticated"
                        id="modal-2" 
                        title="Αγγελία"
                        @ok="createAdvertisement()"
                        @show="clearNewAdvertisement"
                        @hidden="clearNewAdvertisement"
                        :ok-disabled="!enableAddModalButton"
                    >
                        <AdvertisementDetails />
                        <div>
                            <b-button 
                                v-if="advertisementMediaFiles.length < maxMedia" 
                                @click="addMedia({slug: 'advertisementMediaFiles'})" 
                                class="button button-orange font-weight-bold"
                                pill
                                >Προθήκη φωτογραφίας +  
                            </b-button>
                            <PetMedia :index="index" :media="media" v-for="(media,index) in advertisementMediaFiles" :key="index"/>
                        </div>
                        <p class="mb-0 pt-4">*υποχρεωτικά πεδία</p>
                    </b-modal>
                </b-col>
            </b-row>
        </b-container>

        <!-- mobile search form -->
        <b-container id="lostFoundSearchFormMobile" class="bv-example-row display-mobile text-center p-0">
            <b-row>
                <b-col lg="8">

                    <b-form-group label="" v-slot="{ ariaDescribedby }" v-bind:class="!nextStepLostFoundSearch ? '' : 'displaynone'">
                        <b-form-checkbox-group
                            id="checkbox-group-pets-mbl"
                            v-model="advertisementFilters.petsSelected"
                            :options="listOfPets"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1-mbl"
                            @input="checkAnimalType"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    
                    
                    <b-button class="arrowBackSearch" v-if="nextStepLostFoundSearch" @click="go_back()"><span>←</span> πίσω</b-button>
                    <b-row v-if="nextStepLostFoundSearch" class="mt-2">
                        <b-col>
                            <label class="mb-0 mobileSearchFormLabel">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="advertisementFilters.cityId" class="form-control d-block">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>

                    <b-button 
                        v-if="nextStepLostFoundSearch"
                        @click="getAdvertisementResults()"
                        :disabled="!continueStep"
                        variant="primary"
                        class="mt-3 w-100 button-orange button rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
            </b-row>
        </b-container>

  </b-jumbotron>


</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import PetMedia from '../Advertisement/All/AdMedia.vue'
import AdvertisementDetails from '../../components/Advertisement/AdvertisementDetails.vue'
import {merge} from 'lodash'

    export default {
        data(){
            return {
                nextStepLostFoundSearch: false,
                listOfPets: [
                    { text: 'Σκύλος', value: 1 },
                    { text: 'Γάτα', value: 2 },
                    { text: 'Άλογο', value: 3 },
                    { text: 'Άλλο', value: 0 }
                ],
                clientValidation: {
                },

                serverValidation: {},

                errorMessages: {
                }
            }
        },

        computed: {
            ...mapState('search', ['advertisementFilters', '$vald']),
            ...mapState('areas', ['cities']),
            ...mapState('advertisement', ['advertisementMediaFiles', 'maxMedia', 'newAdvertisement']),
            ...mapGetters('auth', ['authenticated']),
            continueStep(){
                return true;
            },
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
            enableAddModalButton() {
                return this.newAdvertisement.title && this.newAdvertisement.type && this.newAdvertisement.description && this.newAdvertisement.price && this.newAdvertisement.petType && this.newAdvertisement.area
            }
        },

        methods: {
            ...mapActions('search', ['getAdvertisementResults', 'getFilterData', 'setValidator']),
            ...mapActions('areas', ['getCities']),
            ...mapActions('advertisement', ['addMedia', 'createAdvertisement', 'clearNewAdvertisement']),
            checkAnimalType() {
                this.nextStepLostFoundSearch = true;

                var child0 = document.getElementById('checkbox-group-pets-mbl_BV_option_0');
                
                if (child0.checked) {
                    child0.parentElement.className += ' selectedBackground';
                    child0.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child0.parentElement.classList.remove('selectedBackground');
                    child0.nextSibling.classList.remove('selectedWhiteFont');
                }

                var child1 = document.getElementById('checkbox-group-pets-mbl_BV_option_1');
                
                if (child1.checked) {
                    child1.parentElement.className += ' selectedBackground';
                    child1.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child1.parentElement.classList.remove('selectedBackground');
                    child1.nextSibling.classList.remove('selectedWhiteFont');
                }

                var child2 = document.getElementById('checkbox-group-pets-mbl_BV_option_2');
                
                if (child2.checked) {
                    child2.parentElement.className += ' selectedBackground';
                    child2.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child2.parentElement.classList.remove('selectedBackground');
                    child2.nextSibling.classList.remove('selectedWhiteFont');
                }

                var child3 = document.getElementById('checkbox-group-pets-mbl_BV_option_3');
                
                if (child3.checked) {
                    child3.parentElement.className += ' selectedBackground';
                    child3.nextSibling.className += ' selectedWhiteFont';
                } else {
                    child3.parentElement.classList.remove('selectedBackground');
                    child3.nextSibling.classList.remove('selectedWhiteFont');
                }
            },
            go_back() {
                this.nextStepLostFoundSearch = false;
            }
        },

        created(){
            this.getCities()
            this.getFilterData()
            this.setValidator(this.$v)
            this.getAdvertisementResults()
        },

        components: {
            AdvertisementDetails, PetMedia
        },
        validations() {
            return this.rules
        }
    }
</script>

<style lang="scss" scoped>

</style>