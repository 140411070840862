<template>
    <div>
        <div class="row pb-4 pt-4 customNoGutters">

            <div class="col-9 align-self-center pt-2">
                <h6 class="mb-1 font-weight-bold">{{pet.name}}</h6>
                <p class="mb-0">Είδος: <strong>{{pet.pet_type.title}}</strong></p>
                <p class="mb-0">Ράτσα: <strong>{{ breeds }}</strong></p>
                <p class="mb-0">Χρώμα: <strong>{{ colouring }}</strong></p>
            </div>

            <div class="col-3 pl-0">
                <b-avatar size="5rem" :src="baseUrl + pet.photo"></b-avatar>
            </div>

            <div class="col-12">
                <p class="mb-0">Φύλο: <strong>{{pet.sex ? 'Θηλυκό' : 'Αρσενικό'}}</strong></p>
                <p class="mb-0">Ημερομηνία Γέν.: <strong>{{ date }}</strong></p>
                <p class="mb-0">Chip ID: <strong>{{ pet.chip_id }}</strong></p>

                <h6 class="mt-3 mb-1 font-weight-bold">Ιδιοκτήτης</h6>
                <p class="mb-0">Όνομα: <strong>{{owner.name}} {{owner.last_name}}</strong></p>
                <p class="mb-0">Τηλέφωνο: <strong>{{owner.phone}}</strong></p>
                <p class="mb-0">Διεύθυνση: <strong>{{owner.address}}</strong></p>
                <p class="mb-0">Email: <strong>{{owner.email}}</strong></p>
            </div>

            <div class="col-12 pt-3">
                <h6 class="mb-1 font-weight-bold">Γονείς</h6>
                <p class="mb-0">Μητέρα: <strong>{{pet.mother}}</strong></p>
                <p class="mb-0">Πατέρας: <strong>{{pet.father}}</strong></p>
                <p class="mb-0">Γιαγιά 1: <strong>{{pet.grandmother1}}</strong></p>
                <p class="mb-0">Παππούς 1: <strong>{{pet.grandfather1}}</strong></p>
                <p class="mb-0">Γιαγιά 2: <strong>{{pet.grandmother2}}</strong></p>
                <p class="mb-0">Παππούς 2: <strong>{{pet.grandfather2}}</strong></p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'

export default {

    computed: {
        ...mapState('pet', ['pet', 'owner']),
        breeds() {
            if (this.pet.breeds) {
                let temp = this.pet.breeds.map((item) => item.name);
                return temp.join(", ");
            }
            return '';
        },
        colouring(){
            if(Array.isArray(this.pet.colours) && this.pet.colours.length){
                return this.pet.colours.map(colour => colour.name).join(", ")
            }
            return ''
        },
        date() {
            return moment(this.pet.dob).format('YYYY-MM-DD')
        }
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_WEB_URI
        }
    }
}
</script>

<style lang="scss" scoped>

</style>