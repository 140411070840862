<template>
    <div class="row no-gutters">
        <div class="col-md-12" id="listNavigationTabs">
            <b-list-group horizontal>
                <b-list-group-item
                v-for="(step, index) in steps" 
                    :key="index"
                    :class="{
                        'active' : $route.name === step.name
                    }"
                    class="w-100 text-center"
                >
                    <span v-if="index >= currentStepIndex && ! isEdit "> {{ step.label }}</span>
                    <router-link v-else :to="{name: step.name}">
                        {{ step.label }}
                    </router-link>
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

    export default {
        computed: {
            ...mapState('pet_registration', ['steps']),
            currentStepIndex(){
                let currentRoute = element => element.name === this.$route.name

                let current_step = this.steps.findIndex(currentRoute)
            
                return current_step
            },
            isEdit() {
                let id = this.$route.params.id

                return id > 0 && id != null
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>