import PetService from "../../services/PetService"
import router from '../../router/index'
export const namespaced = true

export const state = {
    $vald: {},
    errors: {},
    steps: [
        {name: 'Create', label: 'Κατοικίδιο'},
        {name: 'PetLocation', label: 'Τοποθεσία'},
        {name: 'PetParents', label: 'Γονείς'},
        {name: 'Photo', label: 'Φωτογραφία'},
        {name: 'Identity', label: 'Ταυτότητα'},
        {name: 'Chip', label: 'Chip'},
        {name: 'Characteristics', label: 'Χαρακτηριστικά'},
        {name: 'Diseases', label: 'Ιστορικό υγείας'},
        {name: 'Preview', label: 'Επισκόπηση'},
    ],

    completedSteps: 1,
    totalSteps: 9,

    family_types: [],

    countries: null,

    pet: {
        country : '',
        city: null,
        area_id: null,
        tatoo_no: '',
        tatoo_date: '',
        tatoo_location: '',
        adoption_date: '',
        breeds: [],
        pet_type_id: '',
        petigree_id: '',
        mother: '',
        mother_type: '',
        father: '',
        father_type: '',
        photo: [],
        name: '',
        dob: '',
        chip_id: '',
        chip_date: '',
        chip_location: '',
        passport_no: '',
        colours: [],
        sex: '',
        weight: '',
        weight_mo: '',
        sterilization: '',
        vaccines: [],
        diseases: [],
        weightMeasurement: '',
        breed: '',
        pet_type: '',
        grandmother1: '',
        grandmother1_type: '',
        grandmother2: '',
        grandmother2_type: '',
        grandfather1: '',
        grandfather1_type: '',
        grandfather2: '',
        grandfather2_type: '',
        breeder: '',
        listVaccines: [],
        certificate: []
    },

    pet_types: [],
    vaccines: [
        {
            id: 1,
            name: "vaccine1"
        },                      
        {
            id: 2,
            name: "vaccine2"
        },
        {
            id: 3,
            name: "vaccine3"
        },                    
        {
            id: 4,
            name: "vaccine4"
        }
    ],
    selectedVaccine: null,

    disableButtons: false,
    weight_measurement_units: [],
    colours: [],

    currentDate: null
}

export const mutations = {

    SET_VALIDATOR(state, value){
        state.$vald = value
    },

    INIT_CREATE_STEPS(state){
        state.steps = [
            {name: 'Create', label: 'Κατοικίδιο'},
            {name: 'PetLocation', label: 'Τοποθεσία'},
            {name: 'PetFamily', label: 'Οικογένεια'},
            {name: 'Photo', label: 'Φωτογραφία'},
            {name: 'Identity', label: 'Ταυτότητα'},
            {name: 'Chip', label: 'Chip'},
            {name: 'Characteristics', label: 'Χαρακτηριστικά'},
            {name: 'Diseases', label: 'Ιστορικό υγείας'},
            {name: 'Preview', label: 'Επισκόπηση'},
        ];
    },

    SET_COMPLETED_STEPS(state, newStep) {
        state.completedSteps = newStep
    },

    INIT_EDIT_STEPS(state){
        state.steps = [
            {name: 'EditCreate', label: 'Κατοικίδιο'},
            {name: 'EditPetLocation', label: 'Τοποθεσία'},
            {name: 'EditPetFamily', label: 'Οικογένεια'},
            {name: 'EditPhoto', label: 'Φωτογραφία'},
            {name: 'EditIdentity', label: 'Ταυτότητα'},
            {name: 'EditChip', label: 'Chip'},
            {name: 'EditCharacteristics', label: 'Χαρακτηριστικά'},
            {name: 'EditDiseases', label: 'Ιστορικό υγείας'},
            {name: 'EditPreview', label: 'Επισκόπηση'},
        ];
    },

    SET_ERRORS(state, value) {
        state.errors = value
    },
    
    CLEAR_ERRORS(state){
        state.errors = {}
    },
    
    INIT_PET(state) {
        state.pet = {
            country : '',
            city: null,
            area_id: null,
            tatoo_no: '',
            tatoo_date: '',
            tatoo_location: '',
            adoption_date: '',
            breeds: [],
            pet_type_id: '',
            petigree_id: '',
            mother: '',
            mother_type: '',
            father: '',
            father_type: '',
            photo: [],
            name: '',
            dob: '',
            chip_id: '',
            chip_date: '',
            chip_location: '',
            passport_no: '',
            colours: [],
            sex: '',
            weight: '',
            weight_mo: '',
            sterilization: '',
            diseases: [],
            vaccines: [],
            weightMeasurement: '',
            breed: '',
            pet_type: '',
            grandmother1: '',
            grandmother1_type: '',
            grandmother2: '',
            grandmother2_type: '',
            grandfather1: '',
            grandfather1_type: '',
            grandfather2: '',
            grandfather2_type: '',
            breeder: '',
            listVaccines: [],
            certificate: []
        };

        state.completedSteps = 1
    },

    DISABLE_BUTTONS(state){
        state.disableButtons = true
    },

    ENABLE_BUTTONS(state){
        state.disableButtons = false
    },

    INIT_CITY(state){
        state.pet.city = null
    },

    INIT_AREA(state){
        state.pet.area_id = null
    },

    DELETE_VACCINE(state, index){
        state.vaccines.splice(index, 1)
    },

    DELETE_SELECTED_VACCINE(state, index){
        state.pet.listVaccines.splice(index, 1)
    },

    SET_SELECTED_VACCINE(state,value){
        state.selectedVaccine = value
    },

}

export const getters = {
    
}

export const actions = {
    initCity({commit}){
        commit('INIT_CITY')
        commit('INIT_AREA')
    },

    initArea({commit}){
        commit('INIT_AREA')
    },

    setValidator({commit}, value){
        commit('CLEAR_ERRORS')
        commit('SET_VALIDATOR', value)
    },

    disableButtonsAction({commit}){
        commit('DISABLE_BUTTONS')
    },

    enableButtonsAction({commit}){
        commit('ENABLE_BUTTONS')
    },

    fetchData({state}){
        return PetService.fetchData()
            .then(r => {
                state.pet_types = r.data['pet_types']
                state.weight_measurement_units = r.data['weight_measurement_units']
                state.colours = r.data['colours']
                state.family_types = r.data['family_types']
            })
            .catch(e => console.log(e.message))
    },

    fetchVaccines({state}){
        return PetService.fetchVaccinesList()
            .then(r => {
                state.vaccines = r.data
            })
            .catch(e => console.log(e.message))
    },

    getEditData({state}, {id}){
        return PetService.editPet(id)
            .then(r => {
                state.pet_types = r.data['pet_types']
                state.weight_measurement_units = r.data['weight_measurement_units']
                state.colours = r.data['colours'];
                state.pet = r.data['pet']
                state.family_types = r.data['family_types']
                state.pet.pet_type = ''
                state.pet.listVaccines = state.pet.vaccines.map((item) => {
                    return {
                        date: item.pivot.date_vaccine,
                        vaccine: {
                            name: item.name,
                            id: item.pivot.vaccine_id
                        }
                    }
                })
            })
            .catch(e => console.log(e.message))
    },

    appendToFormData({state}, data){
        Object.keys(state[data.prop]).forEach(key => {
            if( state[data.prop][key] !== null && state[data.prop][key] !== "" ){
                if(key === 'breeds' || key === 'colours'){
                    state[data.prop][key].forEach((value, index) => data.formData.append(`${data.prop}[${key}][${index}]`, JSON.stringify(value)))                        
                }else if(key === 'listVaccines'){
                    state[data.prop][key].forEach((value, index) => {
                        console.log(value)
                        data.formData.append(`${data.prop}[${key}][${index}]`, JSON.stringify(value))
                    })                        
                }
                else{
                    data.formData.append(`${data.prop}[${key}]`, state[data.prop][key])                    
                }
            }
        })
    },

    getAreas({state}){
        return PetService.getAreas()
            .then(r => {
                state.countries = r.data['countries']
            })
            .catch(e => console.log(e.message))
    },

    async save({state, dispatch, commit}) {

        let formData = new FormData()

        dispatch('appendToFormData', {formData, prop: 'pet'})

        if (state.pet.photo == []) {
            formData.delete(`pet[photo]`)
        }

        try{
            var result = await PetService.save(formData)
        } catch(e){
            commit('SET_ERRORS', e.response.data.errors)
            throw e
        }

        commit('INIT_PET')

        router.push({path: '/pets/' + result.data.id})
    },

    addNewVaccine({state, dispatch}){
        dispatch('formatDate')

        state.pet.listVaccines.push({
            vaccine: state.selectedVaccine,
            date: state.currentDate
        })
        dispatch('deleteVaccine', state.selectedVaccine)
        state.selectedVaccine = null
    },

    deleteVaccine({commit, state}, vaccine){
        let index = state.vaccines.indexOf(vaccine)
        commit('DELETE_VACCINE', index)
    },

    deleteSelectedVaccine({commit, state}, vaccine){
        let index = state.pet.listVaccines.findIndex(v => v.vaccine.id === vaccine.id)

        commit('DELETE_SELECTED_VACCINE', index)

        state.vaccines.push(vaccine)
        state.vaccines.sort((a,b) => {
            if(a.id < b.id){
                return -1
            }
            if(a.id > b.id){
                return 1
            }
        })
    },

    setSelectedVaccine({commit}, value){
        commit('SET_SELECTED_VACCINE', value)
    },

    setEventId({commit},id){
        commit('SET_EVENT_ID',id)
    },

    formatDate({state}){
        let d = new Date()
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        let year = d.getFullYear()

        if (month.length < 2) 
            month = '0' + month
        if (day.length < 2) 
            day = '0' + day

        state.currentDate = [year, month, day].join('-')
    }
}