import Vue from "vue";
import VueRouter from "vue-router";
import routes from './routes'
import store from '../store/index.js'

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  } 
});

const DEFAULT_TITLE = 'Sozon';
router.beforeEach((to, from, next) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });

    if (to.name != "VerifyNotification" && to.name != "Verify") {
      let verified = store.getters['auth/verified']
      if (verified) {
        next()
      }else{
        next({ name : 'VerifyNotification'})
      }
    } else {
      next();
    }
});

export default router;
