<template>
    <b-form-group>
        <label>{{ title }}</label>
        <b-img v-if="showPhoto" :src="showPhoto" fluid class="pb-3"></b-img>
        <b-form-file
            v-model="file"
            :state="validateState()"
            placeholder="Πάτησε εδώ για να ανεβάσεις μια φωτογραφία"
            drop-placeholder="ΆΦησε το αρχείο εδώ"
            accept="image/*"
            @change="(e) => onFileChange(e)"
        ></b-form-file>
        <ErrorMessage 
            :id="objectName" 
            v-for="(message, index) in errorMessages[parent][objectName]"
            :key="index"
            :show="!validation[parent][objectName][index]"
            :message="message"
        />
    </b-form-group>
</template>

<script>
import ErrorMessage from '../Errors/FormInvalidFeedback.vue'
import Compressor from 'compressorjs'

    export default {
        props:{
            parent: {
                type: String,
                required: true
            },

            objectName: {
                type: String,
                required: true
            },

            title: {
                type: String,
                required: false
            },

            validation: {
                type: Object,
                required: true
            },

            continueStep: {
                type: Boolean,
                required: true
            },

            errorMessages:{
                type: Object,
                required: true
            }
        },

        components: {
            ErrorMessage
        },

        data() {
            return {
                file: null,
                baseUrl: process.env.VUE_APP_WEB_URI
            }
        },

        computed: {
            showPhoto() {
                let photo = this.validation[this.parent][this.objectName].$model
                if (typeof photo === 'object' && !Array.isArray(photo) && photo !== null) {
                    return URL.createObjectURL(photo);
                } else if (photo !== null && photo.length) {
                    return this.baseUrl + photo
                }

                return null;
            }
        },

        methods:{
            validateState(){
                let { $dirty, $error } = this.validation[this.parent][this.objectName]
                if($dirty){
                    if($error){
                        return false
                    }
                }
                return null
            },
            onFileChange(e) {
                const file = e.target.files[0];
                if (!file) {
                    return;
                }

                let self = this;

                new Compressor(file, {
                    quality: 0.8,
                    maxWidth: 500,
                    maxHeight: 500,
                    strict: true,

                    // The compression process is asynchronous,
                    // which means you have to access the `result` in the `success` hook function.
                    success(result) {
                        //console.log(result);

                        //const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
                        //const i = parseInt(Math.floor(Math.log(result.size) / Math.log(1024)), 10)
                        //if (i === 0) return `${result.size} ${sizes[i]})`
                        //console.log(`${(result.size / (1024 ** i)).toFixed(1)} ${sizes[i]}`)

                        self.validation[self.parent][self.objectName].$model = result
                    },
                    error(err) {
                        console.log(err.message);
                    },
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>