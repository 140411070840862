import store from '../../../store/index.js'

export default (to, from, next) => {
    if(store.getters['auth/authenticated']) {
        next({name: 'Home'})
    }else{
        next()
    }
}

