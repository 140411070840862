import axios from 'axios'

let apiClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URI,
    //baseURL: "http://api.sozon.test/api",
    withCredentials: true,
    headers:{
        'Accept': 'application/json',
        'Content-Type' : 'application/json',
    },
    timeout: 10000
});

apiClient.interceptors.response.use(
    response => {return response},
    error => {
        if(error.response.status === 401){
            localStorage.clear()
            window.location.reload()
        }
        return Promise.reject(error)
    }
)

export default apiClient