<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
                <h3>Επιβεβαίωση στοιχείων</h3>
                <Show :pet="pet" />
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import Show from '../../../components/Pets/Show/Show.vue'

export default {
    computed: {
        ...mapState('pet_registration', ['pet']),
    },
    components: {
        Show
    },
}
</script>

<style lang="scss" scoped>

</style>