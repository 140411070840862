<template>
  <b-jumbotron lead="Κτηνίατροι" class="mt-5 search jumbotronSearchLead">
        <b-container fluid class="bv-example-row display-desktop">
            <b-row>
                <b-col lg="8" align-self="end">
                    <b-row class="mt-2">
                        <b-col lg="auto">
                            <label class="mb-0 mt-2">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="vetFilters.cityId">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-button 
                        @click="getVetResults()" 
                        variant="primary"
                        :disabled="!continueStep"
                        class="mt-3 button button-orange font-weight-bold btn-primary rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
                <b-col lg="4" class="text-right">
                    <b-img :src="require('@/assets/images/pets.png')" fluid class="pb-3"></b-img>
                </b-col>
            </b-row>
        </b-container>

        <!-- mobile search form -->
        <b-container id="lostFoundSearchFormMobile" class="bv-example-row display-mobile text-center p-0">
            <b-row>
                <b-col lg="8">
                    <b-row class="mt-2">
                        <b-col>
                            <label class="mb-0 mobileSearchFormLabel">
                                Πόλη: 
                            </label>
                            <b-form-select v-model="vetFilters.cityId" class="form-control d-block">
                                <template #first>
                                    <b-form-select-option :value="''" disabled>Πόλη</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="city in cities" v-bind:value="city.id" :key="city.id">
                                    {{ city.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>

                    <b-button 
                        @click="getVetResults()"
                        :disabled="!continueStep"
                        variant="primary"
                        class="mt-3 w-100 button-orange button rounded-pill"
                    >
                        Αναζήτηση
                    </b-button>
                </b-col>
            </b-row>
        </b-container>

  </b-jumbotron>


</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import {merge} from 'lodash'

    export default {
        data(){
            return {
                clientValidation: {
                },

                serverValidation: {},

                errorMessages: {
                }
            }
        },

        computed: {
            ...mapState('search', ['vetFilters', '$vald']),
            ...mapState('areas', ['cities']),
            ...mapGetters('auth', ['authenticated']),
            continueStep(){
                return true;
            },
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
        },

        methods: {
            ...mapActions('search', ['getVetResults', 'setValidator']),
            ...mapActions('areas', ['getCities']),
        },

        created(){
            this.getCities()
            this.setValidator(this.$v)
            this.getVetResults()
        },
        validations() {
            return this.rules
        }
    }
</script>

<style lang="scss" scoped>

</style>