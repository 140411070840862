<template>
    <div class="login m-auto">

        <h2 class="whiteBackgroundColor text-center innerPageHeader">Αλλαγή Κωδικού</h2>

        <h5>Email : {{ $route.query.email }}</h5>

        <div v-if="requestSent">
            <div class="formBackgroundColor">
                <div class="maxWidthSign m-auto pt-5">
                    <div class="form-group">
                        <b-alert variant="success" show>
                            Ο κωδικός σας άλλαξε. Πατήστε 
                            <router-link :to="{name: 'Login'}">εδώ</router-link>
                            για να συνδεθείτε
                        </b-alert>
                    </div>
                </div>
            </div>

        </div>

        <div v-else>
            <Errors :errors="errors" />

            <div class="formBackgroundColor">
                <div class="maxWidthSign m-auto pt-5">
                    <div class="form-group">
                        <KInput 
                            :validation="$v"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="password"
                            parent="user"
                            placeholder="Password"
                            title="New Password"
                            type="password"
                            autocomplete="new-password"
                            class="label_sign_in"
                        />
                    </div>
                </div>
            </div>

            <div class="formBackgroundColor">
                <div class="maxWidthSign m-auto pt-5">
                    <div class="form-group">
                        <KInput 
                            :validation="$v"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="password_confirmation"
                            parent="user"
                            placeholder="Confirm Password"
                            title="Confirm Password"
                            type="password"
                            autocomplete="new-password"
                            class="label_sign_in"
                        />        
                    </div>
                </div>
            </div>

            <div class="form-group row mb-0 mt-5">
                <div class="col-md-12 text-center">
                    <button v-if="!loading" :disabled="$v.$anyError || !$v.$dirty" @click.prevent="updatePassword" type="submit" class="btn btn-primary registreBtn">
                       Αλλαγή κωδικού
                    </button>

                    <button v-else class="btn btn-primary registreBtn">
                        <b-spinner label="Spinning"></b-spinner> παρακαλώ περιμένετε...
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import {mapActions, mapMutations} from 'vuex'
import KInput from '../../components/Form/KInput.vue'

import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import Errors from '../../components/Errors/ServerErrorMessages.vue'

    export default {

        props: ['token'],

        data(){
            return {
                clientValidation: {
                    user: {
                        password: {
                            required,
                            minLength: minLength(8)
                        },
                        password_confirmation: {
                            required,
                            sameAsPassword: sameAs('password')
                        }
                    }
                },

                serverValidation: {

                },

                errorMessages: {
                    user: {
                        password: {
                            required: 'The New Password is required!',
                            minLength: 'Minimum length of New Password is eight (8) characters'
                        },
                        password_confirmation: {
                            required: 'The Password Confirmation is required!',
                            sameAsPassword: 'The Password Confirmation does not match the Password'
                        }
                    }
                },

                loading: false,
                error: null,

                user: {
                    password: '',
                    password_confirmation: ''
                },

                errors: {},

                requestSent: false
            }
        },

        components:{
            KInput, Errors
        },

        computed:{
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
        },

       methods: {
            ...mapActions('auth', ['updateUserPassword']),
            ...mapMutations('auth', ['CLEAR_ERRORS', 'SET_ERRORS']),

            async updatePassword(){
                this.loading = true
                this.error = null
                this.errors = {}

                try{
                    let r = await this.updateUserPassword({
                        email: this.$route.query.email,
                        token : this.token,
                        password: this.user.password,
                        password_confirmation: this.user.password_confirmation
                    })

                    if(r.status === 200){
                        this.requestSent = true
                    }
                } catch(e){
                    this.errors = e.response.data.errors
                    this.loading = false
                    this.user.password = ''
                    this.user.password_confirmation = ''
                    this.$v.$reset()
                }
            }
        },
        
        validations() {
            return this.rules
        }      
    }
</script>

<style lang="scss" scoped>

</style>