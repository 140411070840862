<template>
    <div>
        <h2 class="whiteBackgroundColor innerPageHeader petRegistrationTitle">Εγγραφή κατοικιδίου</h2>
        <ProgressSteps />
        <div class="w-75 m-auto">
            <div id="petRegistrationSteps"><Steps :steps="steps" /></div>
            <div class="grayBackground">
                <router-view></router-view>
                <Errors :errors="errors" />
                <ButtonsPetRegistration></ButtonsPetRegistration>
            </div>
        </div>
    </div>
</template>

<script>
import Steps from '../../components/Pets/Steps.vue'
import ButtonsPetRegistration from '../../components/Pets/Registration/ButtonsPetRegistration.vue'
import ProgressSteps from '../../components/Pets/Registration/ProgressSteps.vue'
import {mapMutations, mapState, mapActions} from 'vuex'
import Errors from '../../components/Errors/ServerErrorMessages.vue'

export default {
    components: {
        Steps, ButtonsPetRegistration, Errors, ProgressSteps
    },
    computed: {
        ...mapState('pet_registration', ['steps', 'errors']),
    },
    methods: {
        ...mapMutations('pet_registration', ['INIT_CREATE_STEPS', 'INIT_PET']),
        ...mapActions('pet_registration', ['fetchData', 'fetchVaccines'])
    },
    created() {
        this.INIT_PET();
        this.INIT_CREATE_STEPS()
        this.fetchData()
        this.fetchVaccines()
    }
}
</script>

<style lang="scss" scoped>

</style>