import apiClient from './Api.js'

export default { 
    createPetWalkerAd(data){
        return apiClient.post('/pet-walker-ad/save', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getPetWalkerAd(id){
        return apiClient.get('/pet-walker-ad/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    deletePetWalkerAd(id){
        return apiClient.delete('/pet-walker-ad/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    }
}