<template>
    <div>
        <div class="w-75 m-auto">
            <AdvertisementSearchEngine />
        </div>
        <h2 class="p-4 whiteBackgroundColor text-center">Αγγελίες</h2>
        <div v-if="advertisementListExists" class="w-75 m-auto">
            <b-row>
                <b-col lg="4" v-for="(item) in advertisementList" :key="item.id" class="pb-5">
                    <Box :item="item" />
                </b-col>
            </b-row>
        </div>
        <div v-if="!adsCurrentlySearching && !advertisementListExists" class="text-center">
            <h5>Δεν υπάρχουν αποτελέσματα</h5>
        </div>
        <div class="text-center" v-if="adsCurrentlySearching">
            <b-spinner
                variant="secondary"
                type="grow"
            ></b-spinner>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import Box from '../../components/Advertisement/All/Box.vue'
import AdvertisementSearchEngine from '../../components/Search/AdvertisementSearchEngine.vue'
//import store from '../../store/index'

export default {

    /*beforeRouteEnter(to, from, next) {
        store.dispatch('search/initAdvertisementSearch')
        next()
    },*/

    computed: {
        ...mapState('search', ['advertisementList', 'adsCurrentlySearching']),
        ...mapGetters('search', ['advertisementListExists'])
    },

    components: {
        Box, AdvertisementSearchEngine
    }
}
</script>

<style lang="scss" scoped>

</style>