import HomepageService from "../../services/HomepageService"

export const namespaced = true

export const state = {
    lostPets: [],
    foundPets: []
}

export const mutations = {

}

export const getters = {
    
}

export const actions = {

    getData({state}){
        return HomepageService.getData()
            .then(r => {
                state.lostPets = r.data['lost']
                state.foundPets = r.data['found']
            })
            .catch(e => console.log(e.message))
    }
}