import { render, staticRenderFns } from "./ChangeOwner.vue?vue&type=template&id=15595472&scoped=true&"
import script from "./ChangeOwner.vue?vue&type=script&lang=js&"
export * from "./ChangeOwner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15595472",
  null
  
)

export default component.exports