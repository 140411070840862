<template>
    <b-form-group>
        <label v-if="title !== ''">{{ title }}</label>

        <b-form-input
            v-if="!isArray" 
            :aria-describedby="objectName + '-live-feedback'" 
            :type="type" 
            class="form-control" 
            v-model="validation[parent][objectName].$model"
            :placeholder="placeholder"
            :state="validateState()"
            :autocomplete="autocomplete"
            :autofocus="autofocus"
            @input="inputEvent"
        />

        <b-form-input
            v-else
            :aria-describedby="objectName + indexOfArray + '-live-feedback'" 
            :type="type" 
            class="form-control" 
            v-model="validation[parent].$each.$iter[indexOfArray][objectName].$model"
            :placeholder="placeholder"
            :state="validateArrayState()"
            :autocomplete="autocomplete"
            @input="inputEvent"
        />        
        
        <ErrorMessage 
            v-for="(message, index) in errorMessages[parent][objectName]"
            :key="index"
            :message="message"
            :show="showErrorMessage(index)"
            :id="getId(index)" 
        />

    </b-form-group>
</template>

<script>
import ErrorMessage from '../Errors/FormInvalidFeedback.vue'

    export default {
       
    props:{
            isArray: {
                type: Boolean,
                default: false
            },

            autofocus: {
                type: Boolean,
                required: false,
                default: false
            },

            indexOfArray: {
                type: [String, Number],
                default: "0"
            },
            
            parent: {
                type: String,
                required: true
            },

            objectName: {
                type: String,
                required: true
            },

            title: {
                type: String,
                required: false,
                defaut: ''
            },

            validation: {
                type: Object,
                required: true
            },

            continueStep: {
                type: Boolean,
                required: true
            },

            errorMessages:{
                type: Object,
                required: true
            },

            placeholder: {
                type: String, 
                required: false
            },
            type: {
                type: String,
                required: false,
                default: "text"
            },
            autocomplete: {
                type: String,
                required: false,
                default: "on"
            }
        },

        components: {
            ErrorMessage
        },

        methods:{
            validateState(){
                let { $dirty, $error } = this.validation[this.parent][this.objectName]
                if($dirty){
                    if($error){
                        return false
                    }
                }
                return null
            },

            validateArrayState(){
                let { $dirty, $error } = this.validation[this.parent].$each.$iter[this.indexOfArray][this.objectName]
                if($dirty){
                    if($error){
                        return false
                    }
                }
                return null 
            },

            showErrorMessage(index){
                if(!this.isArray){
                    return !this.validation[this.parent][this.objectName][index]
                }

                return !this.validation[this.parent].$each[this.indexOfArray][this.objectName][index]
            },

            getId(index){
                if(this.isArray){
                    return this.objectName + index
                }

                return this.objectName
            },

            inputEvent(e) {
                this.$emit('input', e);
            },
        }
    }
</script>


<style lang="scss" scoped>

</style>