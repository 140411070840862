import { render, staticRenderFns } from "./PetInfo.vue?vue&type=template&id=4ffd8e6d&scoped=true&"
import script from "./PetInfo.vue?vue&type=script&lang=js&"
export * from "./PetInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ffd8e6d",
  null
  
)

export default component.exports