<template>
    <div>
        <b-modal 
            id="modal-2" 
            title="Το κατοικίδιό μου χάθηκε"
            v-model="showModal"
            @ok="createLostPet()"
            @show="initModal()"
            @hidden="clearNewPetLost(); $emit('closed')"
            :ok-disabled="!canSubmitLostPet"
        >
            <LostDetails />
        </b-modal>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import LostDetails from '../../LostAndFound/All/LostDetails.vue'

    export default {
        props: {
            showModal: {
                type: Boolean,
                default: false
            }
        },
        components:{
            LostDetails
        },

        computed: {
            ...mapState('pet', ['newPetLost', 'pet']),
            ...mapGetters('pet', ['canSubmitLostPet'])
        },

        methods:{
            ...mapActions('pet', ['setNewPetLost', 'createLostPet', 'clearNewPetLost']),
            ...mapActions('areas', ['getAreas']),

            initModal(){
                this.clearNewPetLost()
                this.setNewPetLost()
                this.getAreas()
                console.log('open modal...')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>