<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
                <RegisterTemplate title="Δώσε μας μερικά χαρακτηριστικά">
                    <KMultiSelect
                        title="Χρώμα"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="colours"
                        :options="colours"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                        placeholder="επίλεξε χρώμα"                        
                    />
                    <KSelect 
                        title="Φύλο"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="sex"
                        :options="sexes"
                        identifier="value"
                        val="text"
                        parent="pet"
                        class="label_sign_in requiredLabel"
                        placeholder="Φύλο"
                    />
                    <div class="row">
                        <div class="col-8">
                            <KInput
                                title="Βάρος:"
                                :validation="$vald"
                                :continue-step="continueStep"
                                :errorMessages="errorMessages"
                                objectName="weight"
                                parent="pet"
                                class="label_sign_in requiredLabel"
                                type="number"
                            />
                        </div>
                        <div class="col-4 weight-select align-self-end">
                            <KSelect
                                :validation="$vald"
                                :continueStep="continueStep"
                                :errorMessages="errorMessages"
                                objectName="weight_mo"
                                :options="weight_measurement_units"
                                parent="pet"
                                class="label_sign_in requiredLabel"
                                placeholder="Μονάδα"
                                @change="setMeasurementUnit"
                            />
                        </div>
                    </div>
                </RegisterTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import { required, requiredIf, decimal, minValue } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import KInput from '@/components/Form/KInput.vue'
import KSelect from '@/components/Form/KSelect.vue'
import RegisterTemplate from '@/components/Pets/Registration/RegisterTemplate.vue';
import KMultiSelect from '@/components/Form/KMultiSelect.vue'

    export default {
        computed: {
            ...mapState('pet_registration', ['pet', '$vald', 'weight_measurement_units', 'colours']),
            continueStep(){
                let flag = false

                if(this.$vald.pet.$dirty){
                    if(this.$vald.pet.$anyError){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.pet.$invalid){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            }
        },
        data() {
            return {
                sexes: [
                    {value: 0, text: 'Αρσενικό'},
                    {value: 1, text: 'Θηλυκό'},
                ],
                clientValidation: {
                    pet: {
                        colours:{
                            required
                        },

                        sex:{
                            required
                        },

                        weight:{
                            required,
                            decimal,
                            minValue: minValue(1)
                        },
                        weight_mo: {
                            required: requiredIf(function (obj) {
                                return obj.weight !== '' && obj.weight !== null
                            }),
                        }
                    }
                },

                serverValidation: {},

                errorMessages: {
                    pet: {
                        colours: {
                            required: 'At least one colour required!',
                        },

                        sex: {
                            required: 'The Gender is required!',
                        },

                        weight: {
                            required: 'Weight is required!',
                            decimal: 'Must be numeric',
                            minValue: 'Το βάρος πρέπει να είναι μεγαλύτερο από μηδέν'
                        },
                        weight_mo: {
                            required: 'The Measurement Unit is required'
                        }
                    }
                }
            }
        },
        
        validations() {
            return this.rules
        },
        
        methods:{
            ...mapActions('pet_registration', ['disableButtonsAction', 'enableButtonsAction', 'setValidator']),
            setMeasurementUnit() {
                if (this.pet.weight_mo !== '') {
                    this.pet.weightMeasurement = this.weight_measurement_units[this.pet.weight_mo];
                }
                else {
                    this.pet.weightMeasurement = ''
                }
            }
        },

        created(){
            this.setValidator(this.$v)
        },
        components: {
            RegisterTemplate, KInput, KSelect, KMultiSelect
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>

</style>