<template>
    <div>
        <b-form class="mt-2">
            <b-row>
                <b-col>
                    <b-form-file
                        v-model="file"
                        :state="Boolean(file)"
                        placeholder="Πάτησε εδώ για να ανεβάσεις φωτογραφία ή Βίντεο"
                        drop-placeholder="ΆΦησε το αρχείο εδώ"
                        accept="image/*"
                    ></b-form-file>
                </b-col>
                <b-col sm="auto">
                    <b-button 
                        @click="removeMedia({index})" 
                        class="button button-orange font-weight-bold"
                        pill
                        >Remove
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import Compressor from 'compressorjs'

    export default {
        props: ['media', 'index'],

        computed:{
            file: {
                get(){
                    return this.media
                },

                set(file){
                    if (!file) {
                        return;
                    }

                    let self = this;

                    new Compressor(file, {
                        quality: 0.8,
                        maxWidth: 500,
                        maxHeight: 500,
                        strict: true,

                        // The compression process is asynchronous,
                        // which means you have to access the `result` in the `success` hook function.
                        success(result) {
                            self.setMediaFile({value: result, index: self.index})
                        },
                        error(err) {
                            console.log(err.message);
                        },
                    });
                }
            },
        },

        methods:{
            ...mapActions('advertisement', ['removeMedia', 'setMediaFile'])
        }
    }
</script>

<style lang="scss" scoped>

</style>