<template>
    <b-card class="mt-4 mb-4">
        <div class="requiredLabel">
            <label>Όνομα</label>
            <input type="text" v-model="reminder.name" class="w-100 form-control mb-4" />
        </div>
        <div class="requiredLabel">
            <label>Κατηγορία</label>
            <select v-model="reminder.event_type_id" class="w-100 form-control mb-4">
                <option disabled>Επίλεξε Κατηγορία</option>
                <option v-for="event in eventTypes" v-bind:value="event.id" :key="event.id">
                    {{ event.title }}
                </option>
            </select>
        </div>
        <div v-if="eventSubtypes.length" class="requiredLabel">
            <label>Υποκατηγορία</label>
            <select v-model="reminder.event_subtype_id" class="w-100 form-control mb-4">
                <option disabled>Επίλεξε Υποκατηγορία</option>
                <option v-for="event in eventSubtypes" v-bind:value="event.id" :key="event.id">
                    {{ event.title }}
                </option>
            </select>
        </div>
        <div class="row">
            <div class="col-lg-9 requiredLabel">
                <label>Ημερομηνία</label>
                <input type="date" v-model="reminder.notification_date" class="w-100 form-control mb-4" />
            </div>
            <div class="col-lg-3 requiredLabel">
                <label>Setoff</label>
                <input type="number" v-model="reminder.setoff" class="w-100 form-control mb-4" />
            </div>
        </div>
        <div>
            <label>Σημειώσεις</label>
            <textarea  v-model="reminder.description" class="w-100 form-control mb-4" />
        </div>
        <b-button 
            @click="setReminderForm(false)" 
            class="button button-orange font-weight-bold ml-auto mr-0"
            pill
            >Remove
        </b-button>
        <b-button
            class="button button-orange font-weight-bold ml-2 mr-0"
            pill
            @click="saveCustomReminder()"
            :disabled="!enableAddReminder"
        >
            Add Reminder
        </b-button>
    </b-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'

    export default {
        props: ['reminder', 'index'],
        computed: {
            ...mapState('event', ['eventTypes', 'reminders']),

            enableAddReminder(){
                return this.reminder.name !== '' && this.reminder.notification_date !== '' && this.reminder.setoff !== '' && this.event_type_id !== ''
            },
            eventSubtypes() {
                if (this.reminder.event_type_id && this.eventTypes.length) {
                    let event = this.eventTypes.find((item) => item.id == this.reminder.event_type_id);

                    return event.event_subtypes;
                }

                return [];
            }
        },

        methods:{
            ...mapActions('event', ['removeReminder', 'saveCustomReminder', 'setReminderForm']),
        }
    }
    
</script>

<style lang="scss" scoped>

</style>