<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7">
                <h3 class="mainTitlePetRegistration">{{title}}</h3>
                <b-img :src="require('@/assets/images/pets.png')" fluid class="catImg display-desktop"></b-img>
            </div>
            <div class="col-lg-5">
                <slot></slot>
                <p class="mb-0 mt-2">*υποχρεωτικά πεδία</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['title'],
    }
</script>

<style lang="scss" scoped>

</style>