<template>
    <div class="characteristics-box pt-3" v-if="showBox">
        <div class="characteristics-title">
            <h6 class="font-weight-bold">{{ item.title }}</h6>    
        </div>    
        <div class="characteristics-body">
            <div v-for="(ch, index) in filteredCharacteristics" :key="index" class="mb-1">
                <p v-if="ch.type != 'link'" class="mb-0">
                    {{ch.label}}: {{ch.value}}
                </p>
                <p v-else class="mb-0">
                    <b-link v-b-modal.certificate-modal>{{ch.label}}</b-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item', 'showEmpty'],
    computed: {
        showBox() {
            if ( ! this.showEmpty ) {
                return this.filteredCharacteristics.length > 0;
            }

            return true;
        },
        filteredCharacteristics() {
            if ( ! this.showEmpty ) {
                return this.item.characteristics.filter((item) => item.value != '' && item.value != null);
            }

            return this.item.characteristics;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>