import PetWalkerAdService from "../../services/PetWalkerAdService"
import router from '../../router/index'

export const namespaced = true

export const state = {
    newPetWalkerAd: {
        country : null,
        city: null,
        area: null,
        description: '',
        email: '',
        title: ''
    },
    petWalkerAdMediaFiles: [],
    maxMedia: 3,

    petWalkerAd: {
        title: '',
        type: '',
        area: null,
        description: '',
        email: null,
        media: null
    },
    canEdit: false
}

export const mutations = {

}

export const getters = {
    
}

export const actions = {

    async createPetWalkerAd({state}){   
        let formData = new FormData()

        if ("id" in state.newPetWalkerAd) {
            formData.append('id', state.newPetWalkerAd.id)
        }

        formData.append('area_id', state.newPetWalkerAd.area)
        formData.append('title', state.newPetWalkerAd.title)
        formData.append('description', state.newPetWalkerAd.description)
        formData.append('email', state.newPetWalkerAd.email)
        
        state.petWalkerAdMediaFiles.forEach((file, index) => formData.append(`media[${index}]`, file))

        await PetWalkerAdService.createPetWalkerAd(formData)

        location.reload()
    },

    clearNewPetWalkerAd({state}){
        state.petWalkerAdMediaFiles = []
        state.newPetWalkerAd = {
            country : null,
            city: null,
            area: null,
            description: '',
            email: ''
        }
    },

    setPetWalkerAd({state}, data) {
        state.petWalkerAdMediaFiles = []
        state.newPetWalkerAd = {...data}
        state.newPetWalkerAd.area = data.area_id
    },

    removeMedia({state}, {index}){
        state.petWalkerAdMediaFiles.splice(index,1)
    },

    setMediaFile({state}, {value, index}){
        state.petWalkerAdMediaFiles[index] = value
    },

    addMedia({state}){
        if(state.petWalkerAdMediaFiles.length < state.maxMedia){
            state.petWalkerAdMediaFiles.push(null)
        }
    },

    showPetWalkerAd({state}, {id}){
        return PetWalkerAdService.getPetWalkerAd(id)
            .then(r => {
                state.petWalkerAd = r.data['petWalkerAd']
                state.canEdit = r.data['canEdit']
            })
            .catch(e => console.log(e.message))
    },

    // eslint-disable-next-line no-unused-vars
    deletePetWalkerAd({state}, id) {
        return PetWalkerAdService.deletePetWalkerAd(id)
            // eslint-disable-next-line no-unused-vars
            .then(r => {
                router.push({name: 'Pet Walker Ads'})
            })
            .catch(e => console.log(e.message))
    }
}