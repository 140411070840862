<template>
    <div v-if="message.length">
        <b-alert :variant="type" show>
            {{ message }}
        </b-alert>
    </div>
</template>

<script>
    export default {
        props: {
            message: {
                type: String,
                required: true
            },

            type: {
                type: String,
                default: 'danger'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>