import apiClient from './Api.js'

export default { 
    getPet(id){
        return apiClient.get('/pets/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    showPetFromUuid(uuid){
        return apiClient.get('/pets/pet-info/' + uuid, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    qrCodePetInfo(uuid){
        return apiClient.get('/pets/qr-code-pet-info/' + uuid, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getLostPet(id){
        return apiClient.get('/pets/lost/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getFoundPet(id){
        return apiClient.get('/pets/found/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    editPet(id){
        return apiClient.get('/pets/edit/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getPets(){
        return apiClient.get('/pets/', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getMyPets(){
        return apiClient.get('/pets/my-pets', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    save(data){
        return apiClient.post('/pets/save', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    fetchData(){
        return apiClient.get('/pets/create/', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    fetchVaccinesList(){
        return apiClient.get('/pets/fetch-vaccines/', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getTypes(){
        return apiClient.get('/pets/get-types', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getAreas(){
        return apiClient.get('get-areas', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getAllCities(){
        return apiClient.get('get-all-cities', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    getLostPetsList(){
        return apiClient.get('/pets/list-of-lost-pets', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    getMyPetsNotLost(){
        return apiClient.get('/pets/my-pets-not-lost', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    createLost(data){
        return apiClient.post('/pets/create-lost-pet', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    isFound(data){
        return apiClient.post('pets/pet-is-found', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    createFoundPetSave(data){
        return apiClient.post('pets/create-new-found-pet', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    putPetForAdoption(id) {
        return apiClient.post('pets/put-for-adoption/' + id, null, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    removeFromAdoption(id) {
        return apiClient.post('pets/remove-from-adoption/' + id, null, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    createPetForAdoption(data){
        return apiClient.post('adoption/create', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    getPetForAdoption(id) {
        return apiClient.get('adoption/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    setLinkStatus(data, id) {
        return apiClient.post('pets/set-link-status/' + id, data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    changeOwnership(data, id){
        return apiClient.post('pets/change-ownership/' + id, data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    checkLog(id){
        return apiClient.get('pets/check-log/' + id, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },

    updateOwnership(data){
        return apiClient.post('pets/update-owner-to-pet', data, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    }
}