<template>
    <div class="login m-auto">
        <h2 class="whiteBackgroundColor text-center innerPageHeader">Επιβεβαίωση email</h2>
        <div class="text-center">
            <p>Επιβεβαιώστε το email σας για να συνεχίσετε.</p>
            <a href="#" class="button border-button profile-btn" @click.prevent="logoutUser">
                Αποσύνδεση
            </a>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    methods: {
        ...mapActions('auth', ['logout']),
        async logoutUser(){
            try{
                await this.logout()
                //localStorage.clear()
                this.$router.push({name: 'Login'})
            } catch (error) {
                this.error = error
                console.log(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>

</style>