<template>
    <footer>
        <div class="app-store-container">
            <b-row align-h="center">
                <b-col cols="6">
                    <a href='https://play.google.com/store/apps/details?id=gr.sozon.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" class="orangeColor d-inline-block appstore-btn-container">
                        Διαθέσιμη στο<br>
                        <span class="bigger-font">Google Play</span>
                    </a>
                </b-col>
                <b-col cols="6">
                    <a href='https://apps.apple.com/gr/app/sozon-app/id1609977892?platform=iphone' target="_blank" class="orangeColor d-inline-block appstore-btn-container">
                        Διαθέσιμη στο<br>
                        <span class="bigger-font">App Store</span>
                    </a>
                </b-col>
            </b-row>
        </div>
        <hr class="mt-3 mb-0">
        <div class="row no-gutters p-3 text-center">
            <div class="col-md-4 offset-md-4">
                Sozon © 2021. All rights reserved.
            </div>
            <div class="col-md-4">
                <router-link class="d-inline p-0 grayLink font-weight-normal" :to="{name: 'Terms of Service'}">Όροι Χρήσης</router-link>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'footer',
}
</script>

<style lang="scss" scoped>

</style>