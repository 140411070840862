<template>
    <div>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Τίτλος</label>
            </b-col>
            <b-col>
                <b-form-input v-model="newPetWalkerAd.title" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Περιγραφή</label>
            </b-col>
            <b-col>
                <b-form-input v-model="newPetWalkerAd.description" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Email</label>
            </b-col>
            <b-col>
                <b-form-input type="email" v-model="newPetWalkerAd.email" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Χώρα</label>
            </b-col>
            <b-col>
                <b-form-select @change="initCity" v-model="newPetWalkerAd.country">
                    <b-form-select-option v-for="c in countries" :key="c.id" :value="c.id">
                        {{ c.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="newPetWalkerAd.country">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Πόλη</label>
            </b-col>
            <b-col>
                <b-form-select @change="initArea" v-model="newPetWalkerAd.city">
                    <b-form-select-option v-for="ct in cities" :key="ct.id" :value="ct.id">
                        {{ ct.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="newPetWalkerAd.city !== null">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Περιοχή</label>
            </b-col>
            <b-col>
                <b-form-select @change="initNeighborhood" v-model="newPetWalkerAd.area">
                    <b-form-select-option v-for="a in areas" :key="a.id" :value="a.id">
                        {{ a.name }}
                    </b-form-select-option>
                </b-form-select >
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

    export default {
        computed: {
            ...mapState('petWalkerAd', ['newPetWalkerAd']),
            ...mapState('areas', ['countries']),
            cities() {
                if ( this.newPetWalkerAd.country !== '' && this.countries) {
                    return this.countries.find((item) => item.id == this.newPetWalkerAd.country).cities
                }
                return []
            },

            areas() {
                if ( this.newPetWalkerAd.city !== '' && this.countries) {
                    return this.cities.find((item) => item.id == this.newPetWalkerAd.city).areas
                }
                return []
            }
        },

        created(){
            if ( ! this.countries )
                this.getAreas()
        },

        methods:{
            ...mapActions('pet', ['initCity', 'initArea', 'initNeighborhood']),
            ...mapActions('areas', ['getAreas']),
        },
    }
</script>

<style lang="scss" scoped>

</style>