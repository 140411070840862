<template>
    <div class="login m-auto">

        <h2 class="whiteBackgroundColor text-center innerPageHeader">Αποδοχή Υιοθεσίας Κατοικιδίου</h2>

        <h5>Your Email as Sozon User : {{ $route.query.email }}</h5>

        <div v-if="requestSent">
            <div class="formBackgroundColor">
                <div class="maxWidthSign m-auto pt-5">
                    <div class="form-group">
                        <b-alert variant="success" show>
                            Το κατοικίδιο πλέον σας ανήκει
                        </b-alert>
                    </div>
                </div>
            </div>

        </div>

        <div v-else>
            <Error :message="error" />

            <div class="form-group row mb-0 mt-5">
                <div class="col-md-12 text-center">
                    <button v-if="!loading" @click.prevent="changePetUserOwner" type="submit" class="btn btn-primary registreBtn">
                       Αποδοχή Υιοθεσίας
                    </button>

                    <button v-else class="btn btn-primary registreBtn">
                        <b-spinner label="Spinning"></b-spinner> παρακαλώ περιμένετε...
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Error from '../../components/Errors/ServerErrorMessage.vue'
import {mapActions} from 'vuex'

    export default {

        props: ['token'],

        data(){
            return {
                requestSent: false,
                loading: false,
                error: ''
            }
        },

        components: {
            Error
        },

       methods: {
        ...mapActions('pet', ['updatePetOwner']),

            async changePetUserOwner(){
                this.loading = true
                this.error = ''

                try{
                    let r = await this.updatePetOwner({
                        email: this.$route.query.email,
                        token : this.token
                    })

                    if(r.status === 200){
                        this.requestSent = true
                    }else{
                        console.log(r)
                    }
                } catch(e){
                    this.error = e.response.data.message
                    this.loading = false
                }
            }
        } 
    }
</script>

<style lang="scss" scoped>

</style>