<template>
    <div v-if="serverErrors.length">
        <b-alert variant="danger" show>
            <ul class="mb-0">
                <li v-for="(error,index) in serverErrors" :key="index">
                    {{ error }}
                </li>
            </ul>
        </b-alert>
    </div>
</template>

<script>
    export default {
        props: {
            errors: {
                type: Object,
                required: true
            }
        },

        computed: {
            serverErrors(){
                let errors = Object.values(this.errors)
                errors = errors.flat()
                return errors
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>