<template>
    <div id="lostPetCard" v-if="advertisement.media">
        
        <ShowTemplate :image="getImage()" class="petImg">
            <div class="text-center text-lg-right">
                <b-dropdown id="pet-actions" right text="Actions" variant="primary" class="rounded-dropdown" v-if="canEdit">
                    <b-dropdown-item v-b-modal.editAdvertisement>Επεξεργασία</b-dropdown-item>
                    <b-dropdown-item v-b-modal.deleteAdvertisement @click="showDeleteModal = true">Διαγραφή</b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="text-center">
                <h3 class="font-weight-bold display-desktop">{{advertisement.title}}</h3>
                <h5 class="display-desktop">
                    <b-icon-geo-alt class="ml-2 orangeColor"></b-icon-geo-alt>
                    {{ advertisement.area.city.name }}, {{ advertisement.area.city.country.sort }}
                </h5>
            </div>

            <div>
                <div class="row pb-4 pt-3">
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Eίδος: </div>
                        <div class="lostPetInputs">{{ advertisement.type }}</div>
                    </div>
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Eίδος κατοικιδίου: </div>
                        <div class="lostPetInputs">{{ advertisement.pet_type.title }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Τιμή: </div>
                        <div class="lostPetInputs">{{ advertisement.price }}€</div>
                    </div>
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Περιοχή: </div>
                        <div class="lostPetInputs">{{ advertisement.area.name }}</div>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-12">
                        <div class="font-weight-bold lostPetLabels">Πληροφορίες: </div>
                        <div class="lostPetTextarea">{{ advertisement.description }}</div>
                    </div>
                </div>
            </div>
        </ShowTemplate>
        <b-modal 
            v-if="canEdit"
            id="editAdvertisement" 
            title="Επεξεργασία Αγγελίας"
            @ok="createAdvertisement()"
            @show="setAdvertisement(advertisement)"
            @hidden="clearNewAdvertisement"
            :ok-disabled="!enableAddModalButton"
        >
            <AdvertisementDetails />
            <div>
                <b-button 
                    v-if="advertisementMediaFiles.length < maxMedia" 
                    @click="addMedia({slug: 'advertisementMediaFiles'})" 
                    class="button button-orange font-weight-bold"
                    pill
                    >Προθήκη φωτογραφίας +  
                </b-button>
                <AdvertisementMedia :index="index" :media="media" v-for="(media,index) in advertisementMediaFiles" :key="index"/>
            </div>
        </b-modal>
        <b-modal 
            v-if="canEdit"
            id="deleteAdvertisement" 
            title="Διαγραφή Αγγελίας"
            @ok="deleteAdvertisement(advertisement.id)"
        >
            <div>
                <p>Είσαι σίγουρος ότι θέλεις να διαγράψεις αυτή την αγγελία;</p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import { BIconGeoAlt } from 'bootstrap-vue'
import ShowTemplate from '../../components/LostAndFound/Show/ShowTemplate.vue'
import AdvertisementMedia from '../../components/Advertisement/All/AdMedia.vue'
import AdvertisementDetails from '../../components/Advertisement/AdvertisementDetails.vue'

export default {
    computed: {
        ...mapState('advertisement', ['advertisement', 'canEdit', 'advertisementMediaFiles', 'maxMedia', 'newAdvertisement']),
        enableAddModalButton() {
            return this.newAdvertisement.title && this.newAdvertisement.type && this.newAdvertisement.description && this.newAdvertisement.price && this.newAdvertisement.petType && this.newAdvertisement.area
        }
    },

    methods:{
        ...mapActions('advertisement', ['showAdvertisement', 'addMedia', 'createAdvertisement', 'clearNewAdvertisement', 'setAdvertisement','deleteAdvertisement']),
        getImage() {
            if (this.advertisement.media.length) {
                return this.advertisement.media[0].media
            }

            return '';
        }
    },

    created(){
        let data = {
            id: this.$route.params.id
        }
        this.showAdvertisement(data)
    },

    components: {
        BIconGeoAlt, ShowTemplate, AdvertisementMedia, AdvertisementDetails
    }
}
</script>

<style lang="scss" scoped>

</style>