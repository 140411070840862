import apiClient from './Api.js'

export default { 
    getPosts(params){
        return apiClient.get('/posts' + params, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getPost(slug){
        return apiClient.get('/posts/' + slug, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getCategories(){
        return apiClient.get('/posts/categories', {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
    getCategory(category){
        return apiClient.get('/posts/categories/' + category, {
            headers: { 'Authorization': 'Bearer '+ localStorage.getItem('api_token') }
        })
    },
}