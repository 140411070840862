<template>
    <div id="lostPetCard" v-if="this.pet.media">
        <img 
            :src="require('@/assets/images/lost_and_found.png')" 
            alt="Lost and Found Logo" 
            class="display-mobile m-auto pt-4 pb-4" 
            style="padding: 0;border-radius: 0;width: 118px !important; height: 100% !important;" />
            
        <ShowTemplate :image="getImage()" class="petImg">
            <div class="text-center">
                <h3 class="font-weight-bold display-desktop">Βρέθηκε</h3>
                <h5 class="display-desktop">
                    <b-icon-geo-alt class="ml-2 orangeColor"></b-icon-geo-alt>
                    {{ pet.area.city.name }}, {{ pet.area.city.country.sort }}
                </h5>
            </div>

            <div>
                <div class="row pb-4 pt-3">
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Ημερομηνία: </div>
                        <div class="lostPetInputs">{{ pet.date_found }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Ράτσα: </div>
                        <div class="lostPetInputs">{{ breeds }}</div>
                    </div>
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Περιοχή: </div>
                        <div class="lostPetInputs">{{ pet.area.name }}</div>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-12">
                        <div class="font-weight-bold lostPetLabels">Πληροφορίες: </div>
                        <div class="lostPetTextarea">{{ pet.description }}</div>
                    </div>
                </div>
            </div>
        </ShowTemplate>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import { BIconGeoAlt } from 'bootstrap-vue'
import ShowTemplate from '../../components/LostAndFound/Show/ShowTemplate.vue'

export default {
    computed: {
        ...mapState('pet', ['pet']),
        breeds(){
            if(Array.isArray(this.pet.breeds) && this.pet.breeds.length){
                return this.pet.breeds.map(breed => breed.name).join(", ")
            }
            return 'χωρίς ράτσα'
        },
    },

    methods:{
        ...mapActions('pet', ['showFoundPet']),
        getImage() {
            if (this.pet.media.length) {
                return this.pet.media[0].media
            }

            return '';
        }
    },

    created(){
        let data = {
            id: this.$route.params.id
        }
        this.showFoundPet(data)
    },

    components: {
        BIconGeoAlt, ShowTemplate
    }
}
</script>

<style lang="scss" scoped>

</style>