<template>
    <b-card no-body class="overflow-hidden">
        <b-row no-gutters>
            <b-col md="5">
                <router-link :to="{'path': '/posts/' + item.slug}">
                    <b-card-img :src="getPhoto()" :alt="item.title" class="rounded-0 post-card-image"></b-card-img>
                </router-link>
            </b-col>
            <b-col md="7">
                <b-card-body>
                    <b-card-sub-title class="mb-1 mt-0">{{ item.created_at }}</b-card-sub-title>
                    <router-link :to="{'path': '/posts/' + item.slug}" class="grayLink">
                        <b-card-title>{{item.title }}</b-card-title>
                    </router-link>
                    <b-card-text>
                        {{item.trimmed_body}}
                    </b-card-text>
                </b-card-body>
                <b-card-footer class="border-0 whiteBackground">
                    <router-link :to="{'path': '/posts/' + item.slug}" class="grayLink">Περισσότερα</router-link>
                </b-card-footer>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    props: ['item'],

    data(){
        return {
            baseUrl: process.env.VUE_APP_WEB_URI
        }
    },
    methods: {
        getPhoto() {
            if (this.item.image) {
                return this.baseUrl + '/storage/' + this.item.image
            }
            return null
        }
    }
}
</script>