import VetService from "../../services/VetService"

export const namespaced = true

export const state = {
    vet: {
        name: '',
        area: null,
        country: null,
        city: null,
        address: '',
        email: ''
    }
}

export const mutations = {

}

export const getters = {
    
}

export const actions = {

    showVet({state}, {id}){
        return VetService.getVet(id)
            .then(r => {
                state.vet = r.data['vet']
            })
            .catch(e => console.log(e.message))
    },
}