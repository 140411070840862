import PostService from "../../services/PostService"

export const namespaced = true

export const state = {
    posts: null,
    categories: [],
    post: {
        title: '',
        created_at: '',
        body: '',
        post_category: {name: ''},
        slug: '',
        image: ''
    },
    category: null,
    loading: false,
}

export const mutations = {

}

export const getters = {
    
}

export const actions = {

    async getPosts({state}, page=1){
        let params = '?page=' + page
        if (state.category) {
            params += '&category=' + state.category.slug
        }
        state.loading = true
        return await PostService.getPosts(params)
            .then(r => {
                state.posts = r.data['posts']
                state.category = r.data['category']
                state.loading = false
            })
            .catch(e => console.log(e.message))
    },
    setCategory({state}, value) {
        state.category = value
    },
    async showPost({state}, slug){
        return await PostService.getPost(slug)
            .then(r => {
                state.post = r.data
            })
            .catch(e => console.log(e.message))
    },
    async getCategories({state}){
        return await PostService.getCategories()
            .then(r => {
                state.categories = r.data
            })
            .catch(e => console.log(e.message))
    },
}