<template>
    <div class="w-75 m-auto">
        <div class="row pt-4 pb-4">
            <div id="lostPetOrangeBg" class="col-lg-6 align-self-center text-center petImgBackground">
                <b-img v-if="image.length > 0" :src="baseUrl + image" fluid></b-img>
                <b-img v-else blank-color="#6c757d" v-bind="mainProps"></b-img>
            </div>
            <div class="col-lg-6 pet-sidebar">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['image'],
        data(){
            return {
                baseUrl: process.env.VUE_APP_WEB_URI,
                mainProps: { blank: true, class: 'w-75 h-75' }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>