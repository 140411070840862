<template>
    <div id="progressStepsPie" class="row">    
        <div class="col-4">
            <radial-progress-bar
                :diameter="80"
                startColor="#ED7061"
                stopColor="#ED7061"
                :animateSpeed="300"
                :completed-steps="completedSteps"
                :strokeWidth="7"
                :innerStrokeWidth="6"
                :total-steps="totalSteps">
                <p class="mb-0" style="font-weight: bold">{{completedSteps}} of {{totalSteps}}</p>
            </radial-progress-bar>
        </div>
        <div class="col-8 text-right align-self-center">
            <h5 class="mb-0"><strong>{{this.currentStep.label}}</strong></h5>
            <h6 class="mb-0"><small>Επόμενο: {{this.nextStep.label}}</small></h6>
            <h6 class="mb-0" v-if="this.prevStep.label"><small>Προηγούμενο: {{this.prevStep.label}}</small></h6>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import RadialProgressBar from 'vue-radial-progress'

    export default {
        components: {
            RadialProgressBar
        },
        computed: {
            ...mapState('pet_registration', ['steps', 'completedSteps', 'totalSteps']),

            currentStep(){
                let currentRoute = element => element.name === this.currentRouteName()

                let current_step = this.steps.findIndex(currentRoute)

                if(current_step != -1)
                    return this.steps[current_step]
                
                return ''
            },

            nextStep(){
                /*if ( this.isEdit() )
                    return 'Submit'
                */
                let currentRoute = element => element.name === this.currentRouteName()

                let current_step = this.steps.findIndex(currentRoute)
                current_step++

                if(this.steps.length !== current_step)
                    return this.steps[current_step]
                
                return 'Submit'
            },

            prevStep(){
                /*if ( this.isEdit() )
                    return 'Submit'
                */
                let currentRoute = element => element.name === this.currentRouteName()

                let current_step = this.steps.findIndex(currentRoute)
                current_step--

                if(current_step != -1)
                    return this.steps[current_step]
                
                return ''
            }
        },

        methods:{
            currentRouteName(){
                return this.$route.name
            },
            currentStepName(){
                return this.$route.meta.stepName
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>