<template>
    <div>

        <div class="formBackgroundColor">
            <div class="maxWidthSign m-auto pt-5">
                <div class="form-group">
                    <KInput 
                        :validation="$vald2"
                        :continue-step="true"
                        :errorMessages="errorMessages"
                        objectName="code"
                        parent="coupon"
                        placeholder="Κουπόνι"
                        title="Κουπόνι"
                        class="label_sign_in requiredLabel"
                    />
                </div>
            </div>
        </div>

        <Errors :errors="errors" />

        <div class="form-group row mb-0 mt-5">
            <div class="col-md-12 text-center">
                <button @click.prevent="tryToSendCoupon" type="submit" class="btn btn-primary registreBtn">
                    ΕΠΙΚΥΡΩΣΗ ΚΟΥΠΟΝΙΟΥ
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import {mapActions, mapState, mapMutations} from 'vuex'
import KInput from '../../Form/KInput.vue'
import { required } from 'vuelidate/lib/validators'
import Errors from '../../Errors/ServerErrorMessages.vue'

    export default {
        data(){
            return {
                clientValidation: {
                    coupon: {
                        code: {
                            required,
                        },
                    }
                },

                serverValidation: {

                },

                errorMessages: {
                    coupon: {
                        code: {
                            required: 'Το κουπόνι είναι υποχρεωτικό!',
                        },
                    }
                },
                loading: false,
                error: null
            }
        },

        components:{
            KInput, Errors
        },

        computed:{
            ...mapState('auth', ['$vald2',  'errors', 'coupon']),

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
        },

       methods: {
            ...mapActions('auth', ['sendCoupon', 'setValidator2']),
            ...mapMutations('auth', ['CLEAR_ERRORS', 'SET_ERRORS']),

            async tryToSendCoupon(){
                this.error = null
                this.CLEAR_ERRORS();

                this.$v.$touch();
                if(this.$v.$anyError){
                    return
                }

                this.sendCoupon()
            }
        },
        
        validations() {
            return this.rules
        },
        created(){
            this.setValidator2(this.$v)
        },          
    }
</script>

<style lang="scss" scoped>

</style>