<template>
    <div
        class="text-center pet-box"
        footer-bg-variant="white"
    >
        <b-link :to="{ path: '/pets/' + item.id }" class="grayLink">
            <h5>{{ item.name }}</h5>
            <div class="petImgWrapper">
                <b-avatar
                    size="10rem" 
                    :src="baseUrl + item.photo" 
                    class="pet-badge"
                >
                    <template #badge v-if="item.isLogged === 1">
                        <b-img :src="require('@/assets/images/petLogHeart.svg')" fluid />
                    </template>
                </b-avatar>            
            </div>
        </b-link>
    </div>
</template>

<script>
export default {
    props: ['item'],

    data(){
        return {
            baseUrl: process.env.VUE_APP_WEB_URI
        }
    }
}
</script>