<template>
    <div class="mt-4 mb-4">
        <div class="requiredLabel">
            <label>Κατηγορία</label>
            <select v-model="eventID" class="w-100 form-control mb-4" @change="changeEvent()">
                <option disabled>Επίλεξε Κατηγορία</option>
                <option v-for="event in eventTypes" v-bind:value="event.id" :key="event.id">
                    {{ event.title }}
                </option>
            </select>
        </div>
        <div v-if="eventSubtypes.length" class="requiredLabel">
            <label>Υποκατηγορία</label>
            <select v-model="eventSubtypeID" class="w-100 form-control mb-4">
                <option disabled>Επίλεξε Υποκατηγορία</option>
                <option v-for="event in eventSubtypes" v-bind:value="event.id" :key="event.id">
                    {{ event.title }}
                </option>
            </select>
        </div>
        <div class="requiredLabel">
            <label>Ημερομηνία</label>
            <input type="date" v-model="logDate" class="w-100 form-control mb-4" />
        </div>
        <div>
            <label>Σημειώσεις</label>
            <textarea  v-model="logDescription" class="w-100 form-control mb-4" />
        </div>
        <div>
            <b-button 
                v-if="eventPhotos.length < maxPhotos"
                @click="addPhoto" 
                class="button button-orange font-weight-bold"
                pill
                >Προθήκη φωτογραφίας + 
            </b-button>
            <EventPhoto :index="index" :photo="photo" v-for="(photo,index) in eventPhotos" :key="index" />
        </div>
        <Reminders v-if=" ! edit " />
        <p>*υποχρεωτικά πεδία</p>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import EventPhoto from './EventPhoto.vue'
import Reminders from './Reminders.vue'

    export default {
        props: {
            edit:
            {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState('event', ['eventTypes', 'new', 'eventPhotos', 'maxPhotos', 'petType']),
            ...mapState('pet', ['pet']),

            eventID: {
                get(){
                    return this.new.eventId
                },

                set(value){
                    this.setEventId(value)
                }
            },

            eventSubtypeID: {
                get(){
                    return this.new.eventSubtypeId
                },

                set(value){
                    this.setEventSubtypeId(value)
                }
            },

            logDescription: {
                get(){
                    return this.new.description
                },

                set(value){
                    this.setEventDescription(value)
                }
            },
        
            logDate: {
                get(){
                    return this.new.date
                },

                set(value){
                    this.setEventDate(value)
                }
            },

            eventSubtypes() {
                if (this.eventID && this.eventTypes.length) {
                    let event = this.eventTypes.find((item) => item.id == this.eventID);

                    return event.event_subtypes;
                }

                return [];
            }
        },

        components:{
            EventPhoto, Reminders
        },

        methods:{
            ...mapActions('event', ['fetchTypes', 'setEventId', 'setEventDescription', 'setEventDate', 'addPhoto', 'setEventSubtypeId']),
            changeEvent() {
                this.eventSubtypeID = '';
            }
        },

        created() {
            if ( ! this.eventTypes.length || this.petType != this.pet.pet_type_id) {
                let data = {
                    pet_type_id: this.pet.pet_type_id
                }
                this.fetchTypes(data);
            }
        }
    }
    
</script>

<style lang="scss" scoped>

</style>