<template>
    <div>
        <label>Link</label>
        <b-input-group class="mb-2">
            <b-form-input 
                type="text" 
                :value="getLink()"
                ref="mylink"
                readonly
                class="form-control user-select-all" />
            <b-input-group-append>
                <b-button variant="primary" id="copy-btn" @click="copyLink()">
                    <b-icon icon="clipboard"></b-icon>
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <b-tooltip target="copy-btn" placement="bottom" triggers="hover" @hidden="copyText = 'Copy'">
            {{ copyText }}
        </b-tooltip>
        <b-row class="pt-4" align-v="center">
            <b-col sm="auto" class="pb-2">
                <b-form-checkbox switch size="lg" v-model="pet.active_private_link" @change="setLinkStatus"></b-form-checkbox>
            </b-col>
            <b-col>
                <p class="mb-0">Το λινκ είναι {{pet.active_private_link ? 'ενεργό' : 'ανενεργό'}}.</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

    export default {
        computed: {
            ...mapState('pet', ['pet']),
        },
        methods: {
            ...mapActions('pet', ['setLinkStatus']),
            getLink() {
                let link = this.$router.resolve({ 
                    name: 'PetInfo',
                    params: { uuid: this.pet.private_link },
                });
                return window.location.origin + link.href;
            },
            copyLink() {
                var Url = this.$refs.mylink;
                Url.select();
                document.execCommand("copy");
                this.copyText = "Copied to your clipboard"
            }
        },
        data() {
            return {
                copyText: "Copy",
            }
        }
    }
    
</script>

<style lang="scss" scoped>

</style>