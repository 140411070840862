<template>
    <div>
        <h2 class="p-4 text-center">Άρθρα</h2>
        <h4 v-if="category && category.name" class="text-center pb-3">Κατηγορία: {{category.name}}</h4>
        <b-container fluid class="w-75 m-auto" v-if="posts">
            <b-row>
                <b-col lg="9">
                    <b-row>
                        <b-col lg="12" v-for="(item) in posts.data" :key="item.id" class="pb-5">
                            <Box :item="item" />
                        </b-col>
                    </b-row>
                    <pagination align="center" :data="posts" @pagination-change-page="getPosts"></pagination>
                </b-col>
                <b-col lg="3">
                    <Categories />
                </b-col>
            </b-row>
        </b-container>

        <div v-if="!loading && (!posts || !posts.data.length)" class="text-center">
            <h5>Δεν υπάρχουν άρθρα</h5>
        </div>
        <div class="text-center" v-if="loading">
            <b-spinner
                variant="secondary"
                type="grow"
            ></b-spinner>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import Box from '../../components/Posts/All/Box.vue'
import Categories from '../../components/Posts/Categories.vue'
import pagination from 'laravel-vue-pagination'

export default {

    computed: {
        ...mapState('post', ['posts', 'category', 'loading']),
    },

    components: {
        Box, pagination, Categories
    },

    methods: {
        ...mapActions('post', ['getPosts', 'setCategory']),
        getData() {
            if (this.$route.query?.category) {
                this.setCategory({slug: this.$route.query.category})
            } else {
                this.setCategory(null)
            }
            this.getPosts()
        }
    },

    watch: {
        '$route.query': 'getData'
    },

    created() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

</style>