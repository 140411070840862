import Vue from "vue";
import Vuex from "vuex";
import * as pet_registration from './Modules/pet_registration.js'
import * as pet from './Modules/pet.js'
import * as auth from './Modules/auth.js'
import * as event from './Modules/event.js'
import * as areas from './Modules/areas.js'
import * as homepage from './Modules/homepage.js'
import * as search from './Modules/search.js'
import * as advertisement from './Modules/advertisement.js'
import * as petWalkerAd from './Modules/pet_walker.js'
import * as vet from './Modules/vet.js'
import * as post from './Modules/post.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false
  },
  modules: {
      pet_registration,
      pet,
      auth,
      event,
      areas,
      homepage,
      search,
      advertisement,
      petWalkerAd,
      vet,
      post
  },
  mutations: {
    ENABLE_LOADING(state){
      state.loading = true
    },

    DISABLE_LOADING(state){
      state.loading = false
    }
  },
  actions: {
    enableLoader({commit}){
      commit('ENABLE_LOADING')
    },

    disableLoader({commit}){
      commit('DISABLE_LOADING')
    }
  },
});
