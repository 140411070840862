import store from '../../../store/index.js'

export default (to, from, next) => {
    next()
    if(store.getters['auth/verified']) {
        next()
    }else{
        next({ name : 'VerifyNotification'})
    }
}

