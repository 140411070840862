<template>
    <b-container fluid class="pt-5">
        <div class="login m-auto">
            <h3 class="loginTitle">Εγγραφή</h3>
            <h6 class="grayFontColor w-75 pb-3 lightFontWeight">Εγγραφή μέλους.</h6>

            <div class="formBackgroundColor">
                <div class="maxWidthSign m-auto pt-5">
                    <div class="form-group">
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="name"
                            parent="user"
                            placeholder="Όνομα"
                            title="Όνομα"
                            type="text"
                            autocomplete="name"
                            autofocus
                            class="label_sign_in requiredLabel"
                        />
                    </div>

                    <div class="form-group">
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="last_name"
                            parent="user"
                            placeholder="Επώνυμο"
                            title="Επώνυμο"
                            type="text"
                            autocomplete="last_name"
                            autofocus
                            class="label_sign_in requiredLabel"
                        />
                    </div>

                    <div class="form-group">
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="email"
                            parent="user"
                            placeholder="Email"
                            title="Email"
                            type="email"
                            autocomplete="email"
                            autofocus
                            class="label_sign_in requiredLabel"
                        />
                    </div>

                    <div class="form-group">
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="password"
                            parent="user"
                            placeholder="Κωδικός"
                            title="Κωδικός"
                            type="password"
                            autocomplete="new-password"
                            class="label_sign_in requiredLabel"
                        />
                    </div>

                    <div class="form-group">
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="password_confirmation"
                            parent="user"
                            placeholder="Επιβεβαίωση κωδικού"
                            title="Επιβεβαίωση κωδικού"
                            type="password"
                            autocomplete="new-password"
                            class="label_sign_in requiredLabel"
                        />
                    </div>

                    <div class="form-group">
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="phone"
                            parent="user"
                            placeholder="Τηλέφωνο"
                            title="Τηλέφωνο"
                            class="label_sign_in"
                        />
                    </div>

                    <div class="form-group">
                        <KSelect 
                            v-if="countries"
                            title="Χώρα"
                            placeholder="Χώρα"
                            :validation="$vald"
                            :continueStep="true"
                            :errorMessages="errorMessages"
                            objectName="country"
                            :options="countries"
                            identifier="id"
                            val="name"
                            parent="user"
                            class="label_sign_in requiredLabel"
                            @change="initCity"
                        />

                        <KSelect 
                            v-if="cities.length"
                            title="Πόλη"
                            placeholder="Πόλη"
                            :validation="$vald"
                            :continueStep="true"
                            :errorMessages="errorMessages"
                            objectName="city"
                            :options="cities"
                            identifier="id"
                            val="name"
                            parent="user"
                            class="label_sign_in requiredLabel"
                            @change="initArea"
                        />

                        <KSelect 
                            v-if="user.city !== null"
                            title="Περιοχή"
                            placeholder="Περιοχή"
                            :validation="$vald"
                            :continueStep="true"
                            :errorMessages="errorMessages"
                            objectName="area_id"
                            :options="areas"
                            identifier="id"
                            val="name"
                            parent="user"
                            class="label_sign_in requiredLabel"
                        />
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="address"
                            parent="user"
                            placeholder="Διεύθυνση"
                            title="Διεύθυνση"
                            class="label_sign_in"
                        />
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="postal_code"
                            parent="user"
                            placeholder="Τ.Κ."
                            title="Τ.Κ."
                            type="number"
                            class="label_sign_in"
                        />
                    </div>
                    <b-form-checkbox
                        id="want_promotions"
                        v-model="$vald['user']['want_promotions'].$model"
                        name="want_promotions"
                        :value="1"
                        :unchecked-value="0"
                    >
                        Θέλω να λαμβάνω διαφημιστικό περιεχόμενο από το Sozon
                    </b-form-checkbox>
                    <b-form-checkbox
                        id="agreed_to_tos"
                        v-model="$vald['user']['agreed_to_tos'].$model"
                        name="agreed_to_tos"
                        :value="1"
                        :unchecked-value="0"
                    >
                        Αποδέχομαι τους <b-link class="nav-link d-inline p-0" v-b-modal.tos-modal>Όρους Χρήσης</b-link>
                    </b-form-checkbox>

                    <b-modal id="tos-modal" title="Όροι Χρήσης" size="xl" scrollable hide-footer>
                        <ToS />
                    </b-modal>

                    <div v-show="validateState('agreed_to_tos') == false" class="position-relative w-100">
                        <ErrorMessage
                            v-for="(message, index) in errorMessages['user']['agreed_to_tos']"
                            :key="index"
                            :message="message"
                            class="d-block"
                            :show="showErrorMessage(index, 'agreed_to_tos')"
                            id="agreed_to_tos_error" 
                        />
                    </div>

                    <Errors :errors="errors" />

                    <div class="form-group row no-gutters mb-3 mt-5">
                        <div class="col-md-12 text-center">
                            <button @click.prevent="tryToRegister" type="submit" class="btn btn-primary registreBtn">
                                Εγγραφή 
                            </button>
                        </div>
                    </div>

                    <div class="form-group row registerRow">
                        <div class="col-md-12 text-center">
                            <div class="label_sign_up grayFontColor">Έχετε ήδη λογαριασμό? <router-link class="nav-link d-inline p-0 signupLink" :to="{name: 'Login'}">Σύνδεση</router-link></div>
                        </div>
                    </div>
                    <p class="pt-3">*υποχρεωτικά πεδία</p>
                </div>
            </div>
        </div>
    </b-container>

</template>

<script>
import {merge} from 'lodash'
import {mapActions, mapState, mapMutations} from 'vuex'
import KInput from '../../components/Form/KInput.vue'
import KSelect from '../../components/Form/KSelect.vue'
import { required, minLength, email, sameAs, numeric, maxLength } from 'vuelidate/lib/validators'
import Errors from '../../components/Errors/ServerErrorMessages.vue'
import Auth from '../../services/AuthService.js'
import ErrorMessage from '../../components/Errors/FormInvalidFeedback.vue'
import ToS from '../../components/ToS.vue'

    export default {
        data(){
            return {
                user: {
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    last_name: '',
                    phone: '',
                    address: '',
                    agreed_to_tos: 0,
                    want_promotions: 0,
                    country: null,
                    city: null,
                    area_id: null,
                    postal_code: ''
                },
                clientValidation: {
                    user: {
                        name: {
                            required,
                            minLength: minLength(4)
                        },
                        email:{
                            required,
                            email
                        },
                        password: {
                            required,
                            minLength: minLength(8)
                        },
                        password_confirmation: {
                            required,
                            sameAsPassword: sameAs('password')
                        },
                        last_name: {
                            required,
                            minLength: minLength(5)
                        },
                        phone:{
                            numeric,
                            minLength: minLength(10),
                            maxLength: maxLength(10)
                        },
                        address: {
                            minLength: minLength(3)
                        },
                        agreed_to_tos: {
                            sameAs: val => val === 1 
                        },
                        want_promotions: {

                        },
                        country: {
                            required
                        },
                        city: {
                            required
                        },
                        area_id: {
                            required
                        },
                        postal_code: {
                        }
                    }
                },

                serverValidation: {

                },

                errorMessages: {
                    user: {
                        name: {
                            required: 'Το όνομα είναι υποχρεωτικό!',
                            minLength: 'Το όνομα πρέπει να έχει τουλάχιστον τέσσερεις (4) χαρακτήρες!'
                        },
                        email: {
                            required: 'Το email είναι υποχρεωτικό!',
                            email: 'Εισάγετε μια έγκυρη διεύθυνση email!'
                        },
                        password: {
                            required: 'Ο κωδικός είναι υποχρεωτικός!',
                            password: 'Ο κωδικός πρέπει να έχει τουλάχιστον οχτώ (8) χαρακτήρες!'
                        },
                        password_confirmation: {
                            required: 'Η επιβεβαίωση κωδικού είναι υποχρεωτική!',
                            sameAsPassword: 'Η επιβεβαιώση κωδικού δεν είναι ίδια με τον κωδικό'
                        },
                        last_name: {
                            required: 'Το επώνυμο είναι υποχρεωτικό!',
                            minLength: 'Το επώνυμο πρέπει να έχει τουλάχιστον πέντε (5) χαρακτήρες!'
                        },
                        phone:{
                            numeric: 'Το τηλέφωνο πρέπει να αποτελείται μόνο από αριθμούς',
                            minLength: 'Το τηλέφωνο πρέπει να έχει δέκα (10) χαρακτήρες!',
                            maxLength: 'Το τηλέφωνο πρέπει να έχει δέκα (10) χαρακτήρες!'
                        },
                        address: {
                            minLength: 'Η διεύθυνση πρέπει να έχει τουλάχιστον τρεις (3) χαρακτήρες!'
                        },
                        agreed_to_tos: {
                            sameAs: 'Πρέπει να αποδεχτείτε τους όρους χρήσης'
                        },
                        want_promotions: {

                        },
                        country: {
                            required: 'Η επιλογή της χώρας είναι υποχρεωτική!'
                        },
                        city: {
                            required: 'Η επιλογή της πόλης είναι υποχρεωτική!'
                        },
                        area_id: {
                            required: 'Η επιλογή της περιοχής είναι υποχρεωτική!'
                        },
                        postal_code: {
                        }
                    }
                }
            }
        },

        computed: {
            ...mapState('auth', ['$vald',  'errors']),
            ...mapState('areas', ['countries']),
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
            continueStep(){
                let flag = false

                if(this.$vald.user.$dirty){
                    if(this.$vald.user.$anyError){
                        //this.disableButtonsAction()
                    }else{
                        //this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.user.$invalid){
                        //this.disableButtonsAction()
                    }else{
                        //this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },
            cities() {
                if ( this.user.country !== null && this.countries) {
                    return this.countries.find((item) => item.id == this.user.country).cities
                }
                return []
            },

            areas() {
                if ( this.user.city !== null && this.countries) {
                    return this.cities.find((item) => item.id == this.user.city).areas
                }
                return []
            }
        },

        methods:{
            ...mapActions('auth', ['registerUser', 'setValidator', 'setToken', 'setUserMobile']),
            ...mapMutations('auth', ['CLEAR_ERRORS', 'SET_ERRORS']),
            ...mapActions('areas', ['getAreas']),

            async tryToRegister(){
                
                this.error = null
                this.CLEAR_ERRORS();

                this.$v.$touch();
                if(this.$v.$anyError){
                    return
                }

                try{
                    let r = await Auth.register(this.user)

                    this.setToken(r.data['token'])
                    this.setUserMobile(r.data['user'])
                    this.$router.push({name: "My Pets"})
                } catch(e){
                    this.error = e
                    console.log('some errors....')
                    this.SET_ERRORS(e.response.data.errors);
                } finally{
                    this.loading = false
                }
            },

            validateState(objectName){
                let { $dirty, $error } = this.$vald['user'][objectName]
                if($dirty){
                    if($error){
                        return false
                    }
                }
                return null
            },

            showErrorMessage(index, objectName){
                return !this.$vald['user'][objectName][index]
            },

            initCity() {
                this.user.city = null;
                this.user.area_id = null;
            },

            initArea() {
                this.user.area_id = null;
            }
        },
        validations() {
            return this.rules
        },
        created(){
            this.getAreas()
            this.setValidator(this.$v)
        },
        components: {
            KInput,
            KSelect,
            Errors,
            ErrorMessage,
            ToS
        }
    }
</script>

<style lang="scss" scoped>

</style>