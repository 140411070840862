<template>
    <b-card
        class="text-center pet-box lost-pet-box"
        footer-bg-variant="white"
    >
        <b-link :to="{ path: '/lost-pets/' + item.id }" class="grayLink">
            <h6 class="lostFoundLabelTitle" style="color: #7ca8b6">Lost</h6>

            <div class="pt-3">
                <b-avatar size="10rem" :src="baseUrl + item.photo" class="pet-badge"></b-avatar>
            </div>
            <div class="pt-3 font-weight-normal">
                <h4 class="pet-title">{{ item.name }}</h4>
                <div>
                    <div class="d-inline-flex">
                        <h4 class="align-self-center mb-0 pr-2"><b-icon-geo-alt class="orangeColor"></b-icon-geo-alt></h4>
                        <h5 class="grayFontMobile mb-0 align-self-center">{{ item.losts[0].area.name }}</h5>
                    </div>
                </div>
                <p style="color: rgb(179, 179, 179)">{{ item.losts[0].date_lost }}</p>
            </div>
        </b-link>
    </b-card>
</template>

<script>
import { BIconGeoAlt } from 'bootstrap-vue'
export default {
    props: ['item'],

    data(){
        return {
            baseUrl: process.env.VUE_APP_WEB_URI
        }
    },

    components: {
        BIconGeoAlt
    }
}
</script>