<template>
    <div class="container-fluid">
        <div class="pt-5 row justify-content-center">
            <div class="col-lg-9">
                <RegisterTemplate title="Λεπτομέρειες για το Chip">
                    <KInput
                        title="Chip ID:"
                        :validation="$vald"
                        :continue-step="continueStep"
                        :errorMessages="errorMessages"
                        objectName="chip_id"
                        parent="pet"
                        class="label_sign_in"
                        type="number"
                    />
                    <KDate 
                        title="Ημερομηνία του Chip:"
                        :validation="$vald"
                        :continueStep="continueStep"
                        :errorMessages="errorMessages"
                        objectName="chip_date"
                        parent="pet"
                        class="label_sign_in"
                    />
                    <KInput
                        title="Θέση Chip:"
                        :validation="$vald"
                        :continue-step="continueStep"
                        :errorMessages="errorMessages"
                        objectName="chip_location"
                        parent="pet"
                        class="label_sign_in"
                    />
                </RegisterTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import {merge} from 'lodash'
import { mapState, mapActions } from 'vuex'
import {integer, minValue } from 'vuelidate/lib/validators'
import KInput from '@/components/Form/KInput.vue'
import KDate from '@/components/Form/KDate.vue'
import RegisterTemplate from '@/components/Pets/Registration/RegisterTemplate.vue'
import moment from 'moment'


    export default {
        computed: {
            ...mapState('pet_registration', ['pet', '$vald']),
            continueStep(){
                let flag = false

                if(this.$vald.pet.$dirty){
                    if(this.$vald.pet.$anyError){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }else{
                    if(this.$vald.pet.$invalid){
                        this.disableButtonsAction()
                    }else{
                        this.enableButtonsAction()
                        flag = true
                    }
                }

                return flag
            },

            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            }
        },
        data(){
            return {
                clientValidation: {
                    pet: {
                    
                    chip_id:{
                            integer,
                            minValue: minValue(0)
                        },

                    chip_date: {
                        maxValue: value => {
                            if ( ! value )
                                return true
                            let currentDate = moment(new Date()).startOf('day')
                            return value <= currentDate.format('YYYY-MM-DD')
                        }
                             
                        
                    },
                    
                    chip_location: {}
                    }
                },

                serverValidation: {},

                errorMessages: {
                    pet: {
                        chip_id:{
                            integer: 'Must be integer',
                            minValue: 'Δεν μπορεί να έχει αρνητική τιμή'
                        },
                        chip_date: {
                            maxValue: 'The maximum Date of Birth is Today!'
                            
                        },
                        chip_location: {}
                    }
                }
            }
        },
        
        validations() {
            return this.rules
        },
        
        methods:{
            ...mapActions('pet_registration', ['disableButtonsAction', 'enableButtonsAction', 'setValidator'])
        },

        created(){
            console.log('created...')
            this.setValidator(this.$v)
        },
        components: {
            RegisterTemplate, KInput, KDate
        }
    }
</script>

<style lang="scss" scoped>

</style>