<template>
    <div>
        <h2>Όροι Χρήσης</h2>
        <div class="section">
        <h5>Πολιτική Απορρήτου</h5>

        <p>Το sozon.gr κατασκεύασε την εφαρμογή sozon ως δωρεάν εφαρμογή. Αυτή η ΥΠΗΡΕΣΙΑ παρέχεται από το sozon.gr χωρίς κόστος και προορίζεται για χρήση ως έχει.

        Αυτή η σελίδα χρησιμοποιείται για την ενημέρωση των επισκεπτών σχετικά με τις πολιτικές μας σχετικά με τη συλλογή, χρήση και αποκάλυψη Προσωπικών Πληροφοριών, εάν κάποιος αποφασίσει να χρησιμοποιήσει την Υπηρεσία μας.

        Εάν επιλέξετε να χρησιμοποιήσετε την Υπηρεσία μας, τότε συμφωνείτε με τη συλλογή και χρήση πληροφοριών σε σχέση με αυτήν την πολιτική. Οι Προσωπικές Πληροφορίες που συλλέγουμε χρησιμοποιούνται για την παροχή και τη βελτίωση της Υπηρεσίας. Δεν θα χρησιμοποιήσουμε ούτε θα μοιραστούμε τις πληροφορίες σας με κανέναν εκτός από όσα περιγράφονται στην παρούσα Πολιτική Απορρήτου.

        Οι όροι που χρησιμοποιούνται στην παρούσα Πολιτική Απορρήτου έχουν την ίδια σημασία με τους Όρους και Προϋποθέσεις μας, στους οποίους είναι προσβάσιμοι στο sozon.gr εκτός εάν ορίζεται διαφορετικά στην παρούσα Πολιτική Απορρήτου.
        </p>

        <h5>Συλλογή και χρήση πληροφοριών</h5>

        <p>Για καλύτερη εμπειρία, κατά τη χρήση της Υπηρεσίας μας, ενδέχεται να σας ζητήσουμε να μας παρέχετε ορισμένες προσωπικές πληροφορίες, συμπεριλαμβανομένων, ενδεικτικά, μηνυμάτων ηλεκτρονικού ταχυδρομείου. Οι πληροφορίες που ζητάμε θα διατηρηθούν από εμάς και θα χρησιμοποιηθούν όπως περιγράφεται στην παρούσα πολιτική απορρήτου.

        Η εφαρμογή χρησιμοποιεί υπηρεσίες τρίτων που ενδέχεται να συλλέγουν πληροφορίες που χρησιμοποιούνται για την ταυτοποίησή σας.</p>

        <p>Σύνδεσμος με την πολιτική απορρήτου τρίτων παρόχων υπηρεσιών που χρησιμοποιούνται από την εφαρμογή:</p>
        <ul>
            <li><a href="https://policies.google.com/privacy" target="_blank">Υπηρεσίες Google Play</a></li>
            <li><a href="https://www.apple.com/legal/privacy/en-ww/" target="_blank">Πολιτική απορρήτου της Apple</a></li>
        </ul>

        <h5>Δεδομένα καταγραφής</h5>

        <p>Θέλουμε να σας ενημερώσουμε ότι κάθε φορά που χρησιμοποιείτε την Υπηρεσία μας, σε περίπτωση σφάλματος στην εφαρμογή συλλέγουμε δεδομένα και πληροφορίες (μέσω προϊόντων τρίτων) στο τηλέφωνό σας που ονομάζονται Δεδομένα καταγραφής. Αυτά τα Δεδομένα καταγραφής ενδέχεται να περιλαμβάνουν πληροφορίες όπως τη διεύθυνση πρωτοκόλλου Διαδικτύου της συσκευής σας ("IP"), το όνομα της συσκευής, την έκδοση λειτουργικού συστήματος, τη διαμόρφωση της εφαρμογής κατά τη χρήση της Υπηρεσίας μας, την ώρα και την ημερομηνία χρήσης της Υπηρεσίας από εσάς και άλλα στατιστικά στοιχεία.</p>

        <h5>Πώς μπορούν να διαγραφούν δεδομένα</h5>

        <p>Οι πληροφορίες ενδέχεται να διαγραφούν από την εφαρμογή μας κατόπιν αιτήματος στο info@sozon.gr. Αναλαμβάνουμε να πραγματοποιήσουμε τη διαγραφή εντός μίας εβδομάδας (7 ημερολογιακές ημέρες) και θα σας στείλουμε επιβεβαίωση μόλις διαγραφούν οι πληροφορίες. Όπου είναι δυνατόν, θα επιδιώξουμε να ολοκληρώσουμε το αίτημα πριν από τη λήξη της προθεσμίας.</p>

        <h5>Cookies</h5>

        <p>Τα cookies είναι αρχεία με μικρή ποσότητα δεδομένων που χρησιμοποιούνται συνήθως ως ανώνυμα μοναδικά αναγνωριστικά. Αυτά αποστέλλονται στο πρόγραμμα περιήγησής σας από τους ιστότοπους που επισκέπτεστε και αποθηκεύονται στην εσωτερική μνήμη της συσκευής σας.

        Αυτή η Υπηρεσία δεν χρησιμοποιεί ρητά αυτά τα «cookies». Ωστόσο, η εφαρμογή μπορεί να χρησιμοποιεί κώδικα τρίτων και βιβλιοθήκες που χρησιμοποιούν «cookies» για τη συλλογή πληροφοριών και τη βελτίωση των υπηρεσιών τους. Έχετε την επιλογή είτε να αποδεχτείτε είτε να αρνηθείτε αυτά τα cookies και να γνωρίζετε πότε αποστέλλεται ένα cookie στη συσκευή σας. Εάν επιλέξετε να αρνηθείτε τα cookies μας, ενδέχεται να μην μπορείτε να χρησιμοποιήσετε ορισμένα τμήματα αυτής της Υπηρεσίας.
        </p>

        <h5>Πάροχοι υπηρεσιών</h5>

        <p>Ενδέχεται να απασχολούμε τρίτες εταιρείες και ιδιώτες για τους ακόλουθους λόγους:</p>
        
        <ul>
            <li>Για να διευκολύνουμε την Υπηρεσία μας.</li>
            <li>Για την παροχή της Υπηρεσίας για λογαριασμό μας·</li>
            <li>Για την εκτέλεση υπηρεσιών που σχετίζονται με την υπηρεσία. ή</li>
            <li>Για να μας βοηθήσετε να αναλύσουμε πώς χρησιμοποιείται η Υπηρεσία μας.</li>
        </ul>

        <p>Θέλουμε να ενημερώσουμε τους χρήστες αυτής της Υπηρεσίας ότι αυτά τα τρίτα μέρη έχουν πρόσβαση στις Προσωπικές σας Πληροφορίες. Ο λόγος είναι να εκτελούν τα καθήκοντα που τους έχουν ανατεθεί για λογαριασμό μας. Ωστόσο, είναι υποχρεωμένοι να μην αποκαλύπτουν ή να χρησιμοποιούν τις πληροφορίες για οποιονδήποτε άλλο σκοπό.</p>

        <h5>Ασφάλεια</h5>

        <p>Εκτιμούμε την εμπιστοσύνη σας στην παροχή των Προσωπικών σας Πληροφοριών, επομένως προσπαθούμε να χρησιμοποιούμε εμπορικά αποδεκτά μέσα για την προστασία τους. Αλλά να θυμάστε ότι καμία μέθοδος μετάδοσης μέσω Διαδικτύου ή μέθοδος ηλεκτρονικής αποθήκευσης δεν είναι 100% ασφαλής και αξιόπιστη και δεν μπορούμε να εγγυηθούμε την απόλυτη ασφάλειά της.</p>

        <h5>Σύνδεσμοι προς άλλους ιστότοπους</h5>

        <p>Αυτή η Υπηρεσία μπορεί να περιέχει συνδέσμους προς άλλους ιστότοπους. Εάν κάνετε κλικ σε έναν σύνδεσμο τρίτου μέρους, θα κατευθυνθείτε σε αυτόν τον ιστότοπο. Σημειώστε ότι αυτοί οι εξωτερικοί ιστότοποι δεν λειτουργούν από εμάς. Ως εκ τούτου, σας συμβουλεύουμε ανεπιφύλακτα να διαβάσετε την Πολιτική Απορρήτου αυτών των ιστότοπων. Δεν έχουμε κανέναν έλεγχο και δεν αναλαμβάνουμε καμία ευθύνη για το περιεχόμενο, τις πολιτικές απορρήτου ή τις πρακτικές οποιωνδήποτε τοποθεσιών ή υπηρεσιών τρίτων.</p>

        <h5>Παιδικό απόρρητο</h5>

        <p>Αυτές οι Υπηρεσίες δεν απευθύνονται σε κανέναν κάτω των 13 ετών. Δεν συλλέγουμε εν γνώσει μας στοιχεία προσωπικής ταυτοποίησης από παιδιά κάτω των 13 ετών. Σε περίπτωση που ανακαλύψουμε ότι ένα παιδί κάτω των 13 ετών μας έχει παράσχει προσωπικά στοιχεία, τα διαγράφουμε αμέσως από τους διακομιστές μας. Εάν είστε γονέας ή κηδεμόνας και γνωρίζετε ότι το παιδί σας μας έχει παράσχει προσωπικά στοιχεία, επικοινωνήστε μαζί μας ώστε να μπορέσουμε να κάνουμε τις απαραίτητες ενέργειες.</p>

        <h5>Αλλαγές σε αυτήν την Πολιτική Απορρήτου</h5>

        <p>Ενδέχεται να ενημερώνουμε την Πολιτική Απορρήτου μας από καιρό σε καιρό. Επομένως, σας συμβουλεύουμε να ελέγχετε περιοδικά αυτήν τη σελίδα για τυχόν αλλαγές. Θα σας ειδοποιήσουμε για τυχόν αλλαγές δημοσιεύοντας τη νέα Πολιτική Απορρήτου σε αυτή τη σελίδα.

        Αυτή η πολιτική ισχύει από 2021-10-08</p>

        <h5>Επικοινωνήστε μαζί μας</h5>

        <p>Εάν έχετε οποιεσδήποτε ερωτήσεις ή προτάσεις σχετικά με την Πολιτική Απορρήτου μας, μη διστάσετε να επικοινωνήσετε μαζί μας στο <a href="mailto:info@sozon.gr">info@sozon.gr</a>.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ToS',
}
</script>

<style lang="scss" scoped>

</style>