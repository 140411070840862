<template>
    <div
        class="text-center pet-box lost-pet-box"
        footer-bg-variant="white"
    >
        <b-link :to="{ path: '/pet-walker-ads/' + item.id }" class="grayLink">
            <h6 class="lostFoundLabelTitle" style="color: #7ca8b6">{{ item.title}}</h6>
            <div class="pt-3">
                <b-avatar size="10rem" :src="getPhoto()" class="pet-badge"></b-avatar>
            </div>
            <div class="pt-3 font-weight-normal">
                <div>
                    <div class="d-inline-flex">
                        <h4 class="align-self-center mb-0 pr-2"><b-icon-geo-alt class="orangeColor"></b-icon-geo-alt></h4>
                        <h5 class="grayFontMobile mb-0 align-self-center">{{ item.area.name }}</h5>
                    </div>
                </div>
            </div>
        </b-link>
    </div>
</template>

<script>
export default {
    props: ['item'],

    data(){
        return {
            baseUrl: process.env.VUE_APP_WEB_URI
        }
    },
    methods: {
        getPhoto() {
            if (this.item.media.length) {
                return this.baseUrl + this.item.media[0].media
            }
            return null
        }
    }
}
</script>