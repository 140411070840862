<template>
    <div>
        <img :src="require('@/assets/images/lost_and_found.png')" alt="Lost and Found Logo" class="display-mobile m-auto pt-4" />
    
        <div class="w-75 m-auto">
            <SearchEngine />
        </div>
        <LostResults v-if="lostListExists" />
        <FoundResults v-if="foundListExists" />
        <div v-if="!lostAndFoundSearching && !lostListExists && !foundListExists" class="text-center">
            <h5>Δεν υπάρχουν αποτελέσματα</h5>
        </div>

        <div class="text-center" v-if="lostAndFoundSearching">
            <b-spinner
                variant="secondary"
                type="grow"
            ></b-spinner>
        </div>
    </div>
</template>

<script>
import SearchEngine from "../../components/Search/SearchEngine.vue"
import FoundResults from "../../components/Search/FoundSearchResults.vue"
import LostResults from "../../components/Search/LostSearchResults.vue"
import {mapGetters, mapState} from 'vuex'
import store from '../../store/index'

    export default {
        data(){
            return {
                found: true,
                lost: true
            }
        },

        beforeRouteEnter(to, from, next) {
            store.dispatch('search/initSearch')
            next()
        },

        computed: {
            ...mapState('search', ['searchFilters', 'lostAndFoundSearching']),
            ...mapGetters('search', ['lostListExists', 'foundListExists'])
        },

        components:{
            LostResults, FoundResults, SearchEngine
        }
    }
</script>

<style lang="scss" scoped>

</style>