<template>
    <div>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Τίτλος</label>
            </b-col>
            <b-col>
                <b-form-input v-model="newAdvertisement.title" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Είδος</label>
            </b-col>
            <b-col>
                <b-form-input v-model="newAdvertisement.type" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Περιγραφή</label>
            </b-col>
            <b-col>
                <b-form-input v-model="newAdvertisement.description" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Τιμή</label>
            </b-col>
            <b-col>
                <b-form-input type="number" v-model="newAdvertisement.price" />
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Είδος κατοικιδίου</label>
            </b-col>
            <b-col>
                <b-form-select v-model="newAdvertisement.petType">
                    <b-form-select-option v-for="petType in petTypes" :key="petType.id" :value="petType.id">
                        {{ petType.title }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Χώρα</label>
            </b-col>
            <b-col>
                <b-form-select @change="initCity" v-model="newAdvertisement.country">
                    <b-form-select-option v-for="c in countries" :key="c.id" :value="c.id">
                        {{ c.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="newAdvertisement.country">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Πόλη</label>
            </b-col>
            <b-col>
                <b-form-select @change="initArea" v-model="newAdvertisement.city">
                    <b-form-select-option v-for="ct in cities" :key="ct.id" :value="ct.id">
                        {{ ct.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="newAdvertisement.city !== null">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Περιοχή</label>
            </b-col>
            <b-col>
                <b-form-select @change="initNeighborhood" v-model="newAdvertisement.area">
                    <b-form-select-option v-for="a in areas" :key="a.id" :value="a.id">
                        {{ a.name }}
                    </b-form-select-option>
                </b-form-select >
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

    export default {
        computed: {
            ...mapState('advertisement', ['newAdvertisement', 'petTypes']),
            ...mapState('pet', ['petTypes']),
            ...mapState('areas', ['countries']),
            cities() {
                if ( this.newAdvertisement.country !== '' && this.countries) {
                    return this.countries.find((item) => item.id == this.newAdvertisement.country).cities
                }
                return []
            },

            areas() {
                if ( this.newAdvertisement.city !== '' && this.countries) {
                    return this.cities.find((item) => item.id == this.newAdvertisement.city).areas
                }
                return []
            }
        },

        created(){
            this.getPetTypes()
            if ( ! this.countries )
                this.getAreas()
        },

        methods:{
            ...mapActions('pet', ['initCity', 'initArea', 'initNeighborhood', 'getPetTypes']),
            ...mapActions('areas', ['getAreas']),
        },
    }
</script>

<style lang="scss" scoped>

</style>