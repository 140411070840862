<template>
    <div>
        <div class="row pb-4 customNoGutters" ref="petInfo">
            <div class="col-12 pb-4 text-center">
                <b-avatar size="6rem" :src="baseUrl + pet.photo" class="my_pet_avatar"></b-avatar>
            </div>
            <div class="col-lg-12">
                <div class="pdf-title">
                    <h5 class="mb-0 font-weight-bold whiteColor">Ιδιοκτήτης</h5>
                </div>
                <div class="pdf-content row">
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Όνομα</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{owner.name}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Επίθετο</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{owner.last_name}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-3">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Τηλέφωνο</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{owner.phone}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-8">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Διεύθυνση</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{owner.address}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Email</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{owner.email}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pdf-title">
                    <h5 class="mb-0 font-weight-bold whiteColor">Κατοικίδιο</h5>
                </div>
                <div class="pdf-content row">
                    <div class="pdf-field col-lg-6">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Υιοθέτησα</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.pet_type.title}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-6">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Pedigree ID</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{ pet.petigree_id }}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-12">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Ράτσα</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{ breeds }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pdf-title">
                    <h5 class="mb-0 font-weight-bold whiteColor">Ταυτότητα</h5>
                </div>
                <div class="pdf-content row">
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Όνομα</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.name}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Ημερομηνία Γέν.</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{date}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-3">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Chip ID</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.chip_id}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Passport ID</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.passport_no}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pdf-title">
                    <h5 class="mb-0 font-weight-bold whiteColor">Γονείς</h5>
                </div>
                <div class="pdf-content row">
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Μητέρα</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.mother}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Πατέρας</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.father}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Γιαγιά 1</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.grandmother1}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Παππούς 1</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.grandfather1}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Γιαγιά 2</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.grandmother2}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Παππούς 2</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.grandfather2}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 break">
                <div class="pdf-title">
                    <h5 class="mb-0 font-weight-bold whiteColor">Χαρακτηριστικά</h5>
                </div>
                <div class="pdf-content row">
                    <div class="pdf-field col-lg-12">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Χρώμα</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{colouring}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-6">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Φύλο</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.sex ? 'Θηλυκό' : 'Αρσενικό'}}</p>
                        </div>
                    </div>
                    <div class="pdf-field col-lg-6">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Βάρος</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.weight}} {{ pet.weightMeasurement }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pdf-title">
                    <h5 class="mb-0 font-weight-bold whiteColor">Ιστορικό Υγείας</h5>
                </div>
                <div class="pdf-content row">
                    <div class="pdf-field col-lg-4">
                        <div class="pdf-label">
                            <p class="mb-0 orangeColor">Στείρωση</p>
                        </div>
                        <div class="pdf-value">
                            <p class="mb-0">{{pet.sterilization ? 'Ναι' : 'Όχι'}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pdf-title">
                    <h5 class="mb-0 font-weight-bold whiteColor">Εμβόλια</h5>
                </div>
                <div class="pdf-content row">
                    <div class="pdf-field col-12">
                        <div v-for="(item, index) in vaccinesList" :key="index">
                            <p class="mb-0">{{ item.label }} {{ item.value }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pdf-title">
                    <h5 class="mb-0 font-weight-bold whiteColor">Petlog</h5>
                </div>
                <div class="pdf-content row">
                    <div v-for="(event) in pet.pet_life_events" 
                        :key="event.id"
                        class="col-lg-6"
                    >
                        <p class="mb-1">
                            {{event.event_type.title}}
                            <span v-if="event.event_subtype"> | {{event.event_subtype.title}}</span><br>
                            {{event.date}}
                        </p>
                        <p class="orangeColor">{{event.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import html2pdf from 'html2pdf.js'
import moment from 'moment'

export default {

    computed: {
        ...mapState('pet', ['pet', 'owner']),
        breeds() {
            if (this.pet.breeds) {
                let temp = this.pet.breeds.map((item) => item.name);
                return temp.join(", ");
            }
            return '';
        },
        vaccinesList(){
            if(Array.isArray(this.pet.listVaccines) && this.pet.listVaccines.length){
                return this.pet.listVaccines.map(v => {
                    return {
                        label: v.vaccine.name,
                        value: `(${v.date})`
                    }
                })
            }
            return []
        },
        colouring(){
            if(Array.isArray(this.pet.colours) && this.pet.colours.length){
                return this.pet.colours.map(colour => colour.name).join(", ")
            }
            return ''
        },
        date() {
            return moment(this.pet.dob).format('YYYY-MM-DD')
        }
    },
    methods: {
        generateReport () {
            html2pdf(this.$refs.petInfo, {
                margin: 30, 
                pagebreak: { before: '.break', mode: ['avoid-all', 'css'] },
                filename: this.pet.name + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 300, letterRendering: true, useCORS: true, allowTaint: true, scale: 2 },
                jsPDF: { unit: 'pt', orientation: 'portrait' }
            })
        },
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_WEB_URI
        }
    }
}
</script>

<style lang="scss" scoped>

</style>