import axios from 'axios'

let webClient = axios.create({
    baseURL: process.env.VUE_APP_WEB_URI,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        // 'Content-Type' : 'application/json'
    },
    timeout: 10000
});

export default webClient