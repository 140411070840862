<template>
    <div>
        <div v-if="defaultReminders.length > 0 || customReminders.length > 0" class="pt-4 pb-2">
            <h5>Για ποια γεγονότα θα ήθελες να σε υπενθυμίσουμε;</h5>
            <b-form-checkbox-group
                v-model="setReminders"
                switches
                stacked
            >
                <b-form-checkbox v-for="item in defaultReminders" :value="item.id" :key="item.id">
                    {{ item.name }}
                </b-form-checkbox>
            </b-form-checkbox-group>
            <b-form-checkbox-group
                v-model="setCustomReminders"
                switches
                stacked
            >
                <b-form-checkbox v-for="(item, index) in customReminders" :value="item" :key="index">
                    {{ item.name }}
                </b-form-checkbox>
            </b-form-checkbox-group>
        </div>
        <ReminderForm :reminder="customReminder" v-show="showCustomReminderForm"/>
        <b-button 
            @click="setReminderForm(true)" 
            class="button button-orange font-weight-bold mt-2"
            pill
            :disabled="!new_is_filled"
            >Εισαγωγή επιπλέον υπενθύμισης
        </b-button>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import ReminderForm from './ReminderForm.vue'

    export default {
        computed: {
            ...mapState('event', ['eventTypes', 'customReminders', 'selectedReminders', 'new', 'customReminder', 'showCustomReminderForm', 'selectedCustomReminders']),
            ...mapGetters('event', ['new_is_filled']),

            defaultReminders() {
                let temp = [];
                if (this.new.eventId && this.eventTypes.length) {
                    let event = this.eventTypes.find((item) => item.id == this.new.eventId)
                    
                    temp = event.confevents.length ? event.confevents[0].confreminders : [];

                    if (this.new.eventSubtypeId) {
                        let subevent = event.event_subtypes.find((item) => item.id == this.new.eventSubtypeId);

                        let subeventReminders = subevent.confevents.length ? subevent.confevents[0].confreminders : [];

                        temp = [...temp, ...subeventReminders];
                    }
                }

                return temp;
            },

            setReminders: {
                get(){
                    return this.selectedReminders
                },

                set(value){
                    this.setSelectedReminders(value)
                }
            },

            setCustomReminders: {
                get(){
                    return this.selectedCustomReminders
                },

                set(value){
                    this.setSelectedCustomReminders(value)
                }
            },
        },

        components:{
            ReminderForm
        },

        methods:{
            ...mapActions('event', ['setReminderForm', 'setSelectedReminders', 'setSelectedCustomReminders']),
        },
    }
    
</script>

<style lang="scss" scoped>

</style>