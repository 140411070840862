<template>
    <div class="login m-auto">
        <h2 class="whiteBackgroundColor text-center innerPageHeader">Επιβεβαίωση email</h2>
        <p class="text-center">Τo email σας επιβεβαιώθηκε με επιτυχία.</p>
    </div>
</template>

<script>
import store from '../../store/index.js'
import UserService from '../../services/UserService'

export default {
    beforeRouteEnter(to, from, next) {
        if (to.query?.verify_url) {
            UserService.verify(to.query.verify_url)
            .then((r) => {
                store.dispatch('auth/setToken', r.data['token'])
                store.dispatch('auth/setUserMobile', r.data['user'])  

                next({name: 'My Pets'})
            }).catch((e) => console.log(e))
        }
    }
}
</script>

<style lang="scss" scoped>

</style>