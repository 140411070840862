<template>
    <div>
        <div class="w-75 m-auto">
            <VetSearchEngine />
        </div>
        <h2 class="p-4 whiteBackgroundColor text-center">Κτηνίατροι</h2>
        <div v-if="vetListExists" class="w-75 m-auto">
            <b-row>
                <b-col lg="4" v-for="(item) in vetList" :key="item.id" class="pb-5">
                    <Box :item="item" />
                </b-col>
            </b-row>
        </div>
        <div v-if="!vetsCurrentlySearching && !vetListExists" class="text-center">
            <h5>Δεν υπάρχουν αποτελέσματα</h5>
        </div>
        <div class="text-center" v-if="vetsCurrentlySearching">
            <b-spinner
                variant="secondary"
                type="grow"
            ></b-spinner>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import Box from '../../components/Vet/All/Box.vue'
import VetSearchEngine from '../../components/Search/VetSearchEngine.vue'
import store from '../../store/index'

export default {

    beforeRouteEnter(to, from, next) {
        store.dispatch('search/initAdoptionSearch')
        next()
    },

    computed: {
        ...mapState('search', ['vetList', 'vetsCurrentlySearching']),
        ...mapGetters('search', ['vetListExists'])
    },

    components: {
        Box, VetSearchEngine
    }
}
</script>

<style lang="scss" scoped>

</style>