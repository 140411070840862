<template>
    <div id="lostPetCard">
        
        <ShowTemplate :image="getImage()" class="petImg">
            <div class="text-center">
                <h3 class="font-weight-bold display-desktop">{{ vet.name }}</h3>
                <h5 class="display-desktop" v-if="vet.area">
                    <b-icon-geo-alt class="ml-2 orangeColor"></b-icon-geo-alt>
                    {{ vet.area.city.name }}, {{ vet.area.city.country.sort }}
                </h5>
            </div>
            <div>
                <div class="row">
                    <div class="col-6" v-if="vet.email">
                        <div class="font-weight-bold lostPetLabels">Email: </div>
                        <div class="lostPetInputs">{{ vet.email }}</div>
                    </div>
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Διεύθυνση: </div>
                        <div class="lostPetInputs">{{ vet.address }}</div>
                    </div>
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Τ.Κ.: </div>
                        <div class="lostPetInputs">{{ vet.postal_code }}</div>
                    </div>
                    <div class="col-6" v-if="vet.phone">
                        <div class="font-weight-bold lostPetLabels">Τηλέφωνο: </div>
                        <div class="lostPetInputs">{{ vet.phone }}</div>
                    </div>
                    <div class="col-6" v-if="vet.mobile_phone">
                        <div class="font-weight-bold lostPetLabels">Κινητό Τηλέφωνο: </div>
                        <div class="lostPetInputs">{{ vet.mobile_phone }}</div>
                    </div>
                </div>
            </div>
        </ShowTemplate>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import { BIconGeoAlt } from 'bootstrap-vue'
import ShowTemplate from '../../components/LostAndFound/Show/ShowTemplate.vue'

export default {
    computed: {
        ...mapState('vet', ['vet']),
    },

    methods:{
        ...mapActions('vet', ['showVet']),
        getImage() {
            if (this.vet.image) {
                return '/storage/' + this.vet.image
            }

            return '';
        }
    },

    created(){
        let data = {
            id: this.$route.params.id
        }
        this.showVet(data)
    },

    components: {
        BIconGeoAlt, ShowTemplate
    }
}
</script>

<style lang="scss" scoped>

</style>