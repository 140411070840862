import SearchService from "../../services/SearchService"

export const namespaced = true

export const state = {
    searchFilters:{
        typeOfSearch: 'lost',
        petsSelected: [],
        area: '',
        date: '',
        age: '',
        breeds: [],
        cityId: ''
    },

    adoptionFilters:{
        petsSelected: [],
        cityId: '',
        date: '',
        age_from: '',
        age_to: '',
        breeds: []
    },

    advertisementFilters:{
        petsSelected: [],
        cityId: '',
    },

    petWalkerAdFilters:{
        cityId: '',
    },

    vetFilters: {
        cityId: '',
    },

    lostList: [],
    foundList: [],
    adoptionList: [],
    petWalkerAdList: [],
    breeds: [],
    $vald: [],
    advertisementList: [],
    vetList: [],
    adoptionCurrentlySearching: false,
    lostAndFoundSearching: false,
    adsCurrentlySearching: false,
    petWalkersCurrentlySearching: false,
    vetsCurrentlySearching: false
}

export const mutations = {
    CLEAR_LOST_LIST(state){
        state.lostList = []
    },

    CLEAR_FOUND_LIST(state){
        state.foundList = []
    },

    CLEAR_ADOPTION_LIST(state){
        state.adoptionList = []
    },

    INIT_SEARCH_FILTERS(state){
        state.searchFilters = {
            typeOfSearch: 'lost',
            petsSelected: [],
            area: '',
            date: '',
            age: '',
            breeds: [],
            cityId: ''
        }
    },

    INIT_ADOPTION_FILTERS(state){
        state.adoptionFilters = {
            petsSelected: [],
            cityId: '',
            date: '',
            age_from: 0,
            age_to: 10,
            breeds: []
        }
    },
    SET_ERRORS(state, value) {
        state.errors = value
    },
    
    CLEAR_ERRORS(state){
        state.errors = {}
    },

    SET_VALIDATOR(state, value){
        state.$vald = value
    },
}

export const getters = {
    canSearch: state => state.searchFilters.typeOfSearch === 'found' || state.searchFilters.typeOfSearch === 'lost',

    lostListExists: state => state.lostList.length > 0,
    foundListExists: state => state.foundList.length > 0,
    adoptionListExists: state => state.adoptionList.length > 0,
    advertisementListExists: state => state.advertisementList.length > 0,
    petWalkerAdListExists: state => state.petWalkerAdList.length > 0,
    vetListExists: state => state.vetList.length > 0
}

export const actions = {
    async getSearchResults({state}){
        let data = {...state.searchFilters};
        
        if (Array.isArray(data.breeds))
            data.breeds = data.breeds.map((item) => item.id)
        else
            data.breeds = [data.breeds.id]

        state.lostAndFoundSearching = true
        let results = await SearchService.getResults(data)
        state.lostAndFoundSearching = false

        state.lostList = results.data['lostList']
        state.foundList = results.data['foundList']
    },

    async getAdoptionResults({state}){
        let data = {...state.adoptionFilters};
        
        if (Array.isArray(data.breeds))
            data.breeds = data.breeds.map((item) => item.id)
        else
            data.breeds = [data.breeds.id]

        state.adoptionCurrentlySearching = true
        let results = await SearchService.postAdoptionsList(data)
        state.adoptionList = results.data
        state.adoptionCurrentlySearching = false
    },

    async getAdvertisementResults({state}){
        let data = {...state.advertisementFilters};

        state.adsCurrentlySearching = true
        let results = await SearchService.postAdvertisementList(data)
        state.adsCurrentlySearching = false
        state.advertisementList = results.data
    },

    async getPetWalkerAdResults({state}){
        let data = {...state.petWalkerAdFilters};

        state.petWalkersCurrentlySearching = true
        let results = await SearchService.postPetWalkerAdList(data)
        state.petWalkersCurrentlySearching = false
        state.petWalkerAdList = results.data
    },

    async getVetResults({state}){
        let data = {...state.vetFilters};

        state.vetsCurrentlySearching = true
        let results = await SearchService.postVetList(data)
        state.vetsCurrentlySearching = false

        state.vetList = results.data
    },

    async getFilterData({state}){
        let results = await SearchService.getFilterData()
        state.breeds = results.data['breeds']
    },

    initSearch({commit,dispatch}){
        commit('INIT_SEARCH_FILTERS')
        dispatch('clearLostList')
        dispatch('clearFoundList')
    },

    initAdoptionSearch({commit, dispatch}){
        commit('INIT_ADOPTION_FILTERS')
        dispatch('clearAdoptionList')
    },

    clearAdoptionList({commit}){
        commit('CLEAR_ADOPTION_LIST')
    },

    clearLostList({commit}){
        commit('CLEAR_LOST_LIST')
    },

    clearFoundList({commit}){
        commit('CLEAR_FOUND_LIST')
    },

    setValidator({commit}, value){
        commit('CLEAR_ERRORS')
        commit('SET_VALIDATOR', value)
    },
}