<template>
    <b-form-invalid-feedback
        :id="id+'-live-feedback'"
        v-if="show"
    >
        {{ message }}
    </b-form-invalid-feedback>
</template>

<script>
    export default {
        props:{
            id: {
                type: String,
                required: true
            },
            message: {
                type: String,
                required: true
            },
            show: {
                type: Boolean,
                required: true
            }
        }
    }
</script>
