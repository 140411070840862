<template>
    <div>
        <div class="pet-info m-lg-auto">
            <QrPetInfo />
        </div>
    </div>
</template>

<script>
import QrPetInfo from '../../components/Pets/Show/QrPetInfo.vue'

export default {
    components: {
        QrPetInfo
    }
}
</script>

<style lang="scss" scoped>

</style>