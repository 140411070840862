<template>
    <div>
        <b-form class="mt-2">
            <b-row>
                <b-col>
                    <b-form-file
                        v-if="file == null || ! ( 'id' in file )"
                        v-model="file"
                        :state="Boolean(file)"
                        placeholder="Πάτησε εδώ για να ανεβάσεις μια φωτογραφία"
                        drop-placeholder="ΆΦησε το αρχείο εδώ"
                        accept="image/*"
                    ></b-form-file>
                    <b-img v-else :src="baseUrl + file.image" fluid />
                </b-col>
                <b-col sm="auto">
                    <b-button 
                        @click="removePhoto(index)" 
                        class="button button-orange font-weight-bold"
                        pill
                        >Remove
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import Compressor from 'compressorjs'

    export default {
        props: ['photo', 'index'],

        computed:{
            file: {
                get(){
                    return this.photo
                },

                set(file){
                    if (!file) {
                        return;
                    }

                    let self = this;

                    new Compressor(file, {
                        quality: 0.8,
                        maxWidth: 800,
                        maxHeight: 800,
                        strict: true,

                        // The compression process is asynchronous,
                        // which means you have to access the `result` in the `success` hook function.
                        success(result) {
                            self.setEventPhotoFile({value: result, index: self.index})
                        },
                        error(err) {
                            console.log(err.message);
                        },
                    });
                }
            },
        },

        methods:{
            ...mapActions('event', ['removePhoto', 'setEventPhotoFile'])
        },

        data(){
            return {
                baseUrl: process.env.VUE_APP_WEB_URI,
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>