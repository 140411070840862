import store from '../../../store/index.js'

export default (to, from, next) => {
    let data = {
        uuid: to.params.uuid
    }
    store.dispatch('pet/showPetFromUuid', data)
        .then(() => {
            let error = store.getters['pet/error'];

            if (error != 404) {
                next()
            }else{
                next({ name : 'Not Found'})
            }
        })
}

