<template>
    <div id="lostPetCard" v-if="petWalkerAd.media">
        <img 
            :src="require('@/assets/images/logo.svg')" 
            alt="Lost and Found Logo" 
            class="display-mobile m-auto pt-4 pb-4" 
            style="padding: 0;border-radius: 0;width: 118px !important; height: 100% !important;" />
            
        <ShowTemplate :image="getImage()" class="petImg">
            <div class="text-center text-lg-right">
                <b-dropdown id="pet-actions" right text="Actions" variant="primary" class="rounded-dropdown" v-if="canEdit">
                    <b-dropdown-item v-b-modal.editPetWalkerAd>Επεξεργασία</b-dropdown-item>
                    <b-dropdown-item v-b-modal.deletePetWalkerAd @click="showDeleteModal = true">Διαγραφή</b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="text-center">
                <h3 class="font-weight-bold display-desktop">{{petWalkerAd.title}}</h3>
                <h5 class="display-desktop">
                    <b-icon-geo-alt class="ml-2 orangeColor"></b-icon-geo-alt>
                    {{ petWalkerAd.area.city.name }}, {{ petWalkerAd.area.city.country.sort }}
                </h5>
            </div>

            <div>
                <div class="row">
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Email: </div>
                        <div class="lostPetInputs">{{ petWalkerAd.email }}</div>
                    </div>
                    <div class="col-6">
                        <div class="font-weight-bold lostPetLabels">Περιοχή: </div>
                        <div class="lostPetInputs">{{ petWalkerAd.area.name }}</div>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-12">
                        <div class="font-weight-bold lostPetLabels">Πληροφορίες: </div>
                        <div class="lostPetTextarea">{{ petWalkerAd.description }}</div>
                    </div>
                </div>
            </div>
        </ShowTemplate>
        <b-modal 
            v-if="canEdit"
            id="editPetWalkerAd" 
            title="Pet Walker"
            @ok="createPetWalkerAd()"
            @show="setPetWalkerAd(petWalkerAd)"
            @hidden="clearNewPetWalkerAd"
            :ok-disabled="!enableAddModalButton"
        >
            <PetWalkerDetails />
            <div>
                <b-button 
                    v-if="petWalkerAdMediaFiles.length < maxMedia" 
                    @click="addMedia({slug: 'petWalkerAdMediaFiles'})" 
                    class="button button-orange font-weight-bold"
                    pill
                    >Προθήκη φωτογραφίας +  
                </b-button>
                <PetWalkerMedia :index="index" :media="media" v-for="(media,index) in petWalkerAdMediaFiles" :key="index"/>
            </div>
        </b-modal>
        <b-modal 
            v-if="canEdit"
            id="deletePetWalkerAd" 
            title="Διαγραφή Pet Walker"
            @ok="deletePetWalkerAd(petWalkerAd.id)"
        >
            <div>
                <p>Είσαι σίγουρος ότι θέλεις να διαγράψεις αυτή την αγγελία;</p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import { BIconGeoAlt } from 'bootstrap-vue'
import PetWalkerMedia from '../../components/PetWalkerAd/All/PetWalkerMedia.vue'
import PetWalkerDetails from '../../components/PetWalkerAd/PetWalkerDetails.vue'
import ShowTemplate from '../../components/LostAndFound/Show/ShowTemplate.vue'

export default {
    computed: {
        ...mapState('petWalkerAd', ['petWalkerAd', 'canEdit', 'petWalkerAdMediaFiles', 'maxMedia', 'newPetWalkerAd']),
        enableAddModalButton() {
            return this.newPetWalkerAd.title && this.newPetWalkerAd.description && this.newPetWalkerAd.email && this.newPetWalkerAd.area
        }
    },

    methods:{
        ...mapActions('petWalkerAd', ['showPetWalkerAd', 'addMedia', 'createPetWalkerAd', 'clearNewPetWalkerAd', 'setPetWalkerAd', 'deletePetWalkerAd']),
        getImage() {
            if (this.petWalkerAd.media.length) {
                return this.petWalkerAd.media[0].media
            }

            return '';
        }
    },

    created(){
        let data = {
            id: this.$route.params.id
        }
        this.showPetWalkerAd(data)
    },

    data() {
        return {
            showDeleteModal: false
        }
    },

    components: {
        BIconGeoAlt, ShowTemplate, PetWalkerMedia, PetWalkerDetails
    }
}
</script>

<style lang="scss" scoped>

</style>