<template>
    <div>
        <h3>
            {{ newPetLost.pet.name }} - ΧΑΘΗΚΕ... 
        </h3>
        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Ημερομηνία</label>
            </b-col>
            <b-col>
                <b-form-datepicker
                    v-model="newPetLost.lostDate"
                    locale="el"
                    :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                ></b-form-datepicker>
            </b-col>
        </b-row>

        <b-row class="mb-2">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Χώρα</label>
            </b-col>
            <b-col>
                <b-form-select @change="initCity" v-model="newPetLost.country">
                    <b-form-select-option v-for="c in countries" :key="c.id" :value="c">
                        {{ c.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>

        <b-row class="mb-2" v-if="newPetLost.country.cities.length">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Πόλη</label>
            </b-col>
            <b-col>
                <b-form-select @change="initArea" v-model="newPetLost.city">
                    <b-form-select-option v-for="ct in newPetLost.country.cities" :key="ct.id" :value="ct">
                        {{ ct.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>

        <b-row class="mb-2" v-if="newPetLost.city !== null">
            <b-col align-self="center" sm="4" class="requiredLabel">
                <label class="mb-0">Περιοχή</label>
            </b-col>
            <b-col>
                <b-form-select @change="initNeighborhood" v-model="newPetLost.area">
                    <b-form-select-option v-for="a in newPetLost.city.areas" :key="a.id" v-bind:value="a">
                        {{ a.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>

        <b-row class="mb-2" v-if="newPetLost.area !== null">
            <b-col align-self="center" sm="4">
                Γειτονιά
            </b-col>
            <b-col>
                <b-form-input v-model="newPetLost.neighborhood" />
            </b-col>
        </b-row>

        <b-row class="mb-2">
            <b-col align-self="center" sm="4">
                Περιγραφή
            </b-col>
            <b-col>
                <b-form-input v-model="newPetLost.description" />
            </b-col>
        </b-row>
        <p class="mb-0 pt-3">*υποχρεωτικά πεδία</p>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

    export default {
        computed: {
            ...mapState('pet', ['newPetLost']),
            ...mapState('areas', ['countries']),
        },

        methods:{
            ...mapActions('pet', ['initCity', 'initArea', 'initNeighborhood']),
        }        
    }
</script>

<style lang="scss" scoped>

</style>