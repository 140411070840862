<template>
    <b-form-group>

        <label v-if="title !== null" class="w-100">{{ title }}</label>

        <multiselect 
            :id="objectName + '-select'"
            :name="objectName + '-select'"
            v-model="validation[parent][objectName].$model"
            :state="validateState()"
            :aria-describedby="objectName + '-live-feedback'" 
            :placeholder="placeholder"

            :options="options"
            class="label_sign_in"
            :multiple="true"
            label="name" 
            track-by="id"
            @input="inputEvent"
        >
        </multiselect>

    
        <div
            :id="getId(index)" 
            v-for="(message, index) in errorMessages[parent][objectName]"
            :key="index"
        >
            <span v-if="showErrorMessage(index) && validation.$dirty" class="invalid-feedback d-block">
                {{ message }}
            </span>
        </div>
    </b-form-group>
</template>

<script>
import Multiselect from 'vue-multiselect'

    export default {
        props:{         
            parent: {
                type: String,
                required: true
            },

            indexOfArray: {
                type: [String, Number],
                default: "0"
            },

            objectName: {
                type: String,
                required: true
            },

            title: {
                type: String,
                required: false,
                default: null
            },

            validation: {
                type: Object,
                required: true
            },

            continueStep: {
                type: Boolean,
                required: true
            },

            errorMessages:{
                type: Object,
                required: true
            },

            options:{
                type: Array,
                required: true
            },

            placeholder: {
                type: String, 
                required: false
            }
        },

        components: {
            Multiselect
        },

        methods:{
            validateState(){
                let { $dirty, $error } = this.validation[this.parent][this.objectName]
                if($dirty){
                    if($error){
                        return false
                    }
                }
                return null            
            },

            showErrorMessage(index){
                if(!this.isArray){
                    return !this.validation[this.parent][this.objectName][index]
                }
                return !this.validation[this.parent].$each[this.indexOfArray][this.objectName][index]
            },

            getId(index){
                if(this.isArray){
                    return this.objectName + index
                }

                return this.objectName
            },

            inputEvent(e) {
                this.$emit('input', e);
            },

        }
    }
</script>

<style lang="scss" scoped>

</style>