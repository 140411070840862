<template>
    <b-container fluid class="pt-5">
        <div class="login m-auto">
            <h3 class="loginTitle">Σύνδεση</h3>
            <h6 class="grayFontColor w-75 pb-3 lightFontWeight"></h6>
            <div class="formBackgroundColor">
                <div class="maxWidthSign m-auto pt-5">
                    <div class="form-group">
                        <KInput
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="email"
                            parent="user"
                            placeholder="Email"
                            title="Email"
                            type="email"
                            autocomplete="email"
                            autofocus
                            class="label_sign_in requiredLabel"
                        />
                    </div>
                </div>
            </div>
            <div class="formBackgroundColor login">
                <div class="maxWidthSign m-auto pt-3">
                    <div class="form-group">
                        <KInput 
                            :validation="$vald"
                            :continue-step="true"
                            :errorMessages="errorMessages"
                            objectName="password"
                            parent="user"
                            placeholder="Κωδικός"
                            title="Κωδικός"
                            type="password"
                            autocomplete="current-password"
                            class="label_sign_in requiredLabel"
                        />               
                    </div>
                </div>
            </div>
            <Errors :errors="errors" />
            <p>*υποχρεωτικά πεδία</p>

            <div class="form-group row mb-0 mt-5">
                <div class="col-md-12 text-center">
                    <button @click.prevent="login" type="submit" class="btn btn-primary registreBtn">
                        Σύνδεση
                    </button>
                </div>
            </div>

            <div class="form-group row mb-0 mt-5">
                <div class="col-md-12 text-center">
                    <router-link class="btn btn-primary registreBtn" :to="{name: 'Register'}">
                        ΔΕΝ είσαι μέλος; Κάνε εγγραφή!
                    </router-link>
                </div>
            </div>


            <div class="form-group row mb-0 mt-5">
                <div class="col-md-12 text-center">
                    <router-link :to="{name: 'Forgot'}">
                        Ξέχασα τον κωδικό μου
                    </router-link>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import {merge} from 'lodash'
import {mapActions, mapState, mapMutations} from 'vuex'
import KInput from '../../components/Form/KInput.vue'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Errors from '../../components/Errors/ServerErrorMessages.vue'
import Auth from '../../services/AuthService.js'

    export default {
        data(){
            return {
                user: {
                    email: '',
                    password: '',
                },

                clientValidation: {
                    user: {
                        email:{
                            required,
                            email,
                        },
                        password: {
                            required,
                            minLength: minLength(8)
                        },
                    }
                },

                serverValidation: {

                },

                errorMessages: {
                    user: {
                        email: {
                            required: 'The Email is required!',
                            email: 'Insert a valid email address',
                        },
                        password: {
                            required: 'The Password is required!',
                            password: 'Minimum length of Password is eight (8) characters'
                        },
                    }
                },
                loading: false,
                error: null
            }
        },

        components:{
            KInput, Errors
        },

        computed:{
            ...mapState('auth', ['$vald',  'errors']),
            rules() {
                return merge({}, this.clientValidation, this.serverValidation)
            },
        },

        methods: {
            ...mapActions('auth', ['loginUser', 'setValidator', 'setToken', 'setUser', 'getRecentReminders']),
            ...mapMutations('auth', ['CLEAR_ERRORS', 'SET_ERRORS']),

            async login(){
                this.error = null
                this.CLEAR_ERRORS();

                this.$v.$touch();
                if(this.$v.$anyError){
                    return
                }

                try {
                    let r = await Auth.login({
                        email: this.user.email,
                        password: this.user.password,
                        rememberme: this.user.rememberme
                    })
                
                    this.setToken(r.data['token'])
                    this.setUser(r.data['user'])
                    this.getRecentReminders()
                    
                    this.$router.push({name: "My Pets"})
                } catch (e) {
                    this.SET_ERRORS(e.response.data.errors)
                }
            }
        },

        validations() {
            return this.rules
        },
        created(){
            this.setValidator(this.$v)
        },
    }
</script>

<style lang="scss" scoped>

</style>