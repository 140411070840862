import webClient from './Web.js'

export default{
    login(data){
        // return webClient.post('/sanctum/token', data)
        return webClient.post('/mobile-login', data)
    },

    getUser(){
        return webClient.get('/user')
    },

    register(data){
        return webClient.post('/mobile-register', data)
    },

    logout(){
        return webClient.post('/logout')
    }
}   